import React from "react";
import { useHistory } from "react-router-dom";
import "./owner.styles.scss";
import Animate from "../../assets/images/welcomeBoard.svg";
import Button from "../../components/Button/button.component";
import { useSelector } from "react-redux";

const WellcomeOwner = ({ onClick }) => {
  const history = useHistory();

  const { studio_detail, loginFromStudio } = useSelector((state) => state.user);

  const redirectSettings = () => {
    history.push("/admin/basic-settings");
  };

  const handleClick = onClick ?? redirectSettings;

  return (
    <div className="min-h-screen flex flex-col justify-center items-center gap-5 p-10">
      <div className="text-slate-900 text-4xl font-semibold">Welcome!</div>
      <img alt="Welcome Animation" src={Animate} />
      <Button
        style={{
          backgroundColor: loginFromStudio
            ? studio_detail?.styles?.header_color
            : "#E26851",
          color: "#fff",
          width: 378,
        }}
        title="Let’s Get Started!"
        onClick={handleClick}
      >
        Let’s Get Started!
      </Button>
    </div>
  );
};

export default WellcomeOwner;
