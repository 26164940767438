import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import "./owner.styles.scss";

import { Container, Row } from "react-bootstrap";

import ClassNameSetup from "./classname-setup-component";
import ClassDurationSetup from "./classduration-setup-component";
import ClassAddTeacherSetup from "./classaddteacher-setup-component";

const AddSchedule = ({ nextStep, setSelectedStep, colorTheme }) => {
  const { status } = useSelector((state) => state.studio);

  const [stepSchedule, setStepSchedule] = useState(1);

  const nextStepSchedule = () => {
    setStepSchedule(stepSchedule + 1);
  };

  const renderStepsSchedule = () => {
    switch (stepSchedule) {
      case 1:
        return (
          <ClassNameSetup
            nextStepSchedule={nextStepSchedule}
            setSelectedStep={setSelectedStep}
            colorTheme={colorTheme}
          />
        );
      case 2:
        return (
          <ClassDurationSetup
            nextStepSchedule={nextStepSchedule}
            setSelectedStep={setSelectedStep}
            colorTheme={colorTheme}
          />
        );
      case 3:
        return (
          <ClassAddTeacherSetup nextStep={nextStep} colorTheme={colorTheme} />
        );
      default:
    }
  };

  useEffect(() => {
    if (status === "success") {
      nextStep();
    }
  }, [status]);

  const setHeader = () => {
    if (stepSchedule === 1) {
      return <span className="textHeader">Class Name Setup</span>;
    } else if (stepSchedule === 2) {
      return <span className="textHeader">Schedules</span>;
    } else if (stepSchedule === 3) {
      return <span className="textHeader">Add Teacher</span>;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid style={{ height: "100%" }}>
      <div className="flex md:justify-center flex-col">
        <div className="text-center text-black text-[32px] font-semibold mt-[50px]">
          Add your first class
        </div>
        {setHeader()}
        <div className="d-flex align-items-center justify-content-md-center gap-2 mb-2">
          <div
            style={{
              height: "16px",
              width: "16px",
              boxShadow: "inset 2px 2px 2px 0 rgba(0, 0, 0, 0.16)",
              backgroundColor: colorTheme,
            }}
            className="rounded-circle bg-greyblue"
          ></div>
          <div
            style={{
              height: "16px",
              width: "16px",
              boxShadow: "inset 2px 2px 2px 0 rgba(0, 0, 0, 0.16)",
              backgroundColor:
                stepSchedule == 2 || stepSchedule == 3 ? colorTheme : "#E0E0E0",
            }}
            className={`rounded-circle `}
          ></div>
          <div
            style={{
              height: "16px",
              width: "16px",
              boxShadow: "inset 2px 2px 2px 0 rgba(0, 0, 0, 0.16)",
              backgroundColor: stepSchedule == 3 ? colorTheme : "#E0E0E0",
            }}
            className={`rounded-circle `}
          ></div>
        </div>
        {renderStepsSchedule()}
      </div>
    </Container>
  );
};

export default AddSchedule;
