import React from "react";
import { Row, Col } from "react-bootstrap";
import "./footer.styles.scss";

const Footer = () => {
    return (
        <Row>
            <Col lg={12} className="footer">GigsClass {`${new Date().getFullYear()}`}</Col>
        </Row>
    )
}

export default Footer;