import React from "react";
import { Col, Spinner } from "react-bootstrap";
import {useSelector} from "react-redux";

const AtomLoading = () => {
    const {  studio_detail } = useSelector((state) => state.user);
  return (
    <Col className='d-flex justify-content-center flex-column align-items-center h-screen'>
      <div className='text-center fs-12'>
        <Spinner variant='info' animation='grow'
                 style={{
                     backgroundColor : studio_detail?.styles?.header_color
                 }}
        /> <br /> <br />
        <span>Loading Please Wait...</span>
      </div>
    </Col>
  );
};

export default AtomLoading;
