import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import { Container, Row, Col, Modal, Table } from "react-bootstrap";
import "./bank.styles.scss";
import Footer from "./../../components/Footer/footer.component";
import Header from "./../../components/HeaderAdmin/header.component";
import Button from "./../../components/Button/button.component";
import DropdownCard from "./../../components/Button/dropdown.component";
import sampleImg from "./../../assets/images/placeholder.png";

import {
  getListBanks,
  deleteBank,
  setStatusDefault,
  updateStatusBank,
} from "../../redux/bank/bank.action";
import Pagination from "../../components/pagination";
import EmptyData from "../../components/no-data";
import AtomLoading from "../../components/loding-indicator";
import { useOutsideOnclick } from "../../services/clickOutside";
import OrganismLayout from "../../components/Layout/organism-layout";
import ModalDelete from "../../components/Modal/delete";

const BankList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const { user } = useSelector((state) => state.user);
  const { banks, isLoading, status } = useSelector((state) => state.banks);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState({});
  const [show2, setShow2] = useState({});
  const [active, setActive] = useState(1);
  const [index, setIndex] = useState("");

  const tableHead = ["", "Bank Name", "Logo", "Status"];

  useOutsideOnclick(wrapperRef, setShow);

  const handleDelete = (bankId) => {
    dispatch(deleteBank(user?.studio_id, bankId));

    setShow1({ visible: false });
  };

  const handleUpdateStatusBank = (id, status) => {
    let params = { bank_name: show2.bank_name };
    dispatch(
      updateStatusBank(
        user?.studio_id,
        id,
        params,
        status === "active" ? "inactive" : "active"
      )
    );

    setShow2({ visible: false });
  };

  useEffect(() => {
    if (status === "success") {
      dispatch(getListBanks());
      dispatch(setStatusDefault());
    } else {
      dispatch(getListBanks());
    }
  }, [status]);

  return (
    <>
      <OrganismLayout title="Bank List" maxWidth="40rem">
        <Col>
          <div className="d-flex justify-content-end mb-3">
            <Link to="/bank/add">
              <Button title="Add New" type="primary" />
            </Link>
          </div>
          {isLoading ? (
            <AtomLoading />
          ) : (
            <Table hover size="sm" className="">
              <thead className="bg-light">
                <tr>
                  {tableHead.map((el, idx) => (
                    <th key={idx}>{el}</th>
                  ))}
                </tr>
              </thead>
              <tbody ref={wrapperRef}>
                {banks.data?.length > 0 ? (
                  banks.data.map((el, idx) => (
                    <tr key={idx}>
                      <td className="position-relative">
                        <DropdownCard
                          width="5rem"
                          idx={idx}
                          index={index}
                          show={show}
                          setShow={setShow}
                          setIndex={setIndex}
                          className="dropdown-text"
                          positionTop
                          positionLeft={40}
                          data={[
                            {
                              name: "Edit",
                              onClick: () =>
                                history.push({
                                  pathname: `/bank/edit/${el.id}`,
                                  state: { id: el?.id },
                                }),
                            },
                            {
                              name: "Delete",
                              onClick: () => {
                                setShow(false);
                                setShow1({
                                  id: el?.id,
                                  bank_name: el?.bank_name,
                                  visible: true,
                                });
                              },
                            },
                            {
                              name: "Deactivate",
                              onClick: () => {
                                setShow(false);
                                setShow2({
                                  id: el?.id,
                                  bank_name: el?.bank_name,
                                  status: el?.status,
                                  visible: true,
                                });
                              },
                            },
                          ]}
                        />
                      </td>
                      <td>{el.bank_name}</td>
                      <td>
                        <img
                          src={el?.image_link ?? sampleImg}
                          width={80}
                          alt=""
                        />
                      </td>
                      <td>{el.status ?? "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <EmptyData />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
          {banks.data?.length > 0 && (
            <Pagination
              active={active}
              setActive={setActive}
              current_page={banks.meta.pagination?.current_page}
              total_pages={banks.meta.pagination?.total_pages}
            />
          )}
        </Col>
      </OrganismLayout>

      <ModalDelete
        show={show1}
        setShow={setShow1}
        subject="Bank"
        messages="Deleting this data will delete all related bank id in order or
        transactions history."
        handleDelete={() => handleDelete(show1?.id)}
      />

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show2.visible}
        onHide={() => setShow2({ visible: false })}
      >
        <div className="text-center p-3">
          <h2 className="heading1 m-0 mb-3">Deactivate Bank</h2>
          <p className="pb-3">
            Please Confirm you want to deactivate this bank.
          </p>{" "}
          <div className="b-gray justify-content-center mx-5 rounded-lg mb-5">
            <p className="m-0 p-3">
              <span className="lightaquamarine">Bank Name</span> <br />
              {show2.bank_name}
            </p>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow2({ visible: false })}
            />
            <Button
              title="SUBMIT"
              type="primary"
              onClick={() => handleUpdateStatusBank(show2.id, show2.status)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BankList;
