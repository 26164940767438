import React from "react";
import "./cardDashboard.styles.scss";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";

const CardDashboard = ({
  title,
  icon,
  number,
  subnumber,
  color,
  pathname,
  state,
  subText,
}) => {
  return (
    <Link
      className="cardDashboard md:w-1/4 p-2 my-4 "
      style={{ border: `1px solid ${color}` }}
      to={{
        pathname: pathname,
        state: {
          title: title,
          action_type:
            title === "Classes"
              ? "list-activities"
              : title === "Registrants"
              ? "list-registrant"
              : title === "Attendants"
              ? "list-attendance"
              : "list-transactions",
          startDate: moment(state[0].startDate).format("YYYY-MM-DD"),
          endDate: moment(state[0].endDate).format("YYYY-MM-DD"),
        },
      }}
    >
      <div className="titleCard">
        {title}
        <img alt="" src={icon} width={24} />
      </div>
      <div className="bodyCard">{number}</div>
      <div className="footerCard">
        <span className="fw-bold">{subnumber}</span> {subText}
      </div>
    </Link>
  );
};

export default CardDashboard;
