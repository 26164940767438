import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import "./register.styles.scss";

import { Row, Col } from "react-bootstrap";

import { setStatusDefault } from "../../redux/studio/studio.action";
import spiner from "../../assets/images/spiner.svg";

const CreatingStudio = () => {
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(["user", "subdomain"]);

  const { user: auth, isLoading } = useSelector((state) => state.user);

  const { status, subdomain, user_studio } = useSelector(
    (state) => state.studio
  );

  useEffect(() => {
    if (status === "success") {
      setCookie("user", user_studio, {
        path: "/",
        domain: process.env.REACT_APP_HOST,
      });
      setCookie("subdomain", subdomain, {
        path: "/",
        domain: process.env.REACT_APP_HOST,
      });

      dispatch(setStatusDefault());

      window.location.href =
        process.env.REACT_APP_PROTOCOL +
        subdomain +
        `.${process.env.REACT_APP_HOST}/admin/onboarding`;
    }
  }, [dispatch, setCookie, status, subdomain, user_studio]);

  return (
    <Row
      style={{ height: "100vh" }}
      className="justify-content-md-center align-items-center"
    >
      <Col lg={5}>
        <div className="custom-card align-items-center">
          <span className="Please-wait-were-creating-your-online-studio fw-bold m-0">
            Building Your Studio
          </span>
          <span style={{ fontSize: 12 }} className="text-center fw-bolder my-4">
            Please wait! We are creating your studio
          </span>
          {/* <Spinner
            variant='info'
            animation='border'
            size='md'
            style={{ height: 100, width: 100 }}
          /> */}
          <img alt="" src={spiner} className="spiner" />
        </div>
      </Col>
    </Row>
  );
};

export default CreatingStudio;
