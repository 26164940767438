import React, { useCallback, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Col, Form, Row } from "react-bootstrap";

import { getStudioDetail } from "../../../../redux/user/user.action";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { changeHeader } from "../../../../services/studios";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import { useHistory } from "react-router-dom";

import ColorPicker from "../../../../components/Color Picker";
import Button from "../../../../components/Button/button.component";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import UploadPicture from "../../../../components/uploadPicture";
import { CloseCircleOutlined } from "@ant-design/icons";

const ChangeHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { studio_detail, user, loginFromStudio } = useSelector(
    (state) => state.user
  );

  const [cookies, setCookies] = useCookies(["user"]);
  const [headerImage, setHeaderImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(
    studio_detail?.styles?.header_color ?? "#00897B"
  );
  const [previewUrl, setPrevieUrl] = useState(studio_detail?.logo_link ?? "");
  const [isLoading, setIsLoading] = useState(false);

  const mobileSize = useMemo(() => window.screen.width < 576, []);

  const user_token = useMemo(
    () =>
      loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token,
    [cookies.user?.token, isRegisterStudent, loginFromStudio, user?.token]
  );

  const studio_id = useMemo(
    () =>
      loginFromStudio || isRegisterStudent
        ? user?.studio_id
        : cookies.user?.studio_id,
    [
      cookies.user?.studio_id,
      isRegisterStudent,
      loginFromStudio,
      user?.studio_id,
    ]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const params = {
        header_image: headerImage,
        logo: logoImage,
        backgroundColor: backgroundColor,
      };
      setIsLoading(true);
      try {
        await changeHeader(params, studio_id, user_token);

        dispatch(getStudioDetail(user_token, studio_id));
        toast.success("Header has been update successfully!");
      } catch (error) {
        toast.error(<GenerateErrorMessage error={error} />);
      } finally {
        setIsLoading(false);
      }
    },
    [backgroundColor, dispatch, headerImage, logoImage, studio_id, user_token]
  );

  const beforeUpload = useCallback((file, header) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";

    if (!isJpgOrPng) {
      toast.error("You can only upload JPG/JPEG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isLt2M) {
      toast.error("Image must smaller than 1MB!");
    }

    if (isJpgOrPng && isLt2M) {
      const url = URL.createObjectURL(file);
      setPrevieUrl(url);
      setLogoImage(file);
    } else {
      setHeaderImage(null);
      setLogoImage(null);
    }
  }, []);

  const handleRemove = () => {
    setPrevieUrl(null);
    setLogoImage(null);
  };

  return (
    <OrganismLayout title="Change Header" maxWidth="20rem" className="p-0">
      <div className="md:w-[600px]">
        <p className=" font-bold text-2xl">Background</p>
        <p className="font-bold text-lg mt-10">Change Logo</p>

        <div
          style={{ border: `1px solid ${backgroundColor}` }}
          className="flex p-8 justify-between items-center mb-6"
        >
          {previewUrl ? (
            <div className="flex gap-4">
              <img src={previewUrl} className="w-40 h-40" alt="" />
              <CloseCircleOutlined onClick={handleRemove} />
            </div>
          ) : (
            <p className="font-semibold m-0">Upload Logo</p>
          )}

          <div className="flex flex-col justify-center items-center">
            <p className="text-lg font-semibold">Upload Logo</p>
            <input
              type="file"
              id="logo_image"
              className="hidden"
              onChange={(event) => {
                beforeUpload(event.target.files[0], true);
              }}
            />
            <label
              for="logo_image"
              style={{ backgroundColor: backgroundColor }}
              className="py-2 px-8 text-lg font-bold text-white cursor-pointer hover:opacity-70"
            >
              Browse File
            </label>
            <p className="mt-2 text-xs font-normal" style={{ fontSize: 10 }}>
              Min 300 x 300 px - max 1 MB file size
            </p>
          </div>
        </div>

        <ColorPicker
          className={mobileSize ? "w-auto" : ""}
          backgroundColor={backgroundColor}
          setBackgroundColor={setBackgroundColor}
        />
        <div className="d-flex justify-content-between mt-5">
          <Button
            type={"outline-secondary"}
            title={"Cancel"}
            onClick={() => {
              history.push("/admin/settings");
            }}
            style={{
              border: `1px solid ${studio_detail?.styles?.header_color}`,
              color: studio_detail?.styles?.header_color,
              borderRadius: 0,
            }}
          />
          <Button
            style={{
              backgroundColor: studio_detail?.styles?.header_color,
              borderRadius: 0,
            }}
            buttonType="submit"
            onClick={handleSubmit}
            isLoading={isLoading}
            type="primary"
            title="Save"
          />
        </div>
      </div>
    </OrganismLayout>
  );
};

export default ChangeHeader;
