import { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Col, Row } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import {
  changeStatusClass,
  deleteClass,
  getClass,
  getStudents,
  getTeacher,
} from "./../../../../redux/owner/owner.action";
import AtomLoading from "../../../../components/loding-indicator";
import { sendActivities } from "../../../../services/classes";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { setMenuActive } from "../../../../redux/user/user.action";
import hexToRGBA from "../../../../helper/hexToRbga";
import {
  Dropdown,
  DatePicker,
  Tag,
  Select,
  Typography,
  Modal,
  Button as AntButton,
  Table,
  Menu,
  Empty,
  ConfigProvider,
} from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  MoreOutlined,
} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const Classes = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user", "subdomain"]);
  const [type, setType] = useState("time");
  const [index, setIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState({
    id: "",
    visible: false,
    name: "",
    date: null,
  });
  const [modal1, setModal1] = useState({
    id: null,
    visible: false,
  });
  const [modalChangeStatus, setModalChangeStatus] = useState({
    id: "",
    visible: false,
    name: "",
    date: null,
    status: "",
  });
  const [active, setActive] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modalSendAllActivities, setModalSendAllActivities] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [listSelectedOption, setListSelectedOption] = useState([]);
  const [open, setOpen] = useState(false);

  const users_email = listSelectedOption.map((el) => el.user_email);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { owner_classes, isLoadingClass, isLoading, students } = useSelector(
    (state) => state.owner
  );
  const { user, teacher, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const status_class =
    modalChangeStatus.status === "active" ? "cancel" : "active";
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const tableHead = [
    "Date",
    "Class Name",
    "Teacher",
    "Type",
    "Price",
    "Status",
    "Action",
  ];

  const columns = [
    {
      title: tableHead[0],
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("ll"),
    },
    {
      title: tableHead[1],
      dataIndex: "class_name",
      key: "class_name",
      render: (text, record) => (
        <Typography.Link
          onClick={() =>
            history.push({
              pathname: `/admin/class/${record.id}/detail`,
              state: { id: record.id },
            })
          }
          style={{ color: studio_detail?.styles?.header_color }}
        >
          {text}
        </Typography.Link>
      ),
    },
    {
      title: tableHead[2],
      dataIndex: "teachers",
      key: "teachers",
    },
    {
      title: tableHead[3],
      dataIndex: "is_online",
      key: "is_online",
      render: (is_online) => (
        <Tag
          color={is_online === 1 ? "#9C15FF" : "#0296CF"}
          className="capitalize"
        >
          {is_online === 1 ? "Online" : "Onsite"}
        </Tag>
      ),
    },
    {
      title: tableHead[4],
      dataIndex: "class_price",
      key: "class_price",
      render: (text) => `IDR ${text ?? "-"}`,
    },
    {
      title: tableHead[5],
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          color={status === "active" ? "#27AE60" : "#EB5757"}
          className="capitalize"
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() =>
                  history.push({
                    pathname: `/admin/class/${record.id}/edit`,
                    state: { id: record.id },
                  })
                }
              >
                Edit
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => {
                  setShow(false);
                  setModal1({
                    id: record.id,
                    visible: true,
                    isOnline: record.is_online,
                    name: record.class_name,
                    teachers: record.teachers,
                    state: record,
                  });
                }}
              >
                Send Class
              </Menu.Item>
              <Menu.Item
                key="3"
                onClick={() => {
                  setShow(false);
                  setModal({
                    id: record.id,
                    visible: true,
                    name: record.class_name,
                    date: moment(record.created_at).format("ll"),
                  });
                }}
                danger
              >
                Delete
              </Menu.Item>
              <Menu.Item
                key="4"
                onClick={() => {
                  setShow(false);
                  setModalChangeStatus({
                    id: record.id,
                    visible: true,
                    name: record.class_name,
                    date: moment(record.created_at).format("ll"),
                    status: record.status,
                  });
                }}
                danger
              >
                {record.status === "active" ? "Deactivate" : "Activate"}
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined className="text-[25px]" />
        </Dropdown>
      ),
    },
  ];

  const options =
    students.data?.length > 0 &&
    students.data.map((el) => ({
      label: `${el.first_name} ${el.last_name ?? ""}`,
      value: el.email,
    }));

  const handleDeleteClass = (id) => {
    dispatch(deleteClass(user_token, studio_id, id));

    setModal({ visible: false });
  };

  const handleInvitation = async () => {
    setLoading(true);
    try {
      let params = {
        activity_id: modal1.id ?? 0,
        emails: listSelectedOption.map((el) => el.user_email).join(","),
      };
      await sendActivities(params, user_token, studio_id);

      setModal1(false);
      setModalSendAllActivities(false);
      toast.success("Sent Successfully");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSatusClass = () => {
    dispatch(
      changeStatusClass(
        user_token,
        studio_id,
        modalChangeStatus.id,
        status_class
      )
    );
    setModalChangeStatus({ visible: false });
  };

  const handleDelete = (idx) => {
    let deletedItem = listSelectedOption[idx];
    let newData = listSelectedOption.filter((e) => e !== deletedItem);

    setListSelectedOption(newData);
  };

  useEffect(() => {
    let params = {
      token_jwt: user_token,
      studio_id: studio_id,
    };
    dispatch(getStudents(params, active));
    dispatch(getTeacher(user_token, studio_id, 1));
    dispatch(setMenuActive("Classes"));
  }, []);

  useEffect(() => {
    if (selectedOption !== null) {
      if (users_email.includes(selectedOption?.value)) {
        toast.error("Student already exist!");
        return;
      } else {
        setListSelectedOption([
          ...listSelectedOption,
          { user_email: selectedOption },
        ]);
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    dispatch(getClass(user_token, studio_id, active));
  }, [isLoading, active, dispatch, user_token, studio_id]);

  return (
    <>
      <OrganismLayout title="Classes" maxWidth="44rem">
        <div className={"flex flex-row justify-between"}>
          <h2 className="font-bold">Classes</h2>
          <div className={"flex flex-row justify-between items-center gap-3"}>
            <div className="flex flex-row items-center gap-1 cursor-pointer ">
              <RangePicker
                onClick={() => setOpen((prev) => !prev)}
                allowClear={false}
                className="max-w-[270px] text-[20px] font-semibold custom-picker outline-0 border-0 hover:outline-0 hover:border-0 active:outline-0 active:border-0 focus:outline-0 focus:border-0 focus:shadow-none  focus-within:outline-0 focus-within:border-0 focus-within:shadow-none"
                size="large"
                suffixIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="none"
                    stroke="#000"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path
                        strokeWidth="1.5"
                        d="M2 12c0-3.771 0-5.657 1.172-6.828C4.343 4 6.229 4 10 4h4c3.771 0 5.657 0 6.828 1.172C22 6.343 22 8.229 22 12v2c0 3.771 0 5.657-1.172 6.828C19.657 22 17.771 22 14 22h-4c-3.771 0-5.657 0-6.828-1.172C2 19.657 2 17.771 2 14v-2z"
                      ></path>
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M7 4V2.5M17 4V2.5M2.5 9h19"
                      ></path>
                      <path
                        fill="#000"
                        d="M18 17a1 1 0 11-2 0 1 1 0 012 0zM18 13a1 1 0 11-2 0 1 1 0 012 0zM13 17a1 1 0 11-2 0 1 1 0 012 0zM13 13a1 1 0 11-2 0 1 1 0 012 0zM8 17a1 1 0 11-2 0 1 1 0 012 0zM8 13a1 1 0 11-2 0 1 1 0 012 0z"
                      ></path>
                    </g>
                  </svg>
                }
              />
              {open ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </div>
            <Select
              value={type}
              onChange={setType}
              className="border-0 outline-0 custom-select"
              suffixIcon={
                <CaretDownOutlined className="text-[#000] text-[16px]" />
              }
            >
              <Option value="time">Time</Option>
              <Option value="date">Date</Option>
              <Option value="week">Week</Option>
              <Option value="month">Month</Option>
              <Option value="quarter">Quarter</Option>
              <Option value="year">Year</Option>
            </Select>
          </div>
        </div>

        <Col>
          <div className="flex flex-row justify-content-end gap-3 mt-7 mb-8">
            <Button
              isLoading={teacher?.data?.length === 0}
              title="Send All Classes"
              type="primary"
              onClick={() => setModalSendAllActivities(true)}
              style={{
                backgroundColor: studio_detail?.styles?.header_color,
              }}
            />
            <Button
              isLoading={teacher?.data?.length === 0}
              title="Create Classes"
              type="primary"
              onClick={() => {
                history.push("/admin/add-class");
              }}
              style={{
                backgroundColor: studio_detail?.styles?.header_color,
              }}
            />
          </div>

          {isLoadingClass ? (
            <AtomLoading />
          ) : (
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg:
                      hexToRGBA(studio_detail?.styles?.header_color, 0.1) ||
                      "#fafafa",
                  },
                },
              }}
            >
              <Table
                columns={columns}
                dataSource={owner_classes.data}
                rowKey="id"
                locale={{ emptyText: <Empty /> }}
                size="large"
                pagination={false}
                scroll={{ x: true }}
              />
            </ConfigProvider>
          )}
        </Col>
      </OrganismLayout>

      <Modal
        centered
        open={modal?.visible}
        onClose={() => setModal({ visible: false })}
        footer={null}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h3 className="mb-3 font-medium">Delete Class</h3>
          <div className="b-gray mx-md-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b
                className="text-lg"
                style={{ color: studio_detail?.styles?.header_color }}
              >
                {modal?.name}
              </b>{" "}
              <br />
              <span className="text-sm text-gray-600">{modal?.date}</span>
            </p>
          </div>
          <span className="mb-3 text-[#EB5757] text-lg">
            Deleting this data will delete all related bank id in order or
            transactions history.
          </span>
          <div className="flex flex-col justify-center items-center gap-3 w-full">
            <AntButton
              type="primary"
              onClick={() => handleDeleteClass(modal?.id)}
              size="large"
              className="border-0 bg-[#EB5757] text-white rounded-none px-5 max-w-xs"
            >
              Delete
            </AntButton>
            <AntButton
              type="default"
              onClick={() => setModal({ visible: false })}
              size="large"
              style={{
                color: studio_detail?.styles?.header_color,
              }}
              className="rounded-none px-5 max-w-xs"
            >
              Cancel
            </AntButton>
          </div>
        </div>
      </Modal>

      <Modal
        centered
        open={modalChangeStatus.visible}
        onClose={() => setModalChangeStatus({ visible: false })}
        footer={null}
      >
        <div className="mx-md-5 p-3">
          <div className="text-center">
            <h2 className="heading1 mb-3">
              {modalChangeStatus.status === "active"
                ? "Deactivate "
                : "Activate "}
              Class
            </h2>
            <p className="pb-3">
              Please confirm you want to
              {modalChangeStatus.status === "active"
                ? " deactivate "
                : " activate "}{" "}
              this Class.
            </p>
            <div className="b-gray justify-content-center rounded-lg mb-3 p-3">
              <Row className="mb-3">
                <Col>
                  <span className="lightaquamarine">Class Name</span> <br />
                  {modalChangeStatus?.name}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="lightaquamarine">Date</span> <br />
                  {modalChangeStatus?.date}
                </Col>
              </Row>
            </div>
            {modalChangeStatus.status === "active" && (
              <span className="text-danger fs-12">
                Deactivate this class will cancel all the bookings schedules.
              </span>
            )}
          </div>

          <div className="mt-3 d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              style={{
                border: 0,
                backgroundColor: "#ffffff",
                color: studio_detail?.styles?.header_color,
              }}
              onClick={() => setModalChangeStatus({ visible: false })}
            />
            <Button
              isLoading={isLoading}
              title="SEND REQUEST"
              type="primary"
              style={{
                backgroundColor: studio_detail?.styles?.header_color,
              }}
              onClick={handleChangeSatusClass}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        open={modal1.visible}
        onClose={() => setModal1({ visible: false })}
        footer={null}
        closable={false}
      >
        <div className="p-3">
          <div className="text-center">
            <h2 className=" font-medium">SEND CLASS</h2>
          </div>
          <p className="font-semibold text-base my-3">Send this class ?</p>
          <div className="flex my-3 flex-row justify-between items-center">
            <span className="font-semibold text-base">{modal1.name}</span>
            <Tag
              color={modal1.isOnline ? "#27AE60" : "#EB5757"}
              className={"capitalize"}
            >
              {modal1.isOnline ? "Online" : "Offline"}
            </Tag>
          </div>
          <div className="flex flex-col gap-1 my-3">
            <div className="flex flex-row">
              <span
                className="min-w-[150px] font-semibold whitespace-nowrap "
                style={{ color: studio_detail?.styles?.header_color }}
              >
                Schedule
              </span>
              <span>November 14, 2023 12:30 - 14:00</span>
            </div>
            <div className="flex flex-row">
              <span
                className="min-w-[150px] font-semibold whitespace-nowrap"
                style={{ color: studio_detail?.styles?.header_color }}
              >
                Days
              </span>
              <span>Monday - Tuesday - Wednesday - Saturday</span>
            </div>
            <div className="flex flex-row">
              <span
                className="min-w-[150px] font-semibold whitespace-nowrap"
                style={{ color: studio_detail?.styles?.header_color }}
              >
                Repeat Class
              </span>
              <span>Until 10x</span>
            </div>
            <div className="flex flex-row">
              <span
                className="min-w-[150px] font-semibold whitespace-nowrap"
                style={{ color: studio_detail?.styles?.header_color }}
              >
                Teacher
              </span>
              <span className="font-semibold">{modal1?.teachers}</span>
            </div>
          </div>

          <p className="font-semibold text-base">Send to :</p>
          <Select
            placeholder="search user or type email address"
            onChange={(e) => setSelectedOption(e)}
            showSearch
            options={options}
            className="w-full"
            size="large"
          />
          <div className="w-full my-3 cursor-pointer">
            {listSelectedOption.length > 0 && (
              <>
                {listSelectedOption.map((el, idx) => (
                  <Tag
                    key={idx}
                    color={
                      hexToRGBA(studio_detail?.styles?.header_color, 0.7) ||
                      "#fafafa"
                    }
                    closable
                    onClose={() => handleDelete(idx)}
                    className="p-1 m-1 font-normal text-sm"
                  >
                    {el?.user_email}
                  </Tag>
                ))}
              </>
            )}
          </div>

          <div className="flex flex-col items-center gap-3 w-full">
            <AntButton
              loading={loading}
              className={"w-full"}
              type="primary"
              size="large"
              onClick={handleInvitation}
              style={{
                backgroundColor:
                  studio_detail?.styles?.header_color || "#E26851",
              }}
            >
              Send Classes
            </AntButton>
            <AntButton
              type="default"
              style={{
                borderColor: studio_detail?.styles?.header_color || "#E26851",
                color: studio_detail?.styles?.header_color || "#E26851",
              }}
              size="large"
              className="w-full"
              onClick={() => {
                setListSelectedOption([]);
                setModal1({ visible: false });
              }}
            >
              Cancel
            </AntButton>
          </div>
        </div>
      </Modal>

      <Modal
        centered
        open={modalSendAllActivities}
        onCancel={() => setModalSendAllActivities(false)}
        footer={null}
      >
        <div className=" p-3">
          <div className="text-center">
            <h3 className="mb-3 font-medium">SEND ALL CLASSES</h3>
          </div>
          <p className="font-semibold text-base">Sent to :</p>
          <Select
            placeholder="search user or type email address"
            onChange={(e) => setSelectedOption(e)}
            options={options}
            className="w-full my-3"
            size="large"
          />
          <div className="w-full my-3 cursor-pointer">
            {listSelectedOption.length > 0 && (
              <>
                {listSelectedOption.map((el, idx) => (
                  <Tag
                    key={idx}
                    color={
                      hexToRGBA(studio_detail?.styles?.header_color, 0.7) ||
                      "#fafafa"
                    }
                    closable
                    onClose={() => handleDelete(idx)}
                    className="p-1 m-1 font-normal text-sm"
                  >
                    {el?.user_email}
                  </Tag>
                ))}
              </>
            )}
          </div>
          <div className="flex flex-col items-center gap-3 w-full">
            <AntButton
              loading={loading}
              className={"w-full"}
              type="primary"
              size="large"
              onClick={handleInvitation}
              style={{
                backgroundColor:
                  studio_detail?.styles?.header_color || "#E26851",
              }}
            >
              Send Classes
            </AntButton>
            <AntButton
              type="default"
              style={{
                borderColor: studio_detail?.styles?.header_color || "#E26851",
                color: studio_detail?.styles?.header_color || "#E26851",
              }}
              size="large"
              className="w-full"
              onClick={() => {
                setListSelectedOption([]);
                setModalSendAllActivities(false);
              }}
            >
              Cancel
            </AntButton>
          </div>
        </div>
      </Modal>
    </>
  );
};
