import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./../students.styles.scss";
import Button from "../../../../components/Button/button.component";
import AtomNumberFormat from "../../../../components/number-format";
import Dot from "../../../../assets/images/3dots.svg";
import hourglass from "../../../../assets/images/hourglass.svg";

import { useCookies } from "react-cookie";

import {
  getListPlanTransaction,
  deactivatePlan,
  setStatusDefault,
} from "../../../../redux/student/student.action";
import AtomLoading from "../../../../components/loding-indicator";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import moment from "moment";
import { setMenuActive } from "../../../../redux/user/user.action";
import { continuePayment } from "../../../../services/payment";
import {
  Col,
  ConfigProvider,
  Divider,
  Input,
  Modal,
  Popover,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";

import dayjs from "dayjs";
import { sendGetRequest } from "../../../../services/students";

const statusColors = {
  Active: "#27AE60",
  Finish: "#27AE60",
  Pending: "#8A9AB3",
  Deactivated: "#EF4136",
  paid: "#27AE60",
  settlement: "#0283CF",
  cancelled: "#EF4136",
  refunded: "#9C15FF",
  pending: "#8A9AB3",
};

const PlanList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [action, setAction] = useState({
    isDetail: false,
    isDeactivate: false,
    selectedPlan: {
      buy_date: "",
      expire_date: "",
      key: "",
      name: "",
      plan_status: "",
      sessions: 0,
      price: 0,
      payment: {
        payment_method: "",
        payment_status: "",
        payment_type: "",
        price: 0,
        tax: 11,
        tax_amount: 0,
        total_amount: 0,
      },
    },
    propsPayment: null,
  });
  const [cookies, setCookies] = useCookies(["user"]);
  const [active, setActive] = useState(1);
  const [sort, setsort] = useState("asc");
  const [field, setField] = useState("plan_name");
  const [message, setMessage] = useState("");

  const { client_key, isRegisterStudent, cancellation_setting } = useSelector(
    (state) => state.studio
  );
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const { isLoading, transaction_list, statusDeactive } = useSelector(
    (state) => state.student
  );

  const subdomain = window.location.host.split(".")[0];
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const tableHead = [
    { name: "PLAN NAME", value: "plan_name" },
    { name: "PRICE", value: "total_amount" },
    { name: "EXPIRE DATE", value: "expired_date" },
    { name: "BUY DATE", value: "order_date" },
    { name: "SESSIONS", value: "" },
    { name: "PLAN STATUS", value: "plan_status" },
    { name: "" },
    { name: "" },
  ];

  const mobileSize = useMemo(() => window.screen.width < 576, []);

  const handleDeactivatePlan = () => {
    let params = {
      message_deactived: message,
    };

    dispatch(
      deactivatePlan(user_token, studio_id, params, action.selectedPlan.key)
    );
    dispatch(setStatusDefault());

    setMessage("");
    setAction((prev) => ({ ...prev, isDeactivate: false }));
  };

  useEffect(() => {
    dispatch(setMenuActive("Plans"));
  }, []);

  useEffect(() => {
    const url = `/orders/plan?page=${active}&sort_order=${sort}`;
    dispatch(getListPlanTransaction(user_token, studio_id, url));
  }, [active, dispatch, field, sort, statusDeactive, studio_id, user_token]);

  const handleContinuePayment = async (order_id) => {
    try {
      const res = await continuePayment(user_token, studio_id, order_id);
      history.push({
        pathname: "/waiting-payment",
        state: { data: res.data.data, buy_class: false },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusOrder = async (order_id) => {
    try {
      const { data } = await sendGetRequest(
        user_token,
        studio_id,
        `/orders/${order_id}`
      );

      setAction((prev) => ({ ...prev, propsPayment: data.data }));
    } catch (error) {}
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Expire Date",
      dataIndex: "expire_date",
      key: "expire_date",
    },
    {
      title: "Buy Date",
      dataIndex: "buy_date",
      key: "buy_date",
    },
    {
      title: "Sessions",
      dataIndex: "sessions",
      key: "sessions",
    },
    {
      title: "Plan Status",
      dataIndex: "plan_status",
      key: "plan_status",
      render: (text) => {
        const color = statusColors[text];
        return (
          <Tag color={color} className="capitalize">
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Popover
            placement="bottomRight"
            content={() => (
              <div className="grid">
                {record.plan_status === "pending" && (
                  <span
                    className={`cursor-pointer my-2 custom-hover`}
                    onClick={() =>
                      window.open(
                        record?.payment?.gateway_response?.url ||
                          record.stripe_checkout_session_url
                      )
                    }
                  >
                    Pay Now
                  </span>
                )}

                <span
                  onClick={() => {
                    setAction((prev) => ({
                      ...prev,
                      selectedPlan: record,
                      isDetail: true,
                      isDeactivate: false,
                    }));
                  }}
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                >
                  Detail
                </span>

                <span
                  onClick={() => {
                    setAction((prev) => ({
                      ...prev,
                      selectedPlan: record,
                      isDetail: true,
                    }));
                  }}
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                >
                  Invoice
                </span>

                <span
                  onClick={() => {
                    setAction((prev) => ({
                      ...prev,
                      selectedPlan: record,
                      isDeactivate: true,
                      isDetail: false,
                    }));
                  }}
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 text-red-600 font-semibold"
                >
                  Deactivate
                </span>
              </div>
            )}
          >
            <img className="cursor-pointer" src={Dot} alt="" />
          </Popover>
        );
      },
    },
  ];

  const { key, name, sessions, price, buy_date, expire_date, payment } =
    action.selectedPlan;

  const classDetail = [
    { label: "Transaction ID", value: key },
    { label: "Session Limit", value: sessions },
    { label: "Purchased Date", value: buy_date },
    { label: "Expire Date", value: expire_date },
    { label: "Payment Method", value: payment.payment_method },
  ];

  const expiredDate = action.propsPayment?.payment?.expired_date;

  // State to store hours, minutes, and seconds
  const [timeRemaining, setTimeRemaining] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  // Function to calculate the time difference
  const calculateTimeLeft = () => {
    const now = dayjs();
    const expiration = dayjs(expiredDate);

    if (expiration.isAfter(now)) {
      const diffInSeconds = expiration.diff(now, "second");
      const hours = String(Math.floor(diffInSeconds / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((diffInSeconds % 3600) / 60)).padStart(
        2,
        "0"
      );
      const seconds = String(diffInSeconds % 60).padStart(2, "0");

      setTimeRemaining({
        hours,
        minutes,
        seconds,
      });
    } else {
      // If the time has expired, set everything to '00'
      setTimeRemaining({
        hours: "00",
        minutes: "00",
        seconds: "00",
      });
    }
  };

  useEffect(() => {
    if (action.propsPayment?.payment?.payment_status === "pending") {
      // Update the countdown every second
      const timer = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      // Cleanup timer on component unmount
      return () => clearInterval(timer);
    }
  }, [action.propsPayment?.payment?.payment_status]);

  console.log({ action });

  return (
    <>
      <OrganismLayout title="Plans" maxWidth="72rem">
        {false ? (
          <AtomLoading />
        ) : (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
                  headerBorderRadius: 0,
                },
              },
            }}
          >
            <h2 className="font-bold">Plans Transactions</h2>
            <div className="w-full flex justify-end">
              <Button
                title="Buy Plan"
                type="primary"
                style={{
                  borderRadius: 0,
                  backgroundColor: colorTheme,
                  margin: "1rem 0",
                }}
                onClick={() => {
                  history.push("/buy-plan");
                }}
              />
            </div>

            <div className="" style={{ border: `1px solid ${colorTheme}` }}>
              <Table
                rowClassName="pointer"
                onRow={(record) => {
                  return {
                    onClick: () => {
                      setAction((prev) => ({
                        ...prev,
                        selectedPlan: record,
                        isDetail: true,
                        isDeactivate: false,
                      }));
                    },
                  };
                }}
                columns={columns}
                dataSource={
                  transaction_list?.data?.length > 0 &&
                  transaction_list?.data.map((el) => {
                    return {
                      ...el,
                      key: el._id,
                      name: el.plan.plan_name,
                      price: (
                        <AtomNumberFormat
                          prefix="IDR "
                          value={el?.payment?.total_amount}
                        />
                      ),
                      expire_date: moment(el?.plan?.end_valid_date).format(
                        "DD MMMM YYYY"
                      ),
                      buy_date: moment(el?.plan?.start_valid_date).format(
                        "DD MMMM YYYY"
                      ),
                      sessions:
                        el.el?.current_credits ?? el?.current_usage_limit,
                      plan_status: el?.plan_status
                        .toLowerCase()
                        .replace(/\w/, (firstLetter) =>
                          firstLetter.toUpperCase()
                        ),
                    };
                  })
                }
              />
            </div>
          </ConfigProvider>
        )}
      </OrganismLayout>

      <Modal
        footer={null}
        closable={false}
        open={action.isDeactivate}
        onCancel={() => setAction((prev) => ({ ...prev, isDeactivate: false }))}
      >
        <h5 className="text-center">Deactivate Plan</h5>

        <div className="text-[#475569] p-4">
          <p>Please confirm you want to deactivate this plan.</p>

          <div className="flex items-center">
            <label className="font-bold w-[40%]">Plan Name</label>
            <b>{action.selectedPlan.name}</b>
          </div>

          <div className="flex items-center">
            <label className="font-bold w-[40%]">Transaction ID</label>
            <b>{action.selectedPlan.key}</b>
          </div>

          <div className="flex items-center">
            <label className="font-bold w-[40%]">Purchased Date</label>
            <span>{action.selectedPlan.buy_date}</span>
          </div>

          <div className="flex items-center ">
            <label className="font-bold w-[40%]">Expire Date</label>
            <span>{action.selectedPlan.expire_date}</span>
          </div>

          <Divider />

          <label className="font-bold mb-2">Your messages</label>
          <Input.TextArea
            rows={4}
            placeholder="Type your text here.."
            onChange={(e) =>
              setAction((prev) => ({ ...prev, notes: e.target.value }))
            }
          />

          <Row justify="center" className="mt-8">
            <Col>
              <Space direction="vertical">
                <Button
                  type="primary"
                  title="Send Request"
                  onClick={() => handleDeactivatePlan()}
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#EF4136",
                  }}
                />

                <Button
                  type="secondary"
                  title="Cancel"
                  onClick={() =>
                    setAction((prev) => ({ ...prev, isDeactivate: false }))
                  }
                  style={{
                    border: 0,
                    borderRadius: 0,
                    backgroundColor: "#fff",
                    color: colorTheme,
                  }}
                />
              </Space>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        footer={null}
        closable={false}
        open={action.isDetail}
        onCancel={() => setAction((prev) => ({ ...prev, isDetail: false }))}
      >
        {action.propsPayment?.payment?.payment_status === "pending" && (
          <div className="grid justify-items-center gap-2 mb-4">
            <img src={hourglass} alt="" />
            <b className="text-2xl">Pending Payment</b>
            <p>
              Please complete the payment before:{" "}
              {action.propsPayment?.payment?.expired_date}
            </p>
            <div
              className="rounded-lg py-2 px-6"
              style={{ backgroundColor: hexToRGBA("#E2685133", 0.2) }}
            >
              <b className="text-2xl">
                {`${timeRemaining.hours} : ${timeRemaining.minutes} : ${timeRemaining.seconds}`}
              </b>
            </div>
            <Button
              type="primary"
              title="Continue Payment"
              onClick={() =>
                window.open(
                  action.propsPayment?.payment?.gateway_response?.url ??
                    action.propsPayment?.stripe_checkout_session_url
                )
              }
              style={{
                width: 170,
                borderRadius: 0,
                backgroundColor: colorTheme,
              }}
            />
          </div>
        )}

        <h5 className="text-center">Detail</h5>

        <div className="text-[#475569] p-4">
          <b>{name}</b>

          <div className="flex items-center mt-4">
            <label className="font-bold w-[40%]">Status</label>
            <Tag
              color={statusColors[payment.payment_status]}
              className="capitalize"
            >
              {payment.payment_status}
            </Tag>
          </div>

          {classDetail.map((item) => (
            <div className="flex items-center">
              <label className="font-bold w-[40%]">{item.label}</label>
              <span className="capitalize">{item.value}</span>
            </div>
          ))}

          <Divider />

          <div className="flex justify-between">
            <Space direction="vertical">
              <label className="font-bold">Price</label>
              <label className="font-bold">Tax</label>
              <label className="font-bold">Total Payment</label>
            </Space>

            <Space direction="vertical">
              <span>{payment.price}</span>
              <span>{payment.tax}</span>
              <span>{price}</span>
            </Space>
          </div>

          <Row justify="center" className="mt-8">
            <Col>
              <Space direction="vertical">
                <Button
                  type="primary"
                  title="Back"
                  onClick={() =>
                    setAction((prev) => ({ ...prev, isDetail: false }))
                  }
                  style={{
                    borderRadius: 0,
                    backgroundColor: colorTheme,
                  }}
                />
              </Space>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default PlanList;
