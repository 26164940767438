const StudentActionTypes = {
  SET_STATUS_DEFAULT: "SET_STATUS_DEFAULT",
  SET_MSG_PAYMENT: "SET_MSG_PAYMENT",

  // Dashboard
  GET_ACTIVE_PLAN: "GET_ACTIVE_PLAN",
  GET_ACTIVE_PLAN_SUCCESS: "GET_ACTIVE_PLAN_SUCCESS",
  GET_ACTIVE_PLAN_FAILED: "GET_ACTIVE_PLAN_FAILED",

  GET_CLASSES: "GET_CLASSES",
  GET_CLASSES_SUCCESS: "GET_CLASSES_SUCCESS",
  GET_CLASSES_FAILED: "GET_CLASSES_FAILED",

  // Profile
  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILED: "GET_PROFILE_FAILED",

  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",

  GET_USER_ROLE: "GET_USER_ROLE",
  GET_USER_ROLE_SUCCESS: "GET_USER_ROLE_SUCCESS",
  GET_USER_ROLE_FAILED: "GET_USER_ROLE_FAILED",

  // Change Email
  REQUEST_OTP_CHANGE_EMAIL: "REQUEST_OTP_CHANGE_EMAIL",
  REQUEST_OTP_CHANGE_EMAIL_SUCCESS: "REQUEST_OTP_CHANGE_EMAIL_SUCCESS",
  REQUEST_OTP_CHANGE_EMAIL_FAILED: "REQUEST_OTP_CHANGE_EMAIL_FAILED",
  REQUEST_OTP_CHANGE_EMAIL_DEFAULT: "REQUEST_OTP_CHANGE_EMAIL_DEFAULT",

  VERIFY_OTP_EMAIL: "VERIFY_OTP_EMAIL",
  VERIFY_OTP_EMAIL_SUCCESS: "VERIFY_OTP_EMAIL_SUCCESS",
  VERIFY_OTP_EMAIL_FAILED: "VERIFY_OTP_EMAIL_FAILED",
  VERIFY_OTP_EMAIL_DEFAULT: "VERIFY_OTP_EMAIL_DEFAULT",

  CHANGE_EMAIL: "CHANGE_EMAIL",
  CHANGE_EMAIL_SUCCESS: "CHANGE_EMAIL_SUCCESS",
  CHANGE_EMAIL_FAILED: "CHANGE_EMAIL_FAILED",

  // Plan
  GET_LIST_PLAN_TRANSACTION: "GET_LIST_PLAN_TRANSACTION",
  GET_LIST_PLAN_TRANSACTION_SUCCESS: "GET_LIST_PLAN_TRANSACTION_SUCCESS",
  GET_LIST_PLAN_TRANSACTION_FAILED: "GET_LIST_PLAN_TRANSACTION_FAILED",

  GET_LIST_OFFERING_PLAN: "GET_LIST_OFFERING_PLAN",
  GET_LIST_OFFERING_PLAN_SUCCESS: "GET_LIST_OFFERING_PLAN_SUCCESS",
  GET_LIST_OFFERING_PLAN_FAILED: "GET_LIST_OFFERING_PLAN_FAILED",

  CALLBACK_MIDTRANS: "CALLBACK_MIDTRANS",
  CALLBACK_MIDTRANS_SUCCESS: "CALLBACK_MIDTRANS_SUCCESS",
  CALLBACK_MIDTRANS_FAILED: "CALLBACK_MIDTRANS_FAILED",

  DEACTIVE_PLAN: "DEACTIVE_PLAN",
  DEACTIVE_PLAN_SUCCESS: "DEACTIVE_PLAN_SUCCESS",
  DEACTIVE_PLAN_FAILED: "DEACTIVE_PLAN_FAILED",

  // Class
  GET_LIST_OFFERING_CLASSES: "GET_LIST_OFFERING_CLASSES",
  GET_LIST_OFFERING_CLASSES_SUCCESS: "GET_LIST_OFFERING_CLASSES_SUCCESS",
  GET_LIST_OFFERING_CLASSES_FAILED: "GET_LIST_OFFERING_CLASSES_FAILED",

  GET_DETAIL_OFFERING_CLASSES: "GET_DETAIL_OFFERING_CLASSES",
  GET_DETAIL_OFFERING_CLASSES_SUCCESS: "GET_DETAIL_OFFERING_CLASSES_SUCCESS",
  GET_DETAIL_OFFERING_CLASSES_FAILED: "GET_DETAIL_OFFERING_CLASSES_FAILED",

  GET_PUBLIC_LIST_OFFERING_CLASSES: "GET_PUBLIC_LIST_OFFERING_CLASSES",
  GET_PUBLIC_LIST_OFFERING_CLASSES_SUCCESS:
    "GET_PUBLIC_LIST_OFFERING_CLASSES_SUCCESS",
  GET_PUBLIC_LIST_OFFERING_CLASSES_FAILED:
    "GET_PUBLIC_LIST_OFFERING_CLASSES_FAILED",

  GET_MY_ACTIVE_PLAN: "GET_MY_ACTIVE_PLAN",
  GET_MY_ACTIVE_PLAN_SUCCESS: "GET_MY_ACTIVE_PLAN_SUCCESS",
  GET_MY_ACTIVE_PLAN_FAILED: "GET_MY_ACTIVE_PLAN_FAILED",

  GET_MY_ACTIVE_CLASS: "GET_MY_ACTIVE_CLASS",
  GET_MY_ACTIVE_CLASS_SUCCESS: "GET_MY_ACTIVE_CLASS_SUCCESS",
  GET_MY_ACTIVE_CLASS_FAILED: "GET_MY_ACTIVE_CLASS_FAILED",

  CANCEL_CLASS: "CANCEL_CLASS",
  CANCEL_CLASS_SUCCESS: "CANCEL_CLASS_SUCCESS",
  CANCEL_CLASS_FAILED: "CANCEL_CLASS_FAILED",

  ACTIVE_PLAN: "ACTIVE_PLAN",
  ACTIVE_PLAN_SUCCESS: "ACTIVE_PLAN_SUCCESS",
  ACTIVE_PLAN_FAILED: "ACTIVE_PLAN_FAILED",

  ACTIVE_CLASS: "ACTIVE_CLASS",
  ACTIVE_CLASS_SUCCESS: "ACTIVE_CLASS_SUCCESS",
  ACTIVE_CLASS_FAILED: "ACTIVE_CLASS_FAILED",

  // Payment
  MANUAL: "MANUAL",
  MANUAL_SUCCESS: "MANUAL_SUCCESS",
  MANUAL_FAILED: "MANUAL_FAILED",

  GET_DETAIL_PLAN_TRANSACTION: "GET_DETAIL_PLAN_TRANSACTION",
  GET_DETAIL_PLAN_TRANSACTION_SUCCESS: "GET_DETAIL_PLAN_TRANSACTION_SUCCESS",
  GET_DETAIL_PLAN_TRANSACTION_FAILED: "GET_DETAIL_PLAN_TRANSACTION_FAILED",

  GET_DETAIL_CLASS_TRANSACTION: "GET_DETAIL_CLASS_TRANSACTION",
  GET_DETAIL_CLASS_TRANSACTION_SUCCESS: "GET_DETAIL_CLASS_TRANSACTION_SUCCESS",
  GET_DETAIL_CLASS_TRANSACTION_FAILED: "GET_DETAIL_CLASS_TRANSACTION_FAILED",

  // Payment Verification
  PAYMENT_VERIFICATION: "PAYMENT_VERIFICATION",
  PAYMENT_VERIFICATION_SUCCESS: "PAYMENT_VERIFICATION_SUCCESS",
  PAYMENT_VERIFICATION_FAILED: "PAYMENT_VERIFICATION_FAILED",
};

export default StudentActionTypes;
