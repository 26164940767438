import React, { useEffect } from "react";

import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Row, Col, Image } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import AtomNumberFormat from "../../../../components/number-format";
import sampleImg from "./../../../../assets/images/placeholder.png";
import "./../students.styles.scss";

import OrganismLayout from "../../../../components/Layout/organism-layout";
import { toast } from "react-toastify";
import { continuePayment } from "../../../../services/payment";
import { useCookies } from "react-cookie";
import hexToRGBA from "../../../../helper/hexToRbga";

const WaitingPayment = (props) => {
  const history = useHistory();

  const { data, buy_class } = props.location?.state;
  const [cookies, setCookies] = useCookies(["user"]);

  const { isLoadingOrder, class_detail, order_resp } = useSelector(
    (state) => state.student,
  );

  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user,
  );
  const user_token = loginFromStudio ? user?.token : cookies.user?.token;
  const studio_id = loginFromStudio ? user?.studio_id : cookies.user?.studio_id;
  const themeColor = studio_detail?.styles?.header_color;
  const handleContinuePayment = async (order_id) => {
    try {
      const res = await continuePayment(user_token, studio_id, order_id);
      toast.info("Thank You! Your Payment is Being Proceed.");
      history.push({
        pathname: "/dashboard",
        state: {
          payment_pending: res.data.data.payment?.payment_status === "pending",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(data);
    console.log(studio_detail);
  }, [data]);

  return (
    <OrganismLayout maxWidth='42rem'>
      <div className='flex justify-center flex-col items-center p-5'>
        <h1 className=' font-bold '>Payment Verification</h1>
        <div className='w-full md:w-[549px] gap-3 flex flex-col mt-5'>
          <div className='w-full'>
            <span
              tyle={{ color: themeColor }}
              className='inline-block w-2/5 font-bold'
            >
              Total
            </span>
            <span className=' text-slate-600 font-bold'>
              IDR {data.payment?.total_amount.toLocaleString()}
            </span>
          </div>
          <div className='w-full'>
            <span
              style={{ color: themeColor }}
              className='inline-block w-2/5 font-bold'
            >
              Order ID
            </span>
            <span className=' text-slate-600 font-bold'>
              {data?.order_number.split("-")[0]}
            </span>
          </div>
          <div className='w-full'>
            <span
              style={{ color: themeColor }}
              className='inline-block w-2/5 font-bold'
            >
              Payment Method
            </span>
            <span className=' text-slate-600 font-bold'>
              {data?.payment?.payment_method}
            </span>
          </div>
          <div className='w-full'>
            <span
              style={{ color: themeColor }}
              className='inline-block w-2/5 font-bold'
            >
              Bank
            </span>
            <span className=' text-slate-600'>
              {data.payment?.bank_name ?? data.payment?.ewallet_name}
            </span>
          </div>
          <div className='w-full'>
            <span
              style={{ color: themeColor }}
              className='inline-block w-2/5 font-bold'
            >
              Account Number
            </span>
            <span className=' text-slate-600'>
              {data.payment.gateway_response?.va_number ??
                data.payment.gateway_response?.ref_number}
            </span>
          </div>
          <div className='w-full'>
            <span
              style={{ color: themeColor }}
              className='inline-block w-2/5 font-bold'
            >
              Account Name
            </span>
            <span className=' text-slate-600'>
              {data?.payment.gateway_response.username_display}
            </span>
          </div>
          <div className='w-full h-[1px] bg-[#E0E0E0] my-1' />
          <div
            style={{ backgroundColor: hexToRGBA(themeColor, 0.1) }}
            className='w-full py-3 flex flex-col items-center gap-1'
          >
            <span className='font-bold'>Please complete the payment</span>
            <span className=' font-normal text-sm'>
              Pay Before :{" "}
              {moment(data?.payment?.expired_date).format("DD MMM YYYY, HH.mm")}{" "}
              WIB
            </span>
          </div>
          <div className='flex w-full justify-between'>
            <Button
              onClick={() => {
                handleContinuePayment(data._id);
              }}
              // type='outline-secondary'
              title='Back '
              style={{
                backgroundColor: "#fff",
                color: themeColor,
                border: `1px solid ${hexToRGBA(themeColor, 0.1)}`,
                borderRadius: 0,
              }}
            />
            <Button
              onClick={() =>
                history.push({
                  pathname: "/payment-verification",
                  // state: state,
                })
              }
              style={{ backgroundColor: themeColor, borderRadius: 0 }}
              type='primary'
              title='CONFIRM'
            />
          </div>
        </div>
        {/* <div className='bg-pink rounded-lg p-3 mb-3 text-center fw-bold'>
          Pay Before :{" "}
          {moment(data?.payment?.expired_date).format("DD MMM YYYY, HH.mm")} WIB
        </div>
        <div className='b-gray rounded-lg p-3 mb-3'>
          <p>
            <b>{data.class?.class_name}</b>
          </p>
          <Row>
            <Col>Total</Col>
            <Col className='d-flex justify-content-end fs-4'>
              <AtomNumberFormat
                prefix='IDR '
                value={data.payment?.total_amount}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>Order ID</Col>
            <Col className='d-flex justify-content-end'>
              {data?.order_number.split("-")[0]}
            </Col>
          </Row>
        </div>
        <div className='lh-1 b-gray rounded-lg p-3 mb-5'>
          <Row>
            <Col>
              <p className='custom-form-label'>Payment Method</p>
              <p>Online</p>
              <Image src={data.payment?.image ?? sampleImg} width={80} />
            </Col>
            <Col className='border-1 border-start'>
              <p>{data.payment?.bank_name ?? data.payment?.ewallet_name}</p>
              <p className='custom-form-label'>{`${
                data.payment?.payment_type === "e-wallet"
                  ? "Ref"
                  : "Virtual Account"
              } Number`}</p>
              <p>
                {data.payment.gateway_response?.va_number ??
                  data.payment.gateway_response?.ref_number}
              </p>
            </Col>
          </Row>
        </div> */}
      </div>
    </OrganismLayout>
  );
};

export default WaitingPayment;
