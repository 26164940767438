import React from "react";
import "./select-input.styles.scss";

const SelectInput = ({
  label,
  data,
  onChange,
  className,
  disabled,
  type,
  selectClassName,
  required,
  isEdit,
  bankCode,
}) => {
  if (type === "listTeacher") {
    return (
      <div className={`form-group ${className}`}>
        <label className="custom-form-label">{label}</label>
        <select
          onChange={onChange}
          className="select-input p-2"
          disabled={disabled}
          required={required}
        >
          <option value="">- Choose Teacher -</option>
          {data?.length > 0
            ? data.map((element, idx) => (
                <option key={idx} value={element?.id}>
                  {element?.first_name} {element?.last_name}
                </option>
              ))
            : null}
        </select>
      </div>
    );
  } else {
    return (
      <div className={`form-group ${className}`}>
        <label className="custom-form-label">{label}</label>
        <select
          onChange={onChange}
          className={`${selectClassName} select-input p-2`}
          disabled={disabled}
          required={required}
        >
          {data
            ? data.map((element, idx) => (
                <option
                  key={idx}
                  selected={
                    isEdit ? element.code === bankCode : element.selected
                  }
                  value={element.code ? element.code : element.value}
                >
                  {element.label ?? element.name ?? element.class_name}
                </option>
              ))
            : null}
        </select>
      </div>
    );
  }
};

export default SelectInput;
