const OwnerActionTypes = {
  ADD_STUDENT: "ADD_STUDENT",
  ADD_STUDENT_SUCCESS: "ADD_STUDENT_SUCCESS",
  ADD_STUDENT_FAILED: "ADD_STUDENT_FAILED",

  GET_STUDENT_DETAIL: "GET_STUDENT_DETAIL",
  GET_STUDENT_DETAIL_SUCCESS: "GET_STUDENT_DETAIL_SUCCESS",
  GET_STUDENT_DETAIL_FAILED: "GET_STUDENT_DETAIL_FAILED",

  GET_STUDENT_CLASS: "GET_STUDENT_CLASS",
  GET_STUDENT_CLASS_SUCCESS: "GET_STUDENT_CLASS_SUCCESS",
  GET_STUDENT_CLASS_FAILED: "GET_STUDENT_CLASS_FAILED",

  GET_STUDENT_PLAN: "GET_STUDENT_PLAN",
  GET_STUDENT_PLAN_SUCCESS: "GET_STUDENT_PLAN_SUCCESS",
  GET_STUDENT_PLAN_FAILED: "GET_STUDENT_PLAN_FAILED",

  GET_STUDENT_NOTES: "GET_STUDENT_NOTES",
  GET_STUDENT_NOTES_SUCCESS: "GET_STUDENT_NOTES_SUCCESS",
  GET_STUDENT_NOTES_FAILED: "GET_STUDENT_NOTES_FAILED",

  GET_STUDENT_ORDER: "GET_STUDENT_ORDER",
  GET_STUDENT_ORDER_SUCCESS: "GET_STUDENT_ORDER_SUCCESS",
  GET_STUDENT_ORDER_FAILED: "GET_STUDENT_ORDER_FAILED",

  UPDATE_STUDENT: "UPDATE_STUDENT",
  UPDATE_STUDENT_SUCCESS: "UPDATE_STUDENT_SUCCESS",
  UPDATE_STUDENT_FAILED: "UPDATE_STUDENT_FAILED",

  ADD_STUDENT_NOTES: "ADD_STUDENT_NOTES",
  ADD_STUDENT_NOTES_SUCCESS: "ADD_STUDENT_NOTES_SUCCESS",
  ADD_STUDENT_NOTES_FAILED: "ADD_STUDENT_NOTES_FAILED",

  ADD_TEACHER: "ADD_TEACHER",
  ADD_TEACHER_SUCCESS: "ADD_TEACHER_SUCCESS",
  ADD_TEACHER_FAILED: "ADD_TEACHER_FAILED",
  SET_STATUS_DEFAULT_ADD_TEACHER: "SET_STATUS_DEFAULT_ADD_TEACHER",

  GET_TEACHER_DETAIL: "GET_TEACHER_DETAIL",
  GET_TEACHER_DETAIL_SUCCESS: "GET_TEACHER_DETAIL_SUCCESS",
  GET_TEACHER_DETAIL_FAILED: "GET_TEACHER_DETAIL_FAILED",

  UPDATE_TEACHER: "UPDATE_TEACHER",
  UPDATE_TEACHER_SUCCESS: "UPDATE_TEACHER_SUCCESS",
  UPDATE_TEACHER_FAILED: "UPDATE_TEACHER_FAILED",

  GET_CLASS: "GET_CLASS",
  GET_CLASS_SUCCESS: "GET_CLASS_SUCCESS",
  GET_CLASS_FAILED: "GET_CLASS_FAILED",

  GET_CLASS_DETAIL: "GET_CLASS_DETAIL",
  GET_CLASS_DETAIL_SUCCESS: "GET_CLASS_DETAIL_SUCCESS",
  GET_CLASS_DETAIL_FAILED: "GET_CLASS_DETAIL_FAILED",

  GET_CLASS_PARTICIPANT: "GET_CLASS_PARTICIPANT",
  GET_CLASS_PARTICIPANT_SUCCESS: "GET_CLASS_PARTICIPANT_SUCCESS",
  GET_CLASS_PARTICIPANT_FAILED: "GET_CLASS_PARTICIPANT_FAILED",

  GET_CLASS_TIME_PARTICIPANT: "GET_CLASS_TIME_PARTICIPANT",
  GET_CLASS_TIME_PARTICIPANT_SUCCESS: "GET_CLASS_TIME_PARTICIPANT_SUCCESS",
  GET_CLASS_TIME_PARTICIPANT_FAILED: "GET_CLASS_TIME_PARTICIPANT_FAILED",

  ACTIVATE_CLASS: "ACTIVATE_CLASS",
  ACTIVATE_CLASS_SUCCESS: "ACTIVATE_CLASS_SUCCESS",
  ACTIVATE_CLASS_FAILED: "ACTIVATE_CLASS_FAILED",

  UPDATE_STATUS_ATTENDED: "UPDATE_STATUS_ATTENDED",
  UPDATE_STATUS_ATTENDED_SUCCESS: "UPDATE_STATUS_ATTENDED_SUCCESS",
  UPDATE_STATUS_ATTENDED_FAILED: "UPDATE_STATUS_ATTENDED_FAILED",

  UPDATE_PAYMENT_STATUS: "UPDATE_PAYMENT_STATUS",
  UPDATE_PAYMENT_STATUS_SUCCESS: "UPDATE_PAYMENT_STATUS_SUCCESS",
  UPDATE_PAYMENT_STATUS_FAILED: "UPDATE_PAYMENT_STATUS_FAILED",

  UPDATE_CLASS: "UPDATE_CLASS",
  UPDATE_CLASS_SUCCESS: "UPDATE_CLASS_SUCCESS",
  UPDATE_CLASS_FAILED: "UPDATE_CLASS_FAILED",

  GET_UPDATED_SCHEDULE: "GET_UPDATED_SCHEDULE",
  GET_UPDATED_SCHEDULE_SUCCESS: "GET_UPDATED_SCHEDULE_SUCCESS",
  GET_UPDATED_SCHEDULE_FAILED: "GET_UPDATED_SCHEDULE_FAILED",

  CLASSNAME_SETUP: "CLASSNAME_SETUP",
  ADD_SCHEDULE: "ADD_SCHEDULE",
  REMOVE_SCHEDULE: "REMOVE_SCHEDULE",
  ADD_DURATION: "ADD_DURATION",
  ADD_IDTEACHER: "ADD_IDTEACHER",
  REMOVE_IDTEACHER: "REMOVE_IDTEACHER",
  CLEAR_IDTEACHER: "CLEAR_IDTEACHER",
  CLEAR_CLASS_DETAIL: "CLEAR_CLASS_DETAIL",

  ADD_CLASS: "ADD_CLASS",
  ADD_CLASS_SUCCESS: "ADD_CLASS_SUCCESS",
  ADD_CLASS_FAILED: "ADD_CLASS_FAILED",

  ADD_PLAN: "ADD_PLAN",
  ADD_PLAN_SUCCESS: "ADD_PLAN_SUCCESS",
  ADD_PLAN_FAILED: "ADD_PLAN_FAILED",

  UPDATE_PLAN: "UPDATE_PLAN",
  UPDATE_PLAN_SUCCESS: "UPDATE_PLAN_SUCCESS",
  UPDATE_PLAN_FAILED: "UPDATE_PLAN_FAILED",

  DEACTIVE_PLANS: "DEACTIVE_PLANS",
  DEACTIVE_PLANS_SUCCESS: "DEACTIVE_PLANS_SUCCESS",
  DEACTIVE_PLANS_FAILED: "DEACTIVE_PLANS_FAILED",

  SET_STATUS_DEFAULT: "SET_STATUS_DEFAULT",

  GET_STUDENTS: "GET_STUDENTS",
  GET_STUDENTS_SUCCESS: "GET_STUDENTS_SUCCESS",
  GET_STUDENTS_FAILED: "GET_STUDENTS_FAILED",

  GET_TEACHERS: "GET_TEACHERS",
  GET_TEACHERS_SUCCESS: "GET_TEACHERS_SUCCESS",
  GET_TEACHERS_FAILED: "GET_TEACHERS_FAILED",

  GET_PAYMENT_DETAIL: "GET_PAYMENT_DETAIL",
  GET_PAYMENT_DETAIL_SUCCESS: "GET_PAYMENT_DETAIL_SUCCESS",
  GET_PAYMENT_DETAIL_FAILED: "GET_PAYMENT_DETAIL_FAILED",

  GET_PLANS: "GET_PLANS",
  GET_PLANS_SUCCESS: "GET_PLANS_SUCCESS",
  GET_PLANS_FAILED: "GET_PLANS_FAILED",

  GET_DETAIL_PLANS: "GET_DETAIL_PLANS",
  GET_DETAIL_PLANS_SUCCESS: "GET_DETAIL_PLANS_SUCCESS",
  GET_DETAIL_PLANS_FAILED: "GET_DETAIL_PLANS_FAILED",

  GET_STATISTIC_PROFILE: "GET_STATISTIC_PROFILE",
  GET_STATISTIC_PROFILE_SUCCESS: "GET_STATISTIC_PROFILE_SUCCESS",
  GET_STATISTIC_PROFILE_FAILED: "GET_STATISTIC_PROFILE_FAILED",

  GET_STATISTIC_PAYMENT: "GET_STATISTIC_PAYMENT",
  GET_STATISTIC_PAYMENT_SUCCESS: "GET_STATISTIC_PAYMENT_SUCCESS",
  GET_STATISTIC_PAYMENT_FAILED: "GET_STATISTIC_PAYMENT_FAILED",

  GET_STATISTIC_CANCELLATION: "GET_STATISTIC_CANCELLATION",
  GET_STATISTIC_CANCELLATION_SUCCESS: "GET_STATISTIC_CANCELLATION_SUCCESS",
  GET_STATISTIC_CANCELLATION_FAILED: "GET_STATISTIC_CANCELLATION_FAILED",

  GET_STATISTIC_UPCOMING_CLASS: "GET_STATISTIC_UPCOMING_CLASS",
  GET_STATISTIC_UPCOMING_CLASS_SUCCESS: "GET_STATISTIC_UPCOMING_CLASS_SUCCESS",
  GET_STATISTIC_UPCOMING_CLASS_FAILED: "GET_STATISTIC_UPCOMING_CLASS_FAILED",

  GET_STATISTIC_CLASS_IN_PROGRESS: "GET_STATISTIC_CLASS_IN_PROGRESS",
  GET_STATISTIC_CLASS_IN_PROGRESS_SUCCESS:
    "GET_STATISTIC_CLASS_IN_PROGRESS_SUCCESS",
  GET_STATISTIC_CLASS_IN_PROGRESS_FAILED:
    "GET_STATISTIC_CLASS_IN_PROGRESS_FAILED",

  GET_ONBOARDING_STATUS: "GET_ONBOARDING_STATUS",
  GET_ONBOARDING_STATUS_SUCCESS: "GET_ONBOARDING_STATUS_SUCCESS",
  GET_ONBOARDING_STATUS_FAILED: "GET_ONBOARDING_STATUS_FAILED",

  ADD_LOCATIONS_SUCCESS: "ADD_LOCATIONS_SUCCESS",
  ADD_LOCATIONS_DEFAULT: "ADD_LOCATIONS_DEFAULT",
};

export default OwnerActionTypes;
