import { toast } from "react-toastify";
import axios from "../../axiosInstance";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import StudioActionTypes from "./studio.types";

const getStudiosClientKeyUrl = "/studios/payment-key";
const registerStudioUrl = "/studios/register";
const registerStudentUrl = "/studios/students/register";
const editStudioUrl = "/studios/";
const chooseStudioUrl = "/studio/session";

export const setStatusDefault = () => {
  return (dispatch) => {
    dispatch({
      type: StudioActionTypes.SET_STATUS_DEFAULT,
    });
  };
};

export const setIsRegisterStudent = () => {
  return (dispatch) => {
    dispatch({
      type: StudioActionTypes.SET_IS_REGISTER_STUDENT,
    });
  };
};

export const registerStudioWithSocmedSuccess = (payload) => {
  return (dispatch) => {
    dispatch({
      type: StudioActionTypes.REGISTER_STUDIO_WITH_SOCMED_SUCCESS,
      payload: payload,
    });
  };
};

export const getClassCategories = (token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudioActionTypes.GET_CLASS_CATEGORIES,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };

      const res = await axios.get(
        `/studios/${studio_id}/categories?limit=0`,
        config
      );
      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.GET_CLASS_CATEGORIES_SUCCESS,
          payload: res?.data.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudioActionTypes.GET_CLASS_CATEGORIES_FAILED,
        message: error.response?.data?.message,
      });
    }
  };
};

export const registerStudio = (params) => {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: StudioActionTypes.REGISTER_STUDIO,
      });
      const res = await axios.post(registerStudioUrl, formData);

      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.REGISTER_STUDIO_SUCCESS,
          payload: res.data.data[0],
        });
      }
      toast.success("Success!");
    } catch (error) {
      dispatch({
        type: StudioActionTypes.REGISTER_STUDIO_FAILED,
        message: error.response.data.message,
      });
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const registerStudent = (params, subdomain) => {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return async (dispatch) => {
    try {
      dispatch({
        type: StudioActionTypes.REGISTER_STUDENT,
      });

      const config = {
        headers: {
          "content-type": "multipart/formData",
          subdomain: subdomain,
        },
      };

      const res = await axios.post(registerStudentUrl, formData, config);

      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.REGISTER_STUDENT_SUCCESS,
          payload: res.data,
        });
      }
      if (res.data.is_account_active === 0) {
        toast.info(res.data?.message);
      } else {
        toast.success("Registered Successfully!");
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudioActionTypes.REGISTER_STUDENT_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const EditStudio = (params, idStudio, token_jwt) => {
  const formData = new FormData();
  formData.append("studio_name", params.studio_name);
  formData.append("subdomain", params.subdomain);
  // formData.append("header_image", params.header_image);
  formData.append("logo", params.logo);

  return async (dispatch, getState) => {
    try {
      dispatch({
        type: StudioActionTypes.EDIT_STUDIO,
      });

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token_jwt}`,
        },
      };

      const res = await axios.post(
        `${editStudioUrl}${idStudio}`,
        formData,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.EDIT_STUDIO_SUCCESS,
          subdomain: params.subdomain,
          payload: res.data.data,
        });
      }
    } catch (error) {
      dispatch({
        type: StudioActionTypes.EDIT_STUDIO_FAILED,
        message: error.response.data.message,
      });
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const chooseStudio = (token, studio_id, subdomain) => {
  return async (dispatch) => {
    try {
      let params = {
        studio_id: studio_id,
      };

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      dispatch({
        type: StudioActionTypes.CHOOSE_STUDIO,
      });

      const res = await axios.post(chooseStudioUrl, params, config);

      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.CHOOSE_STUDIO_SUCCESS,
          subdomain: subdomain,
          payload: res.data.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudioActionTypes.CHOOSE_STUDIO_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getClientKey = (token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudioActionTypes.GET_CLIENT_KEY,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };
      const res = await axios.get(getStudiosClientKeyUrl, config);

      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.GET_CLIENT_KEY_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudioActionTypes.GET_CLIENT_KEY_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getCancellationSetting = (token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudioActionTypes.GET_CANCELLATION_SETTING,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };
      const res = await axios.get("/studios/cancellation-setting", config);

      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.GET_CANCELLATION_SETTING_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudioActionTypes.GET_CANCELLATION_SETTING_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getGeneralTermsPublic = (subdomain) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudioActionTypes.GET_GENERAL_TERMS_PUBLIC,
      });

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const res = await axios.get(`/general-terms/${subdomain}`, config);

      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.GET_GENERAL_TERMS_PUBLIC_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudioActionTypes.GET_GENERAL_TERMS_PUBLIC_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const getGeneralTerms = (token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudioActionTypes.GET_GENERAL_TERMS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };
      const res = await axios.get("/studios/general-term", config);

      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.GET_GENERAL_TERMS_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudioActionTypes.GET_GENERAL_TERMS_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const updateGeneralTerms = (params, token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudioActionTypes.UPDATE_GENERAL_TERMS,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };
      const res = await axios.put("/studios/term", params, config);

      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.UPDATE_GENERAL_TERMS_SUCCESS,
          payload: res.data.data[0],
        });
      }
      toast.success("Successfully Updated!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudioActionTypes.UPDATE_GENERAL_TERMS_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const updateCancellation = (params, token_jwt, studio_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: StudioActionTypes.UPDATE_CANCELLATION,
      });

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token_jwt}`,
          "Studio-Id": studio_id,
        },
      };
      const res = await axios.put(
        "/studios/cancellation-setting",
        params,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: StudioActionTypes.UPDATE_CANCELLATION_SUCCESS,
          payload: res.data.data[0],
        });
      }
      toast.success("Successfully Updated!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: StudioActionTypes.UPDATE_CANCELLATION_FAILED,
        message: error.response.data.message,
      });
    }
  };
};

export const OpenSubMenu = () => {
  return (dispatch) => {
    dispatch({
      type: StudioActionTypes.SUB_MENU_OPEN,
    });
  };
};

export const CloseSubMenu = () => {
  return (dispatch) => {
    dispatch({
      type: StudioActionTypes.SUB_MENU_CLOSE,
    });
  };
};
