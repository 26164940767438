import React, { useEffect, useState, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Col, Form } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import { useCookies } from "react-cookie";
import {
  getProfile,
  updateProfile,
  requestOtp,
  setStatusOtpDefault,
} from "./../../../../redux/student/student.action";
import AtomLoading from "../../../../components/loding-indicator";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { Input } from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";

const EditProfile = ({ type, OnCancel, setChangeEmail }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [profileUser, setProfile] = useState(null);
  const [previewUrl, setPrevieUrl] = useState("");
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { profile, isLoading, statusOtp, statusVerifyEmail } = useSelector(
    (state) => state.student,
  );
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user,
  );

  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const handleClick = () => {
    // dispatch(requestOtp(user_token, studio_id, email));
    setChangeEmail(true);
  };

  const submitProfile = (e) => {
    e.preventDefault();
    let params =
      user?.role === "teacher"
        ? {
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
          }
        : { first_name: firstName, last_name: lastName };
    dispatch(updateProfile(user_token, studio_id, params));
    setTimeout(() => {
      dispatch(getProfile(user_token, studio_id));
    }, 1000);
  };

  useEffect(() => {
    if (statusOtp === "success") {
      history.push({
        pathname: "/admin/verify-email",
        state: { verifyCurrentEmail: true },
      });
      dispatch(setStatusOtpDefault());
    }
  }, [statusOtp]);

  useEffect(() => {
    dispatch(getProfile(user_token, studio_id));
  }, [dispatch, studio_id, user_token]);

  useEffect(() => {
    setEmail(profile?.email ?? "");
    setFirstName(profile?.first_name ?? "");
    setLastName(profile?.last_name ?? "");
    user?.role === "teacher" && setPhoneNumber(profile?.phone_number);
  }, [profile, user?.role]);

  const beforeUpload = useCallback((file, header) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";

    if (!isJpgOrPng) {
      toast.error("You can only upload JPG/JPEG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isLt2M) {
      toast.error("Image must smaller than 1MB!");
    }

    if (isJpgOrPng && isLt2M) {
      const url = URL.createObjectURL(file);
      setPrevieUrl(url);
      setProfile(file);
    }
  }, []);

  if (type === "modal") {
    return (
      <>
        {isLoading ? (
          <AtomLoading />
        ) : (
          <div>
            <h1 className='text-center mb-5 font-bold'>EDIT PROFILE</h1>
            <Form onSubmit={submitProfile} className='mb-5'>
              <Form.Group>
                <TextInput
                  required
                  className='mt-4'
                  label='First Name'
                  placeholder='Input First Name...'
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
                <TextInput
                  className='mt-4'
                  label='Last Name'
                  placeholder='Input Last Name...'
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />

                <div className='d-grid d-md-flex justify-content-md-between mt-1'>
                  <span className=' l mt-2 font-bold'>Email Address</span>
                  {/* <span>{email}</span> */}
                  <u
                    className='pointer font-semibold  no-underline '
                    style={{ color: studio_detail?.styles?.header_color }}
                    onClick={handleClick}
                  >
                    Change Email
                  </u>
                </div>
                <Input value={email} disabled />
                {user?.role === "teacher" && (
                  <TextInput
                    max={13}
                    type='number'
                    className='mt-4'
                    label='Phone Number'
                    placeholder='Input Phone Number...'
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                  />
                )}
                <div
                  style={{
                    border: `1px solid ${hexToRGBA(
                      studio_detail?.styles?.header_color,
                      0.2,
                    )}`,
                  }}
                  className='flex p-8 justify-between items-center my-6'
                >
                  <img src={previewUrl} className='w-40' alt='' />
                  <div className='flex flex-col justify-center items-center '>
                    <p className='text-xl font-semibold'>
                      Upload Profile Picture
                    </p>
                    <input
                      type='file'
                      id='logo_image'
                      className='hidden'
                      onChange={(event) => {
                        beforeUpload(event.target.files[0], true);
                      }}
                    />
                    <label
                      for='logo_image'
                      style={{
                        backgroundColor: studio_detail?.styles?.header_color,
                      }}
                      className='py-2 px-10 text-xl font-bold text-white cursor-pointer hover:opacity-70'
                    >
                      Browse File
                    </label>
                    <p
                      className='mt-2 text-xs font-normal'
                      style={{ fontSize: 10 }}
                    >
                      Min 300 x 300 px - max 1 MB file size
                    </p>
                  </div>
                </div>
              </Form.Group>
              <div className='mt-5 flex justify-between'>
                <Button
                  onClick={OnCancel}
                  type='outline-secondary'
                  title='Cancel'
                  style={{
                    border: `1px solid ${studio_detail?.styles?.header_color}`,
                    color: studio_detail?.styles?.header_color,
                    borderRadius: 0,
                  }}
                />
                <Button
                  style={{
                    backgroundColor: studio_detail?.styles?.header_color,
                    borderRadius: 0,
                  }}
                  buttonType='submit'
                  type='primary'
                  title='UPDATE'
                />
              </div>
            </Form>
          </div>
        )}
      </>
    );
  }

  return (
    <OrganismLayout title='Profile' maxWidth='42rem'>
      {isLoading ? (
        <AtomLoading />
      ) : (
        <Col className='mx-auto' style={{ maxWidth: "24rem" }}>
          <h1 className='heading1 text-center mb-5'>EDIT PROFILE</h1>
          <Form onSubmit={submitProfile} className='mb-5'>
            <Form.Group>
              <span className='custom-form-label'>Email Address</span>
              <div className='d-grid d-md-flex justify-content-md-between mt-2'>
                <span>{email}</span>
                <u
                  className='greyblue pointer custom-hover'
                  onClick={handleClick}
                >
                  Change
                </u>
              </div>
              <TextInput
                required
                className='mt-4'
                label='First Name'
                placeholder='Input First Name...'
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              <TextInput
                className='mt-4'
                label='Last Name'
                placeholder='Input Last Name...'
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
              {user?.role === "teacher" && (
                <TextInput
                  max={13}
                  type='number'
                  className='mt-4'
                  label='Phone Number'
                  placeholder='Input Phone Number...'
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                />
              )}
            </Form.Group>
            <div className='d-flex justify-content-center gap-4 mt-5'>
              <Button
                onClick={() => history.push("/dashboard")}
                type='outline-secondary'
                title='Cancel'
              />
              <Button buttonType='submit' type='primary' title='UPDATE' />
            </div>
          </Form>
        </Col>
      )}
    </OrganismLayout>
  );
};

export default EditProfile;
