function hexToRGBA(hex, opacity) {
  // Remove the hash (#) if it exists

  hex = hex?.replace(/^#/, "");

  // Parse the hex values for red, green, and blue
  const r = parseInt(hex?.slice(0, 2), 16);
  const g = parseInt(hex?.slice(2, 4), 16);
  const b = parseInt(hex?.slice(4, 6), 16);

  // Ensure that opacity is within the range [0, 1]
  opacity = Math.min(1, Math.max(0, opacity));

  // Create and return the RGBA color
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export default hexToRGBA;
