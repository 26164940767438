import React, { useEffect } from "react";

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../components/Button/button.component";

import {
  updateGeneralTerms,
  setStatusDefault,
} from "../../redux/studio/studio.action";
import { ConfigProvider, Form, Input } from "antd";

export const TermsAndConditions = ({ nextStep, colorTheme }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [cookies] = useCookies(["user"]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { status, isLoading } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const handleSubmit = ({ general_term_title, general_term_description }) => {
    let params = {
      general_term_title,
      general_term_description,
    };

    dispatch(updateGeneralTerms(params, user_token, studio_id));
  };

  useEffect(() => {
    if (status === "success") {
      nextStep();
      dispatch(setStatusDefault());
    }
  }, [status]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full p-4 min-h-screen flex flex-col items-center">
      <h2 className="font-semibold my-10">Set your general terms studio</h2>
      <div className=" text-black text-[20px] font-semibold my-6">
        General Terms
      </div>
      <ConfigProvider
        theme={{
          components: {
            Form: {},
          },
        }}
      >
        <Form
          onFinish={handleSubmit}
          className="md:w-[568px] w-full"
          form={form}
          layout="vertical"
        >
          <Form.Item label="Title" name="general_term_title">
            <Input className="h-10" placeholder="Term & Condition" />
          </Form.Item>
          <Form.Item label="Description" name="general_term_description">
            <Input.TextArea rows={6} placeholder="Type your notes here" />
          </Form.Item>
          <Form.Item>
            <Button
              buttonType="submit"
              type="primary"
              title="CONTINUE"
              style={{ width: "100%", backgroundColor: colorTheme }}
              isLoading={isLoading}
            />
            <Button
              buttonType="button"
              type="secondary"
              title="Skip for now"
              onClick={() => {
                nextStep();
              }}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                color: colorTheme,
              }}
            />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </div>
  );
};
