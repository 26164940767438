import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import logo from "./../assets/images/gigs-logo.svg";
import TextInput from "./TextInput/text-input.component";
import {
  activateAccount,
  requestOtpActivate,
  resendOtp,
  setLoginFromStudio,
  setStatusDefault,
  verifyOtp,
} from "../redux/user/user.action";
import Button from "./Button/button.component";
import { getStudioInfo } from "../services/studios";
import { Space } from "antd";

const FormOTP = ({ isReactivate, subdomain }) => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [remainTime, setRemainTime] = useState(60);
  const [studioDetail, setStudioDetail] = useState({});

  const { status, user, login_email, login_data } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    handleGetStudioInfo();
  }, []);

  useEffect(() => {
    if (status === "success") {
      dispatch(setStatusDefault());
      setRemainTime(60);
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (!remainTime) return;
    const timer = setInterval(() => {
      setRemainTime(remainTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [remainTime]);

  const handleGetStudioInfo = async () => {
    try {
      const { data } = await getStudioInfo(subdomain);

      setStudioDetail(data.data);
    } catch (error) {}
  };

  const resendCode = () => {
    if (isReactivate) {
      dispatch(
        requestOtpActivate(
          {
            email: user?.email ?? login_email,
            role: login_data?.role ?? "student",
          },
          subdomain
        )
      );
    } else {
      if (subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
        dispatch(resendOtp(user?.email ?? login_email, null));
      } else {
        dispatch(resendOtp(user?.email ?? login_email, subdomain));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      email: user?.email ?? login_email,
      password: Number(otp),
    };
    if (isReactivate) {
      dispatch(
        activateAccount(
          {
            email: user?.email ?? login_email,
            role: login_data?.role ?? "student",
            otp: otp,
          },
          subdomain
        )
      );
      dispatch(setLoginFromStudio());
    } else {
      if (subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
        dispatch(verifyOtp(params, null));
      } else {
        dispatch(verifyOtp(params, subdomain));
        dispatch(setLoginFromStudio());
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Space direction="vertical" align="center">
        <img
          alt=""
          src={studioDetail?.studio_logo_link || logo}
          style={{ width: 80 }}
        />

        <span className="fw-bold mt-2 mb-5">
          {studioDetail?.studio_name || "GIGSCLASSROOM"}
        </span>

        <h4
          className="fw-bold text-center"
          style={{ fontFamily: "SourceSans3" }}
        >
          Verify Your Email Address
        </h4>

        <p className="regular-text text-center mb-3mt-3">
          We emailed you 6 (six) digits code to{" "}
          <span
            style={{ color: studioDetail?.studio_header_color || "#000" }}
            className="font-semibold"
          >
            {user?.email ?? login_email}.
          </span>{" "}
          Enter the code below to confirm your email address
        </p>

        <TextInput
          autoFocus
          required
          min={6}
          max={6}
          type="number"
          placeholder="000000"
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
          }}
          style={{
            fontSize: "24px",
            fontFamily: "SourceSans3",
            color: "#000",
            textAlign: "center",
            width: "50%",
            margin: "0 auto",
          }}
          className="mt-3"
        />

        <p className="regular-text text-center mb-3 mt-3 fst-italic">
          The verification code above is only valid for 30 minutes. Please don’t
          share this code with anyone, including those on behalf of GigsClass
        </p>

        <div className="regular-text text-center my-3">
          Didn't receive the code?{" "}
          {!remainTime ? (
            <u
              className="fw-bold"
              style={{
                color: studioDetail?.studio_header_color || "#E26851",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={resendCode}
            >
              Resend Code
            </u>
          ) : (
            <span
              className="fw-bold"
              style={{
                color: studioDetail?.studio_header_color || "#E26851",
              }}
            >
              Resend Code in {remainTime} Second.
            </span>
          )}
        </div>

        <Button
          buttonType="submit"
          type="primary"
          title="VERIFY"
          style={{
            backgroundColor: studioDetail?.studio_header_color || "#E26851",
            borderRadius: 0,
            marginTop: 24,
          }}
        />
      </Space>
    </Form>
  );
};

export default FormOTP;
