export const truncateString = (str, num) => {
  if (str === null) {
    return "";
  } else {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "..";
  }
};

export const capitalize = (str) => {
  if (typeof str !== "string") return ""; // Handle non-string cases
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
