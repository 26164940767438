const BankActionTypes = {
    GET_BANKS: 'GET_BANKS',
    GET_BANKS_SUCCESS: 'GET_BANKS_SUCCESS',
    GET_BANKS_FAILED: 'GET_BANKS_FAILED',

    GET_BANKS_DETAIL: 'GET_BANKS_DETAIL',
    GET_BANKS_DETAIL_SUCCESS: 'GET_BANKS_DETAIL_SUCCESS',
    GET_BANKS_DETAIL_FAILED: 'GET_BANKS_DETAIL_FAILED',

    ADD_BANK: 'ADD_BANK',
    ADD_BANK_SUCCESS: 'ADD_BANK_SUCCESS',
    ADD_BANK_FAILED: 'ADD_BANK_FAILED',  
    
    EDIT_BANK: 'EDIT_BANK',
    EDIT_BANK_SUCCESS: 'EDIT_BANK_SUCCESS',
    EDIT_BANK_FAILED: 'EDIT_BANK_FAILED',  
    
    DELETE_BANK: 'DELETE_BANK',
    DELETE_BANK_SUCCESS: 'DELETE_BANK_SUCCESS',
    DELETE_BANK_FAILED: 'DELETE_BANK_FAILED',  

    SET_STATUS_DEFAULT: 'SET_STATUS_DEFAULT'
}   

export default BankActionTypes;