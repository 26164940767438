import LocationTypes from "./location.types";


const INITIAL_STATE = {
  location: [],
  timezone: [],
};

const LocationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LocationTypes.GET_TIMEZONE:
      return {
        ...state,
        timezone: action.payload,
      };

    case LocationTypes.ADD_LOCATION:
      return {
        ...state,
        location: [...state.location, action.payload],
      };

    case LocationTypes.UPDATE_LOCATION:
      return {
        ...state,
        location: state.location.map((loc) =>
          loc.id === action.payload.id ? action.payload : loc
        ),
      };

    case LocationTypes.DELETE_LOCATION:
      return {
        ...state,
        location: state.location.filter((loc) => loc.id !== action.payload),
      };
    
    case LocationTypes.GET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    
    default:
      return state;
  }
};

export default LocationReducer;
