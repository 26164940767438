import React from "react";
import { WarningFilled } from "@ant-design/icons";

const EmptyData = () => {
  return (
    <div
      // style={{ height: "75px" }}
      className="grid justify-items-center mt-8"
    >
      <WarningFilled className="text-[#D13239] text-lg" />
      <h4>No results.</h4>
      <span>There are no matching results. Try adjusting your filters.</span>
    </div>
  );
};

export default EmptyData;
