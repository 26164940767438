import React, { useEffect } from "react";
import Footer from "./../../components/Footer/footer.component";
import Button from "./../../components/Button/button.component";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { getGeneralTermsPublic } from "../../redux/studio/studio.action";
import AtomLoading from "../../components/loding-indicator";
import logo from "./../../assets/images/gigs-logo.svg";

const GeneralTerms = (props) => {
  const dispatch = useDispatch();
  const subdomain = window.location.host.split(".")[0];

  const { isLoading, general_terms_public } = useSelector(
    (state) => state.studio
  );

  const { studio_detail } = useSelector((state) => state.user);

  const isRegisterStudio = props.location.state?.isRegisterStudio;

  useEffect(() => {
    if (isRegisterStudio) return;
    else dispatch(getGeneralTermsPublic(subdomain));
  }, [isRegisterStudio, dispatch, subdomain]);


  return (
    <Container fluid className="bg-light">
      {isLoading ? (
        <AtomLoading />
      ) : (
        <Row style={{ minHeight: "100vh" }} className="p-5">
          <Col className="p-5">
            <div className="flex items-center gap-4">
              <img alt="" src={studio_detail?.logo_link || logo} width={50} />

              <h1
                style={{ margin: "0 " }}
                className="heading1  fw-bold uppercase"
              >
                {general_terms_public?.title ?? "Terms and Conditions"}
              </h1>
            </div>

            <h1 className="heading1 fw-bold my-3">
              General Terms & Conditions
            </h1>
            {general_terms_public?.description ? (
              <p className="whitespace-pre-wrap">
                {general_terms_public?.description}
              </p>
            ) : (
              <em className="custom-form-label">"Not Available"</em>
            )}

            <Button
              onClick={() => window.close()}
              type="primary"
              title="BACK"
              className="bg-light"
              style={{
                color: studio_detail?.styles.header_color || "#E26851",
                border:
                  `1px solid ${studio_detail?.styles.header_color}` ||
                  "1px solid #E26851",
              }}
            />
          </Col>
        </Row>
      )}
      <Footer />
    </Container>
  );
};

export default GeneralTerms;
