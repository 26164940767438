import React from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import SelectInput from "../../../../components/SelectInput/select-input.component";

const StudentModal = ({
  category,
  title,
  data,
  status,
  setShow,
  currentStatus,
  onChange,
  handleSubmit,
  themeColor,
}) => {
  return (
    <Modal
      centered
      contentClassName="rounded-lg"
      show={data?.visible}
      onHide={() => setShow()}
    >
      <div className="mx-md-5 p-3">
        <div className="text-center">
          <h2 className="font-bold mb-5">{title}</h2>
          <p>Please confirm you want to change this status.</p>
          <div
            style={{ border: "1px solid #E0E0E0" }}
            className=" justify-content-center  mb-5 p-3"
          >
            <Row className="mb-3">
              <Col>
                <span style={{ color: themeColor }}>Class Name</span> <br />
                <span className="text-[#475569]">{data?.name}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span style={{ color: themeColor }}>Invoice</span> <br /> 111
                <span className="text-[#475569]">{data?.invoice}</span>
              </Col>
              <Col>
                <span
                  style={{ color: themeColor }}
                  className=" text-capitalize"
                >
                  Current Status
                </span>{" "}
                <br />
                <span className="text-[#475569]">{currentStatus}</span>
              </Col>
            </Row>
          </div>
          <p>
            <b>To</b>
          </p>
        </div>
        <Form onSubmit={handleSubmit}>
          {currentStatus === "canceled" || currentStatus === "refunded" ? (
            <div className="text-center mb-4">
              <em className="custom-form-label">
                You cannot change the payment status further
              </em>
            </div>
          ) : (data?.category === "edit-plan-status" &&
              currentStatus === "active") ||
            currentStatus === "pending" ? (
            <div className="text-center mb-4">
              <em className="custom-form-label">
                You cannot change the status further
              </em>
            </div>
          ) : (
            <div className="py-4 px-5 mb-5">
              <SelectInput required data={status} onChange={onChange} />
            </div>
          )}
          <div className="flex flex-col gap-3 w-full items-center">
            <Button
              isLoading={
                currentStatus === "canceled" || currentStatus === "refunded"
              }
              buttonType="submit"
              title="Save"
              type="primary"
              style={{ backgroundColor: themeColor, borderRadius: 0 }}
            />
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow()}
              style={{
                color: themeColor,
                border: "none",
                borderRadius: 0,
              }}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default StudentModal;
