import React, { useState } from "react";
import { SwatchesPicker } from "react-color";
import { HiOutlineChevronDown } from "react-icons/hi";
import { Popover } from "antd";
const ColorPicker = ({ backgroundColor, setBackgroundColor }) => {
  const [open, setOpen] = useState(false);
  const color_palette = [
    "880E4F",
    "4a148c",
    "7b1fa2",
    "311b92",
    "512da8",
    "673ab7",
    "1a237e",
    "303f9f",
    "3f51b5",
    "0d47a1",
    "01579b",
    "006064",
    "004d40",
    "194D33",
    "3e2723",
    "5d4037",
    "263238",
    "455a64",
  ];

  const content = (
    <div className="grid grid-cols-4 gap-4">
      {color_palette.map((color) => (
        <div
          style={{
            width: 30,
            height: 30,
            backgroundColor: `#${color}`,
            borderRadius: 12,
          }}
          className=" cursor-pointer"
          onClick={() => {
            setBackgroundColor(`#${color}`);
            setOpen(false);
          }}
        ></div>
      ))}
    </div>
  );

  // create array from data on the top

  return (
    <div>
      <div className="d-flex align-items-center gap-3">
        <span htmlFor="" className="text-sm font-bold">
          Choose Color
        </span>
        <Popover
          open={open}
          onOpenChange={(e) => {
            setOpen(e);
          }}
          trigger={"click"}
          content={content}
          placement="top"
        >
          <div
            style={{
              width: 30,
              height: 30,
              backgroundColor: backgroundColor,
              borderRadius: 12,
            }}
            className="pointer"
          ></div>
        </Popover>

        <HiOutlineChevronDown
          className="pointer"
          onClick={() => setOpen(true)}
        />
      </div>
    </div>
  );
};

export default ColorPicker;
