import React, { useState } from "react";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Link, useHistory } from "react-router-dom";
import { logout, setMenuActive } from "../../redux/user/user.action";
import { useDispatch, useSelector } from "react-redux";

import "./header-student.styles.scss";
import AtomLoading from "../loding-indicator";
import axios from "axios";

const HeaderStudent = ({ title }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, removeCookies] = useCookies(["user", "subdomain"]);

  const { studio_detail, user, loginFromStudio, active_menu } = useSelector(
    (state) => state.user,
  );
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;

  const menu = [
    { name: "Dashboard", href: "/" },
    { name: "Plans", href: "/plan-list" },
    { name: "Classes", href: "/class-list" },
    { name: "Profile", href: "/student-profile" },
  ];

  const handleActive = (params) => {
    dispatch(setMenuActive(params));
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        handleLogout();
      }
      return error;
    },
  );

  const handleLogout = () => {
    if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
    }
    dispatch(logout(user_token));
    history.push("/login");
  };

  return studio_detail ? (
    <Row className='col-3'>
      <Col lg={12} className='p-0 d-flex flex-column'>
        <div
          className='d-flex flex-column flex-shrink-0 p-3 header-owner '
          style={{
            backgroundColor: studio_detail?.styles?.header_color,
            height: "100%",
          }}
        >
          <div className='d-flex flex-row mb-5'>
            <a
              href='/admin/dashboard'
              className='d-flex align-items-center   link-dark text-decoration-none'
            >
              <img
                alt='studio-logo'
                src={
                  studio_detail?.logo_link ??
                  user?.studio_logo ??
                  cookies.user?.studio_logo
                }
                width='60'
                height='60'
                className='rounded-circle '
                style={{ zIndex: 10 }}
              />
            </a>
            <div>
              <b
                className='text-uppercase ps-3'
                style={{ fontSize: "14px", color: "#fff" }}
              >
                {user?.studio_name ?? cookies.user?.studio_name}
              </b>
            </div>
          </div>

          <Nav className='flex-column'>
            {menu.map((el, idx) => (
              <div className='d-flex flex-row align-items-center '>
                <div
                  className={`${
                    el.name === active_menu ? "dot-active" : "dot"
                  } rounded-circle`}
                ></div>
                <Nav.Link
                  onClick={() => handleActive(el.name)}
                  key={idx}
                  as={Link}
                  to={el.href}
                  className={`${el.name === active_menu && "active"} `}
                >
                  {el.name}
                  {/* {el.name === active_menu && (
                    <div
                      className='active-menu'
                      style={{
                        backgroundColor: studio_detail?.styles?.header_color,
                      }}
                    />
                  )} */}
                </Nav.Link>
              </div>
            ))}
          </Nav>
        </div>
        {/* <Navbar
          className='header-owner'
          style={{
            backgroundColor: studio_detail?.styles?.header_color,
          }}
          expand='lg'
        >
          <Container fluid>
            <Navbar.Brand href='/dashboard'>
              <img
                alt='studio-logo'
                src={
                  studio_detail?.logo_link ??
                  user?.studio_logo ??
                  cookies.user?.studio_logo
                }
                width='60'
                height='60'
                className='rounded-circle position-absolute'
                style={{ zIndex: 10, top: 25 }}
              />
              <div style={{ transform: "translateX(60px)" }}>
                <b className='text-uppercase ps-3' style={{ fontSize: "14px" }}>
                  {user?.studio_name ?? cookies.user?.studio_name}
                </b>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse className='text-center' id='basic-navbar-nav'>
              <Nav className='mx-auto'>
                {menu.map((el, idx) => (
                  <Nav.Link
                    onClick={() => handleActive(el.name)}
                    as={Link}
                    key={idx}
                    to={el.href}
                  >
                    {el.name}
                    {el.name === active_menu && (
                      <div
                        className='active-menu'
                        style={{
                          backgroundColor: studio_detail?.styles?.header_color,
                        }}
                      />
                    )}
                  </Nav.Link>
                ))}
              </Nav>
              <Nav.Link className='nav' onClick={(e) => handleLogout(e)}>
                Log Out
              </Nav.Link>
            </Navbar.Collapse>
          </Container>
        </Navbar> */}
        {/* <div
          style={{
            backgroundColor: studio_detail?.styles?.header_color,
            height: 165,
          }}
          className='d-flex justify-content-center p-4 position-relative'
        >
          <h2 className='heading1 m-0' style={{ color: "#fff" }}>
            {title}
          </h2>
          <img
            alt=''
            src={studio_detail?.header_image_link}
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0.2,
            }}
          />
        </div> */}
      </Col>
    </Row>
  ) : (
    <AtomLoading />
  );
};

export default HeaderStudent;
