import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, Image } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import AtomLoading from "../../../../components/loding-indicator";
import sampleImg from "./../../../../assets/images/placeholder.png";
import { useCookies } from "react-cookie";
import { FilterOutlined, CaretDownOutlined } from "@ant-design/icons";
import "react-calendar/dist/Calendar.css";
import {
  getStudentClass,
  getStudentDetail,
  getStudentNotes,
  getStudentOrder,
  getStudentPlan,
  updatePaymentStatus,
  updateStatusAttended,
} from "../../../../redux/owner/owner.action";
import StudentModal from "./student-modal";
import { deactivatePlan } from "../../../../redux/student/student.action";
import axiosInstance from "../../../../axiosInstance";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import ModalDelete from "../../../../components/Modal/delete";
import { saveAs } from "file-saver";
import { resendWelcomeEmail } from "../../../../services/students";
import { setMenuActive } from "../../../../redux/user/user.action";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { ConfigProvider, Input, Popover, Radio, Table, Tag, Space } from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";
import Dot from "../../../../assets/images/3dots.svg";

const tabs = [
  { label: "Class History", value: "class_history" },
  { label: "Order History", value: "order_history" },
  { label: "Plan History", value: "plans_history" },
  { label: "Notes", value: "notes" },
];

const attendedStatus = [
  { label: "Select Status", value: "" },
  { label: "Attended", value: 1 },
  { label: "Unattended", value: 0 },
];

const DetailStudent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [active, setActive] = useState(1);
  const [active1, setActive1] = useState(1);
  const [active2, setActive2] = useState(1);
  const [active3, setActive3] = useState(1);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [status, setStatus] = useState([]);
  const [search, setSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [isAttended, setIsAttended] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isclick, setIsClick] = useState("class_history");

  const {
    student_detail,
    student_class,
    student_plan,
    student_order,
    student_notes,
    isLoadingStudent,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const colorTheme = studio_detail?.styles?.header_color;
  const id = props.location.pathname.split("/")[3];
  const setOrder = props.location.state?.setOrder;
  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const extraParams = `page=${
    isclick === "class_history"
      ? active
      : isclick === "order_history"
      ? active1
      : isclick === "plans_history"
      ? active2
      : isclick === "notes" && active3
  }&${
    isclick === "order_history" ? "payment_status" : "status"
  }=${filterStatus}&search=${search}`;

  useEffect(() => {
    const data_status =
      isclick === "class_history"
        ? [
            { label: "Select Status", value: "", selected: true },
            { label: "Registered", value: "registered", selected: false },
            { label: "Waiting List", value: "waiting list", selected: false },
            { label: "Pending", value: "pending", selected: false },
            {
              label: "Cancel Request",
              value: "cancel request",
              selected: false,
            },
            { label: "Cancelled", value: "cancelled", selected: false },
          ]
        : isclick === "order_history"
        ? [
            {
              label: "Select Status",
              value: "",
              selected: true,
            },
            { label: "Pending", value: "pending", selected: false },
            {
              label: "Payment Verification",
              value: "payment verification",
              selected: false,
            },
            { label: "Settlement", value: "settlement", selected: false },
            {
              label: "Refund Request",
              value: "refund request",
              selected: false,
            },
            { label: "Refunded", value: "refunded", selected: false },
            { label: "Cancelled", value: "cancelled", selected: false },
          ]
        : isclick === "plans_history"
        ? [
            { label: "Select Status", value: "", selected: true },
            { label: "Pending", value: "pending", selected: false },
            { label: "Active", value: "active", selected: false },
            { label: "Deactivated", value: "deactivated", selected: false },
          ]
        : isclick === "notes" && student_notes.data?.length > 0
        ? [
            {
              label: "Added By",
              value: "",
              selected: true,
            },
            ...student_notes?.data.map((el) => ({
              label: el?.created_by,
              value: el?.created_by,
            })),
          ]
        : [
            {
              label: "Added By",
              value: "",
              selected: true,
            },
          ];

    setStatus(data_status);
  }, [isclick]);

  useEffect(() => {
    if (id) {
      setOrderHistory();
      dispatch(getStudentDetail(user_token, studio_id, id));
      dispatch(getStudentClass(user_token, studio_id, id, extraParams));
      dispatch(getStudentOrder(user_token, studio_id, id, extraParams));
      dispatch(getStudentPlan(user_token, studio_id, id, extraParams));
      dispatch(getStudentNotes(user_token, studio_id, id, extraParams));
    }
    dispatch(setMenuActive("Student"));
  }, [id]);

  useEffect(() => {
    const extraParams = `page=${
      isclick === "class_history"
        ? active
        : isclick === "order_history"
        ? active1
        : isclick === "plans_history"
        ? active2
        : isclick === "notes" && active3
    }&${
      isclick === "order_history" ? "payment_status" : "status"
    }=${filterStatus}&search=${search}`;

    isclick === "class_history"
      ? dispatch(getStudentClass(user_token, studio_id, id, extraParams))
      : isclick === "order_history"
      ? dispatch(getStudentOrder(user_token, studio_id, id, extraParams))
      : isclick === "plans_history"
      ? dispatch(getStudentPlan(user_token, studio_id, id, extraParams))
      : isclick === "notes" &&
        dispatch(getStudentNotes(user_token, studio_id, id, extraParams));
  }, [id, active, active1, active2, active3, filterStatus, search, isclick]);

  const mobileSize = useMemo(() => window.screen.width < 576, []);

  const payment_status =
    show6?.payment_status === "pending"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Settlement", value: "settlement", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "payment verification"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Settlement", value: "settlement", selected: false },
          { label: "Refund Request", value: "refund request", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "settlement"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Refund Request", value: "refund request", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "refund request" && [
          { label: "Select Status", value: "", selected: true },
          { label: "Refunded", value: "refunded", selected: false },
        ];

  const setOrderHistory = () => {
    if (setOrder) setIsClick("order_history");
  };

  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const downloadTransferReceipt = (img_url) => {
    try {
      saveAs(img_url, "Transfer Receipt");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleDeleteStudent = async () => {
    try {
      await axiosInstance.delete(`/students/${show8?.id}`, config);

      history.goBack();
      setShow1({ visible: false });
      toast.success("Student account is successfully deleted!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleResendWelcomeEmail = async () => {
    try {
      let params = {
        email: student_detail?.email,
      };

      await resendWelcomeEmail(user_token, studio_id, params);
      toast.success("Email Sent Successfully!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setShow(false);
    }
  };

  const handleDeleteNotes = async () => {
    try {
      await axiosInstance.delete(`/students/notes/${show9?.id}`, config);

      setShow1({ visible: false });
      setShow9({ visible: false });
      dispatch(getStudentNotes(user_token, studio_id, id, extraParams));
      toast.success("Student notes is successfully deleted!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const updateClassAttendance = (e) => {
    e.preventDefault();
    let params = {
      order_id: show4?.id,
      is_attended: isAttended,
    };

    dispatch(updateStatusAttended(user_token, studio_id, params));
    dispatch(getStudentClass(user_token, studio_id, id, extraParams));

    setShow4({ visible: false });
    setShow1(false);
  };

  const cancelClass = (class_order_number) => {
    let params = {
      payment_status: "cancelled",
    };

    dispatch(
      updatePaymentStatus(user_token, studio_id, params, class_order_number)
    );
    dispatch(getStudentClass(user_token, studio_id, id, extraParams));

    setShow1(false);
    setShow5({ visible: false });
  };

  const updateStatusPayment = (e) => {
    try {
      e.preventDefault();
      let params = {
        payment_status: paymentStatus,
      };

      dispatch(updatePaymentStatus(user_token, studio_id, params, show6?.id));
    } catch (error) {
    } finally {
      dispatch(getStudentOrder(user_token, studio_id, id, extraParams));
      setShow6({ visible: false });
      setShow1(false);
    }
  };

  const handleDeactivatePlan = () => {
    let params = {
      message_deactived: "request by owner",
    };

    dispatch(deactivatePlan(user_token, studio_id, params, show7?.id));
    dispatch(getStudentPlan(user_token, studio_id, id, extraParams));

    setShow7({ visible: false });
    setShow1(false);
  };

  const columnsClass = [
    {
      title: "Class Name",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Schedules",
      dataIndex: "class_schedule",
      key: "class_schedule",
    },
    {
      title: "Invoice",
      dataIndex: "class_order_number",
      key: "class_order_number",
    },
    {
      title: "Booking Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        const statusColors = {
          waiting_list: "#F2994A",
          cancelled: "#EF4136",
          settlement: "#0D47A1",
          "refund request": "#FFCD00",
        };

        const color = statusColors[text] || "#27AE60"; // Default color
        return (
          <Tag color={color} className="capitalize">
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Attended Status",
      dataIndex: "is_attended",
      key: "is_attended",
      render: (text) => {
        const statusColors = {
          "wainting list": "#0296CF",
          pending: "#F2994A",
          cancelled: "#EF4136",
          unattended: "#EF4136",
          settlement: "#0D47A1",
          "refund request": "#FFCD00",
        };

        const color = statusColors[text.toLowerCase()] || "#27AE60"; // Default color
        return (
          <Tag color={color} className="capitalize">
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Popover
            placement="bottomRight"
            content={() => (
              <div className="grid">
                <span
                  onClick={() =>
                    setShow4({
                      id: record?.class_transactions_id,
                      visible: true,
                      name: record?.name,
                      attented_status: record?.is_attended,
                    })
                  }
                  className="cursor-pointer py-2 px-4"
                >
                  Update Attended Status
                </span>

                <span
                  onClick={() =>
                    setShow5({
                      id: record?.class_id,
                      visible: true,
                      name: record?.name,
                    })
                  }
                  className="cursor-pointer py-2 px-4 text-[#EB5757]"
                >
                  Cancel Class
                </span>
              </div>
            )}
            className="cursor-pointer"
          >
            <img alt="" className="w-6 h-6" src={Dot} />
          </Popover>
        );
      },
    },
  ];

  const columnsOrders = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
      key: "invoice",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
    },
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text) => {
        const statusColors = {
          pending: "#BDBDBD",
          cancelled: "#EF4136",
          settlement: "#0D47A1",
          "refund request": "#FFCD00",
        };

        const color = statusColors[text] || "#E26851"; // Default color for other statuses

        return (
          <Tag color={color} className="capitalize">
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Popover
            placement="bottomRight"
            content={() => (
              <span
                onClick={() =>
                  setShow6({
                    id: record?.order_number,
                    visible: true,
                    name: record?.name,
                    invoice: record?.order_number.split("-")[0],
                    payment_status: record?.payment_status,
                  })
                }
                className="cursor-pointer py-2 px-4"
              >
                Update Payment Status
              </span>
            )}
            className="cursor-pointer"
          >
            <img alt="" className="w-6 h-6" src={Dot} />
          </Popover>
        );
      },
    },
  ];

  const columnsPlans = [
    {
      title: "Plan Name",
      dataIndex: "planName",
      key: "planName",
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
      key: "invoice",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Amount of Usage",
      dataIndex: "amountOfUsage",
      key: "amountOfUsage",
    },
    {
      title: "Expired Date",
      dataIndex: "expiredDate",
      key: "expiredDate",
    },
    {
      title: "Plan Status",
      dataIndex: "planStatus",
      key: "planStatus",
      render: (text) => (
        <Tag
          color={text === "active" ? "#27AE60" : "#EF4136"}
          className="capitalize"
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Popover
            placement="bottomRight"
            content={() => (
              <span
                onClick={() =>
                  setShow7({
                    category: "edit-plan-status",
                    id: record?.plan_id,
                    visible: true,
                    name: record?.plan_name,
                    invoice: record?.plan_order_number.split("-")[0],
                    plan_status: record?.status,
                  })
                }
                className=" cursor-pointer py-2 px-4"
              >
                Update Plan Status
              </span>
            )}
            className="cursor-pointer"
          >
            <img alt="" className="w-6 h-6" src={Dot} />
          </Popover>
        );
      },
    },
  ];

  const columnsNotes = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text) => {
        return (
          <div className="cursor-pointer">
            <img alt="" className="w-6 h-6" src={Dot} />
          </div>
        );
      },
    },
  ];

  return (
    <OrganismLayout fluid style={{ height: "100%" }} className="bg-light">
      <ConfigProvider
        theme={{
          components: {
            Radio: {
              buttonSolidCheckedBg: colorTheme,
              buttonSolidCheckedActiveBg: colorTheme,
              buttonColor: "#BDBDBD",
              buttonSolidCheckedHoverBg: colorTheme,
              colorBgTextHover: colorTheme,
            },
            Table: {
              headerBorderRadius: 0,
              headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
            },
          },
        }}
      >
        <div className="flex gap-4">
          <div>
            <img
              className="h-20 w-20 rounded-full"
              src={student_detail?.profile_picture}
              alt="logo"
            />
          </div>
          <div className="flex flex-col gap-1">
            <span className="font-bold text-[2rem] flex ">
              {student_detail?.first_name} {student_detail?.last_name}
              <Popover
                placement="bottom"
                content={() => (
                  <Space direction="vertical">
                    <span
                      onClick={() => {
                        history.push({
                          pathname: `/admin/student/${student_detail?.id}/edit`,
                          state: { id: student_detail?.id },
                        });
                      }}
                      className="font-semibold cursor-pointer"
                    >
                      Edit Profile
                    </span>
                    <span
                      onClick={() => handleResendWelcomeEmail()}
                      className="font-semibold cursor-pointer"
                    >
                      Resend Welcome Email
                    </span>
                    <span
                      onClick={() => {
                        setShow(false);
                        setShow8({
                          id: student_detail?.id,
                          visible: true,
                          name: student_detail?.first_name,
                          last_name: student_detail?.last_name,
                          email: student_detail?.email,
                        });
                      }}
                      className="font-semibold cursor-pointer text-[#EB5757]"
                    >
                      Delete
                    </span>
                  </Space>
                )}
              >
                <CaretDownOutlined style={{ fontSize: 18 }} />
              </Popover>
            </span>
            <b className="capitalize text-[#BDBDBD]">
              {student_detail?.gender}
            </b>
            <span>{student_detail?.email}</span>
            <span>{student_detail?.phone_number}</span>
          </div>
        </div>

        <div className="flex justify-between mt-5 flex-col md:flex-row gap-2">
          <div>
            <Radio.Group
              value={isclick}
              size="large"
              options={tabs}
              optionType="button"
              buttonStyle="solid"
              onChange={(e) => {
                setIsClick(e.target.value);
              }}
            />
          </div>
          <div className="flex  md:items-center md:justify-center flex-col md:flex-row gap-2">
            <Input
              placeholder={`${
                isclick === "class_history"
                  ? " Enter a class name Notes"
                  : isclick === "order_history"
                  ? "Enter a class name, a plan name, invoice number.."
                  : isclick === "plans_history"
                  ? "Enter a plan name or invoice.."
                  : "Enter a title or description.."
              } `}
              size="large"
              className="w-full md:w-[340px]"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div
              style={{
                padding: "4px 8px",
                cursor: "pointer",
                border: `1px solid ${colorTheme}`,
              }}
            >
              <FilterOutlined size={24} style={{ color: colorTheme }} />
            </div>
            {isclick === "class_history" || isclick === "plans_history" ? (
              <Button
                className="w-auto py-[10px] px-[40px] "
                type="primary"
                title={
                  isclick === "class_history" ? "Book A Class" : "Buy Plan"
                }
                style={{
                  backgroundColor: colorTheme,
                  color: "white",
                  borderRadius: 0,
                }}
                onClick={() => {
                  history.push({
                    pathname:
                      isclick === "class_history"
                        ? "/admin/class-list"
                        : "/admin/buy-plan",
                    state: { student_id: id, fromOwner: true },
                  });
                }}
              />
            ) : null}
            {isclick === "notes" ? (
              <Button
                onClick={() =>
                  history.push({
                    pathname: "/admin/add/notes",
                    state: { id: id },
                  })
                }
                className="w-auto py-[10px] px-[40px] "
                title="Add Notes"
                type="primary"
                style={{
                  backgroundColor: colorTheme,
                  color: "white",
                  borderRadius: 0,
                }}
              />
            ) : null}
          </div>
        </div>

        <div
          style={{
            minWidth: mobileSize ? "auto" : "56rem",
            border: `1px solid ${hexToRGBA(colorTheme, 0.3)}`,
          }}
          className="my-5"
        >
          {isLoadingStudent ? (
            <AtomLoading />
          ) : (
            <Table
              columns={
                isclick === "class_history"
                  ? columnsClass
                  : isclick === "order_history"
                  ? columnsOrders
                  : isclick === "plans_history"
                  ? columnsPlans
                  : isclick === "notes"
                  ? columnsNotes
                  : null
              }
              dataSource={
                isclick === "class_history"
                  ? student_class?.data?.map((el, idx) => {
                      return {
                        ...el,
                        key: el?.class_id,
                        class_name: el?.class_name,
                        class_schedule: el?.class_schedule,
                        class_order_number: el?.class_order_number,
                        status: el?.status,
                        is_attended: el.is_attended,
                      };
                    })
                  : isclick === "order_history"
                  ? student_order?.data?.map((el, idx) => {
                      return {
                        ...el,
                        key: el?.id,
                        totalPrice: el?.total_amount,
                        invoice: el?.order_number.split("-")[0],
                        transactionDate: el?.created_at,
                        payment: el?.payment_type,
                        bank: el?.bank_name,
                        paymentStatus: el?.payment_status,
                      };
                    })
                  : isclick === "plans_history"
                  ? student_plan?.data?.map((el, idx) => {
                      return {
                        ...el,
                        key: el?.id,
                        invoice: el?.plan_order_number.split("-")[0],
                        planName: el?.plan_name,
                        category: el?.plan_type,
                        transactionDate: el?.plan_transaction_date,
                        expiredDate: el?.end_valid_date,
                        amountOfUsage: el?.amount_of_usage,
                        planStatus: el?.status,
                      };
                    })
                  : isclick === "notes"
                  ? student_notes?.data?.map((el, idx) => {
                      return {
                        ...el,
                        key: el?.id,
                        createdDate: el?.created_at,
                        className: el?.class_name,
                        addedBy: el?.created_by,
                      };
                    })
                  : null
              }
            />
          )}
        </div>
      </ConfigProvider>

      <Modal
        show={show2?.visible}
        onHide={() => setShow2({ visible: false })}
        centered
        contentClassName="rounded-lg"
      >
        <div className="py-5 mx-auto">
          <h1 className="heading1 text-center mb-3">Bank Transfer Receipt</h1>
          <div className="b-dashed bg-babyblue p-5 mb-4 d-flex flex-column align-items-center">
            <Image
              src={show2?.transfer_receipt ?? sampleImg}
              width={200}
              rounded
            />
            <div className="d-flex align-items-center mt-4">
              <Button
                type="outline-secondary"
                title="Download"
                onClick={() => {
                  downloadTransferReceipt(show2?.transfer_receipt);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              type="primary"
              title="Back"
              onClick={() => {
                setShow2({ vsible: false });
              }}
            />
          </div>
        </div>
      </Modal>

      <StudentModal
        title="Edit Booking Status"
        data={show3}
        setShow={() => setShow3({ visible: false })}
        status={status}
        currentStatus={show3?.booking_status}
        themeColor={colorTheme}
      />

      <StudentModal
        title="Edit Attended Status"
        data={show4}
        setShow={() => setShow4({ visible: false })}
        status={attendedStatus}
        currentStatus={show4?.attended_status}
        onChange={(e) => setIsAttended(e.target.value)}
        handleSubmit={updateClassAttendance}
        themeColor={colorTheme}
      />

      <StudentModal
        title="Edit Payment Status"
        data={show6}
        setShow={() => setShow6({ visible: false })}
        status={payment_status}
        currentStatus={show6?.payment_status}
        onChange={(e) => setPaymentStatus(e.target.value)}
        handleSubmit={updateStatusPayment}
        themeColor={colorTheme}
      />

      <StudentModal
        title="Edit Plan Status"
        data={show7}
        setShow={() => setShow7({ visible: false })}
        status={status}
        currentStatus={show7?.plan_status}
        handleSubmit={handleDeactivatePlan}
        themeColor={colorTheme}
      />

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show5?.visible}
        onHide={() => setShow1({ visible: false })}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h2 className="heading1 m-0 mb-3">Cancel Class</h2>
          <p>Cancel this Classs?</p>
          <div className="b-gray mx-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b className="">{show5.name}</b>
              <br />
              <span className="heading3">
                for {student_detail?.first_name} {student_detail?.last_name}
              </span>
            </p>
          </div>
          <span className="mb-3 text-danger fs-12">
            You need to return the money manually to your customer
          </span>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow5({ visible: false })}
              style={{
                color: colorTheme,
                border: "none",
                borderRadius: 0,
              }}
            />
            <Button
              title="Submit"
              type="primary"
              onClick={() => cancelClass(show5?.id)}
              style={{ backgroundColor: colorTheme, borderRadius: 0 }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show8?.visible}
        onHide={() => setShow1({ visible: false })}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h2 className="heading1 m-0 mb-3">Delete Student</h2>
          <p>Delete this Student?</p>
          <div className="b-gray mx-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b className="">
                {show8?.name} {show8?.last_name}
              </b>
              <br />
              <span className="heading3">{show8?.email}</span>
            </p>
          </div>
          <div className="text-start p-3 bg-pink b-gray rounded mb-3">
            <b>
              When you delete this account, his Profile, orders, payment
              transactions, plan, and registered classes will be permanently
              removed.
            </b>
          </div>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow8({ visible: false })}
            />
            <Button
              title="DELETE"
              type="primary"
              onClick={() => handleDeleteStudent()}
            />
          </div>
        </div>
      </Modal>

      <ModalDelete
        show={show9}
        setShow={setShow9}
        subject="Notes"
        messages="Student note will be dissapear after deleting"
        handleDelete={handleDeleteNotes}
      />
    </OrganismLayout>
  );
};

export default DetailStudent;
