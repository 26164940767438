import React from "react";
import { Typography } from "antd";

const { Title, Text, Paragraph } = Typography;

const privacyPoints = [
  "Personal data processed by Gigsclass",
  "Sharing and disclosing of information",
  "Use of cookies",
  "Protection of personal data",
  "Access and management of personal data",
  "Changes to this Privacy Statement",
];

const studioDataPoints = [
  "To assess your registration;",
  "To process payments;",
  "For sending Gigsclass information;",
  "For sending email notifications about activities in the studio;",
  "For analyzes and internal statistical purposes;",
  "To meet obligations imposed by payment providers;",
  "For creating the studio owner and teacher environments;",
  "For providing secure access to these environments;",
  "For creating a public URL to the online timetable.",
];

const teacherDataPoints = [
  "For sending Gigsclass information (only with permission);",
  "For analyzes and internal statistical purposes;",
  "For creating your own teacher profile;",
  "For providing secure access to this profile;",
  "For sending email notifications about activities in the studio.",
];

const studentDataPoints = [
  "To create a student profile within the Gigsclass environment;",
  "For registering at one or more studios;",
  "For analyzes for internal statistical purposes;",
  "For providing secure access to your student profile;",
  "For forwarding email notifications from the yoga studio.",
];

const anonymousDataPoints = [
  "Get a better picture of how our software is used;",
  "Learn about global trends;",
  "Improve the software.",
];

const PrivacyStatement = () => (
  <Typography className="m-8">
    <Title level={5}>Privacy Statement</Title>
    <Paragraph>
      <ul>
        {privacyPoints.map((point, index) => (
          <li key={index}>
            <a href={`#${point}`}>{point}</a>
          </li>
        ))}
      </ul>
    </Paragraph>
    <Paragraph>
      Gigsclass considers your privacy important and wants to handle your
      personal data in a safe way. This Privacy Statement explains what personal
      information we collect and how we use and protect it.
    </Paragraph>
    <Paragraph>
      Gigsclass makes software for organizing a studio. The members of the
      studio have the possibility to register and deregister for classes, and to
      pay for classes. Gigsclass collects data from the studio and the members.
      This data is necessary for the software to work.
    </Paragraph>
    <Title level={5} id="Personal data processed by Gigsclass">
      Personal data processed by Gigsclass
    </Title>
    <Paragraph>
      Gigsclass only processes personal data insofar as it is necessary for the
      correct operation of its software. Personal data will only be processed
      for the following purposes:
    </Paragraph>
    <Text>Studio data</Text>
    <Paragraph>
      <ul>
        {studioDataPoints.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </Paragraph>

    <Text>Teacher data</Text>
    <Paragraph>
      If you want to use Gigsclass as a teacher, your data will be used:
      <ul>
        {teacherDataPoints.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </Paragraph>

    <Text>Student data</Text>
    <Paragraph>
      If you want to use Gigsclass as a student, your data will be used:
      <ul>
        {studentDataPoints.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </Paragraph>

    <Paragraph>
      We think it is important that Gigsclass listens to the wishes of all
      studios, teachers and students. We therefore constantly work on improving
      the software. To this end, in addition to personal data, we also store
      anonymous data about the users of our software. This information cannot be
      traced back to the personal level, and helps us to:
      <ul>
        {anonymousDataPoints.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </Paragraph>

    <Title level={5} id="Sharing and disclosing of information">
      Sharing and disclosing information
    </Title>
    <Paragraph>
      Gigsclass will only share studio data if this is necessary for the
      execution of the agreement, and only with permission.
      <br />
      <br />
      Gigsclass will never sell personal details of studios, teachers or
      students. Gigsclass only shares information with third parties with prior
      permission, unless bound by law. Gigsclass may provide (some) data to
      third parties if this is necessary for the execution of services, for
      example data exchange with a payment provider or email program.
      <br />
      <br />
      Gigsclass reserves the right to transfer (parts of) data in the event of a
      transfer of ownership, for example through a merger or takeover. If
      information is provided to the acquiring company, Gigsclass will endeavor
      to inform the studios of this, for example by email or by a message on the
      Gigsclass website.
    </Paragraph>

    <Title level={5} id="Use of cookies">
      Use of cookies
    </Title>
    <Paragraph>
      A cookie is a small piece of data that is sent to your browser by a
      website you visit. Cookies help Gigsclass remember information about your
      visit such as a preferred language and offer the possibility to store
      login data so that you do not have to enter it every time.
    </Paragraph>
    <Paragraph>
      Gigsclass also uses cookies to analyze how its website is used. We use
      Google Analytics for this. This way we know how many visitors our website
      has and which are the landing pages. This way, we can analyze, for
      example, how effective our marketing campaigns have been. This data
      contains no personal data and cannot be traced back to individuals.
    </Paragraph>
    <Paragraph>
      The use of cookies is completely safe. No personal information, such as a
      telephone number or an email address, can be retrieved from cookies.
      Cookies cannot be extracted and used, for instance, for email or
      telemarketing campaigns.
    </Paragraph>

    <Title level={5} id="Protection of personal data">
      Protection of personal data
    </Title>
    <Paragraph>
      Gigsclass takes the protection of your personal data very seriously, and
      we take all technical and organizational measures to prevent misuse, loss,
      disclosure, unsolicited modification, or access to your data.
    </Paragraph>
    <Paragraph>
      We ask studios, teachers, and students to take responsibility for their
      own personal data and the security of this data.
    </Paragraph>

    <Title level={5} id="Access and management of personal data">
      Access and management of personal data
    </Title>
    <Paragraph>
      You have the right to view your personal data processed by Gigsclass. You
      can correct and delete it unless Gigsclass cannot grant you access due to
      legal obligations.
    </Paragraph>

    <Text>Studio data</Text>
    <Paragraph>
      Gigsclass stores data from the studio in an encrypted database. After
      terminating an account with Gigsclass, you have the right to request and
      view your data. It is also possible to continue your account with
      Gigsclass on re-registration based on your old data. Upon request,
      Gigsclass can permanently delete the data, so that it can no longer be
      retrieved.
    </Paragraph>

    <Text>Teacher data</Text>
    <Paragraph>
      Gigsclass concludes a processor agreement with each studio. This agreement
      stipulates that the studio is the processor of the teacher data. With a
      name and email address, a studio can create a profile for a teacher. At
      the request of a teacher, the studio is obliged to remove a teacher's
      profile. Because Gigsclass stores email addresses from users, the teacher
      must, along with this request, also submit a request to delete the email
      address. The email address is encrypted in the Gigsclass database and can
      never be used for other purposes.
    </Paragraph>

    <Text>Student data</Text>
    <Paragraph>
      Gigsclass concludes a processor agreement with each studio. This agreement
      stipulates that the studio is the processor of the student data. A studio
      can create a profile for a student based on a name and email address. A
      student can create a profile in Gigsclass, and then register with one or
      more studios. If a student profile already exists, a studio cannot
      independently assign this profile to itself. This is an act reserved for
      the student. Only with permission can a profile be created by the studio.
      A student can request to remove his profile from a studio. The Gigsclass
      profile will remain accessible to the student for personal use. Gigsclass
      can remove the profile completely on request.
    </Paragraph>

    <Title level={5} id="Changes to this Privacy Statement">
      Changes to this Privacy Statement
    </Title>
    <Paragraph>
      If Gigsclass changes its Privacy Statement, the changes will immediately
      be included in this statement. We advise you to regularly read this
      Privacy Statement, so that you are aware of changes.
    </Paragraph>
    <Paragraph>
      Do you have questions or requests regarding the Gigsclass Privacy Policy?
      Please let us know by sending a message to{" "}
      <a href="mailto:help@gojago.com">help@gojago.com</a>.
    </Paragraph>
    <i>Last update: June 18, 2024</i>
  </Typography>
);

export default PrivacyStatement;
