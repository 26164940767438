import React from "react";

import { useHistory } from "react-router-dom";

import { Col } from "react-bootstrap";

import thankyouImg from "../../../../assets/images/thankyou-page.png";
import "../students.styles.scss";
import { useSelector } from "react-redux";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const MidtransCallbackSucces = () => {
  const history = useHistory();

  const { order_resp } = useSelector((state) => state.student);

  return (
    <OrganismLayout title="Payment Successful!" maxWidth="42rem">
      <Col className="text-center p-3">
        <img src={thankyouImg} alt="Thank You" width={300} />
        <p>Thank You for Your Payment</p>
        {/* <h1 className="heading1 text-center mb-5">THANK YOU!</h1>
            <h3 className="text-center mb-3">
              You have registered for this{" "}
              {order_resp?.category === "class" ? "class" : "plan"}.
            </h3>
            {order_resp?.category === "class" ? (
              <div className="b-gray rounded-lg p-3 mb-3">
                <Row className="fs-12 mb-3">
                  <Col>
                    <HiOutlineCalendar className="text-pink fs-5" />{" "}
                    {order_resp?.is_online === 1 ? "Online" : "Offline"}
                  </Col>
                  <Col sm={4}>
                    <HiClock className="text-pink fs-5" />{" "}
                    {order_resp.class.classes_schedule?.class_date}, {" "} 
                    {order_resp.class.classes_schedule?.class_start_time} -{" "}
                    {order_resp.class.classes_schedule?.class_end_time}
                  </Col>
                  <Col sm={4}>
                    <HiUser className="text-pink fs-5" />{" "}
                    {order_resp?.teachers.join(",")}
                  </Col>
                  <Col></Col>
                </Row>
                <span>
                  <b>{order_resp?.name}</b>
                </span>
              </div>
            ) : (
              <div className="b-gray rounded-lg p-3 mb-3">
                <p className="heading3">
                  <HiClock className="text-pink fs-5" /> Valid until 21 Dec 2022
                  24.00
                </p>
                <Row>
                  <Col className="fw-bold">
                    Unlimited Forever Plans{" "}
                    <span className="heading3 ms-3">15 Credits</span>
                  </Col>
                </Row>
              </div>
            )} */}
        <div className="d-flex justify-content-center mt-5">
          <button
            onClick={() => history.push("/students")}
            className="bg-greyblue text-white border-0 fw-bold rounded-3 custom-hover py-2 px-4"
          >
            BACK
          </button>
        </div>
      </Col>
    </OrganismLayout>
  );
};

export default MidtransCallbackSucces;
