import React, {useState} from "react";
import {useJsApiLoader, Autocomplete} from "@react-google-maps/api";

import {CloseCircleOutlined, SearchOutlined} from "@ant-design/icons";
import InputText from "./TextInput/input-text";

const AutoCompleteMaps = ({
                              formattedAddress,
                              setFormattedAddress,
                              setLatLong,
                              required
                          }) => {
    const [libraries] = useState(["places"]);
    const {isLoaded, loadError} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });


    const [autocomplete, setAutocomplete] = useState(null);

    // Geocode
    const loadAutocomplete = (e) => {
        setAutocomplete(e);
    };

    const placeChanged = () => {
        if (autocomplete !== null) {
            const address = autocomplete.getPlace().formatted_address;
            const lat = autocomplete.getPlace().geometry.location.lat();
            const lng = autocomplete.getPlace().geometry.location.lng();

            setFormattedAddress(address);
            setLatLong((prev) => ({
                ...prev,
                lat,
                lng,
            }));
        } else {
            console.log("Autocomplete is not loaded yet!");
        }
    };


    return isLoaded ? (
        <>
            {formattedAddress ? (
                <InputText
                    required={required ?? true}
                    readOnly
                    name="address"
                    value={formattedAddress}
                    placeholder={formattedAddress}
                    suffix={
                        <CloseCircleOutlined
                            className="pointer custom-hover"
                            onClick={() => {
                                setLatLong((prev) => ({
                                    ...prev,
                                    lat: 56.951474436830196,
                                    lng: 24.11326717154613,
                                }));
                                setFormattedAddress(null);
                            }}
                        />
                    }
                />
            ) : (
                <Autocomplete onLoad={loadAutocomplete} onPlaceChanged={placeChanged}>
                    <InputText
                        required={required ?? true}
                        placeholder={"Search Location"}
                        message="location"
                        suffix={<SearchOutlined/>}
                    />
                </Autocomplete>
            )}
        </>
    ) : null;
};

export default AutoCompleteMaps;
