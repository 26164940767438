import React, { useEffect, useState } from "react";

import "./register.styles.scss";

import logo from "./../../assets/images/gigs-logo.svg";
import Dots from "../../assets/images/dots.svg";
import CreateStudio from "./create-studio.component";
import VerifyOtpRegister from "./verify-otp.component";
import StudioSetup from "./studio-setup.component";
import CreatingStudio from "./creating-studio.component";

const Register = (props) => {
  const isStudioEmpty = props.location.state?.studio_empty;
  const firstName = props.location.state?.firstName;
  const lastName = props.location.state?.lastName;
  const emailAddress = props.location.state?.email;

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <CreateStudio
            nextStep={nextStep}
            setStep={setStep}
            emailAddress={emailAddress}
            givenName={firstName}
            familyName={lastName}
          />
        );
      case 2:
        return <VerifyOtpRegister nextStep={nextStep} />;
      case 3:
        return <StudioSetup nextStep={nextStep} />;
      case 4:
        return <CreatingStudio />;
      default:
    }
  };

  useEffect(() => {
    if (isStudioEmpty) setStep(3);
  }, [isStudioEmpty]);

  return (
    <div>
      <div className="md:flex min-h-screen">
        {step === 1 && (
          <div className="login-left-image md:w-3/5">
            <img
              src={Dots}
              className="w-40 absolute top-[230px] -left-[100px] z-0 md:top-[75%]"
              alt="dots"
            />
            <img
              src={Dots}
              className="w-40 absolute top-0 right-0 md:right-[40%] z-0 "
              alt="dots"
            />
            <div className="text-white text-title w-full flex justify-between">
              Gigs
            </div>
            <div
              style={{ height: "100%" }}
              className="d-flex flex-column justify-content-center align-items-center flex-wrap text-white"
            >
              <img src={logo} alt="logo" className="w-20 md:w-52 z-10" />
              <h4 className="mt-4 text-center">WELCOME TO GIGSCLASS</h4>
              <div className="md:w-96 text-center text-[#BAC2DB] flex">
                <div>
                  <p>
                    One app fit for the entire studios. Making learn fun and
                    exciting
                  </p>
                  <p>Flexible learning inspired by the pandemic</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`${step === 1 && "md:w-2/5"} w-full ${
            step !== 1 && "min-h-screen"
          }`}
          // style={{ minHeight: "100vh", marginTop: 6 }}
        >
          {renderSteps()}
        </div>
      </div>
      {/* {step === 4 ? (
        <Header title='Building Your Studio . . .' />
      ) : (
        <Header
          isBrowseClasses={step === 1}
          mainPage
          title='Start Creating Your Own Studio'
        />
      )} */}

      {/* <Footer /> */}
    </div>
  );
};

export default Register;
