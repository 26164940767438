import React from "react";
import "./button.styles.scss";

const Button = ({
  title,
  onClick,
  type,
  style,
  className,
  icon,
  isLoading,
  buttonType,
  ...props
}) => {
  return (
    <button
      {...props}
      type={buttonType !== "submit" ? "button" : "submit"}
      disabled={isLoading}
      onClick={onClick}
      className={`${
        isLoading && "bg-secondary text-white disabled"
      } button ${className} ${type}`}
      style={style}
    >
      {icon && !isLoading ? (
        <img
          alt=""
          src={icon}
          style={{ height: "25px", width: "25px", marginRight: "10px" }}
        />
      ) : null}
      {title}
    </button>
  );
};

export default Button;
