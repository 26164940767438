import React, { useEffect } from "react";

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { HiClock, HiUser } from "react-icons/hi";
import Button from "../../../../components/Button/button.component";
import "./../students.styles.scss";

import AtomNumberFormat from "../../../../components/number-format";

import {
  directBuyManual,
  setStatusDefault,
} from "./../../../../redux/student/student.action";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const ConfirmClass = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { order_status, class_detail, active_plan } = useSelector(
    (state) => state.student
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const fromOwner = props.location.state?.fromOwner;
  const student_id = props.location.state?.student_id;
  const selectedPlan = props.location.state?.selected_plan;
  const classDetail = class_detail[0]?.class_schedules[0];

  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const handleSubmit = () => {
    const params = fromOwner
      ? {
          category: "class",
          class_schedule_id: classDetail?.class_schedule_id,
          plan_order_id: selectedPlan.split(",")[1],
          payment_method: "active plan",
          payment_type: "active plan",
          student_id: student_id,
        }
      : {
          category: "class",
          class_schedule_id: classDetail?.class_schedule_id,
          plan_order_id: selectedPlan.split(",")[1],
          payment_method: "active plan",
          payment_type: "active plan",
        };

    dispatch(directBuyManual(user_token, studio_id, params));
  };

  useEffect(() => {
    if (order_status === "success") {
      history.push("/online-payment-success");
      dispatch(setStatusDefault());
    }
  }, [dispatch, history, order_status]);

  return (
    <OrganismLayout maxWidth="42rem">
      <Col className="mx-auto p-md-3" style={{ maxWidth: "32rem" }}>
        <div className="text-center">
          <h1 className="heading1 mb-5">Confirm Class</h1>
        </div>
        <div className="mb-3 b-gray rounded-10 p-3">
          <div className="fs-12 d-flex justify-content-between mb-2">
            <span>{classDetail?.is_online === 1 ? "Online" : "Offline"}</span>
            <span>
              <HiClock className="fs-5 text-pink" />{" "}
              {classDetail?.class_start_time} - {classDetail?.class_end_time}
            </span>
            <span>
              <HiUser className="fs-5 text-pink" />{" "}
              {classDetail?.teachers.join(",")}
            </span>
            <span></span>
          </div>
          <span className="fw-bold">{classDetail?.class_name}</span>
          <hr />
          <Row>
            <Col className="fw-bold">
              <p>Schedule</p>
            </Col>
            <Col className="fs-12" xs={9}>
              {class_detail[0]?.class_date}, {classDetail?.class_start_time} -{" "}
              {classDetail?.class_end_time}
            </Col>
          </Row>
          <Row>
            <Col className="fw-bold">
              <p>Slots</p>
            </Col>
            <Col className="fs-12" xs={9}>
              {classDetail?.slots} Remaining
            </Col>
          </Row>
          <Row>
            <Col className="fw-bold">
              <p>Credits</p>
            </Col>
            <Col className="fs-12" xs={9}>
              {classDetail?.credits}
            </Col>
          </Row>
          <Row>
            <Col className="fw-bold">
              <p>Price</p>
            </Col>
            <Col className="fs-12" xs={9}>
              <AtomNumberFormat
                prefix="IDR "
                value={classDetail?.total_amount}
              />
            </Col>
          </Row>
          <Row>
            <Col className="fw-bold">
              <p>Notes</p>
            </Col>
            <Col className="fs-12" xs={9}>
              {classDetail?.notes}
            </Col>
          </Row>
        </div>
        <div className="mb-3 b-gray rounded-10 p-3">
          <span>Payment Method</span> <br />
          <p>
            <b>{selectedPlan.split(",")[0]}</b>
          </p>
          {active_plan &&
            active_plan
              .filter((el) => el?._id === selectedPlan.split(",")[1])
              .map((item, idx) => (
                <div key={idx}>
                  <Row>
                    <Col>
                      <b>Valid Until</b>
                    </Col>
                    <Col>{item.plan?.end_valid_date}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Credit/Usage Limit</b>
                    </Col>
                    <Col>
                      {item?.current_credits ?? item?.current_usage_limit}
                    </Col>
                  </Row>
                </div>
              ))}
        </div>
        <div className="d-flex justify-content-center gap-4">
          <Button
            onClick={() => history.goBack()}
            type="outline-secondary"
            title="Cancel"
          />
          <Button onClick={handleSubmit} type="primary" title="CONFIRM" />
        </div>
      </Col>
    </OrganismLayout>
  );
};

export default ConfirmClass;
