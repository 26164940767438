import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import "./../students.styles.scss";
import Footer from "./../../../../components/Footer/footer.component";
import Header from "./../../../../components/HeaderStudent/header-student.component";
import Button from "./../../../../components/Button/button.component";
import TextInput from "../../../../components/TextInput/text-input.component";

import { HiClock, HiUser } from "react-icons/hi";
import { useCookies } from "react-cookie";
import {
  cancelClass,
  setStatusDefault,
} from "../../../../redux/student/student.action";
import { getCancellationSetting } from "../../../../redux/studio/studio.action";

const PaymentMethod = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);

  const [notes, setNotes] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [show, setShow] = useState(false);

  const { cancellation_setting, isRegisterStudent } = useSelector(
    (state) => state.studio,
  );
  const { cancel_class_status } = useSelector((state) => state.student);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const state = props.location?.state;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      cancellation_reason: notes,
    };

    dispatch(cancelClass(user_token, studio_id, params, state.order_id));
  };

  useEffect(() => {
    dispatch(getCancellationSetting(user_token, studio_id));
  }, []);

  useEffect(() => {
    if (cancel_class_status === "success") {
      history.push("/cancel-class-complete");
    }
    dispatch(setStatusDefault());
  }, [cancel_class_status]);

  return (
    <Container fluid style={{ height: "100%" }} className='bg-light'>
      <Header />
      <Row
        style={{ maxWidth: "44rem", transform: "translateY(-77px)" }}
        className='mx-auto justify-content-center shadow-sm p-3 mb-5 bg-body rounded-lg'
      >
        <Col>
          <div
            className='text-center mx-auto mb-4'
            style={{ maxWidth: "20rem" }}
          >
            <h1 className='heading1 mb-4'>Class Cancel Request</h1>
            {false && (
              <div className='text-danger border border-danger fs-12 p-3 rounded-3'>
                You can only cancel class <b>4 hours</b> before class.
              </div>
            )}
          </div>
          <div className='regular-text'>
            <div className='d-flex gap-3 mb-3'>
              <div>
                <HiClock className='text-pink fs-5' /> {state?.class_date},{" "}
                {state?.class_time}
              </div>
              <div>
                <HiUser className='text-pink fs-5' /> {state?.teachers}
              </div>
            </div>
            <p className='fw-bold'>{state?.class_name}</p>
            <hr />
            <span className='custom-form-label'>Email</span>
            <p>{user?.email}</p>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <TextInput
                  required
                  label='Your Messages'
                  placeholder='Input Your Messages...'
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Form.Group>
              <div className='my-3 form-check'>
                <input
                  id='checked'
                  type='checkbox'
                  className='form-check-input pointer'
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
                <label htmlFor='checked'>I've read the&nbsp;</label>
                <u
                  className='text-black custom-hover pointer'
                  onClick={() => setShow(true)}
                >
                  cancellation policy
                </u>
              </div>
              <div className='d-flex justify-content-center align-items-center gap-5 mt-5 mb-3'>
                <span
                  onClick={() => history.goBack()}
                  className='custom-form-label custom-hover pointer'
                >
                  Cancel
                </span>
                <Button
                  isLoading={!isChecked}
                  buttonType='submit'
                  title='SEND'
                  type='primary'
                />
              </div>
            </Form>
          </div>
        </Col>
      </Row>

      <Modal contentClassName='rounded-lg' size='lg' show={show}>
        <div className='p-5'>
          <h1 className='heading1 text-center'>
            {cancellation_setting?.cancellation_policy_title}
          </h1>
          <p className='overflow-auto' style={{ height: "20rem" }}>
            {cancellation_setting?.cancellation_policy_description}
          </p>
          <div className='d-flex justify-content-center mt-5'>
            <Button
              onClick={() => setShow(false)}
              title='Close'
              type='outline-secondary'
            />
          </div>
        </div>
      </Modal>
    </Container>
  );
};

export default PaymentMethod;
