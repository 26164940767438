import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import "./owner.styles.scss";

import Button from "../../components/Button/button.component";

import { addStudent, setStatusDefault } from "../../redux/owner/owner.action";
import { ConfigProvider, Form, Input, Select, Checkbox } from "antd";
import UploadPicture from "../../components/uploadPicture";

const AddStudent = ({ nextStep, colorTheme }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [cookies, setCookies] = useCookies(["user", "subdomain"]);
  const [pictureData, setPictureData] = useState(null);

  const { addStudentStatus, isLoadingStudent } = useSelector(
    (state) => state.owner
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);
  const { isRegisterStudent } = useSelector((state) => state.studio);

  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (addStudentStatus === "success") {
      nextStep();
      dispatch(setStatusDefault());
    }
  }, [addStudentStatus]);

  const handleSubmit = ({
    first_name,
    last_name,
    email,
    phone_number,
    send_welcome_email,
    gender,
  }) => {
    let params = {
      first_name,
      last_name,
      email,
      send_welcome_email: send_welcome_email ? 1 : 0,
      gender,
    };
    if (phone_number) params.phone_number = phone_number;
    if (pictureData) params.profile_picture = pictureData;

    dispatch(addStudent(params, user_token, studio_id));
  };

  return (
    <div className="w-full p-4 min-h-screen flex flex-col items-center">
      <h2 className="font-semibold my-10">Add your first student</h2>
      <div className=" text-black text-[20px] font-semibold my-6">
        Add Student
      </div>

      <ConfigProvider
        theme={{
          components: {
            Form: {},
          },
        }}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          className="w-full md:w-[568px]"
          scrollToFirstError
        >
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please input your First Name",
              },
            ]}
          >
            <Input className="h-10" placeholder="Input Your First Name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please input your Last Name",
              },
            ]}
          >
            <Input placeholder="Input Your Last Name" className="h-10" />
          </Form.Item>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email Address",
              },
            ]}
          >
            <Input placeholder="Input Your Email" className="h-10" />
          </Form.Item>
          <Form.Item label="Phone Number" name="phone_number">
            <Input
              type="number"
              placeholder="Input Your Phone Number"
              className="h-10"
            />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[
              {
                required: true,
                message: "Please input your Gender",
              },
            ]}
            initialValue="male"
          >
            <Select
              options={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ]}
              placeholder="Select Your Gender"
              className="h-10"
            />
          </Form.Item>
          <UploadPicture
            themeColor={colorTheme}
            fileData={(file) => setPictureData(file)}
          />
          <Form.Item valuePropName="checked" name="send_welcome_email">
            <Checkbox placeholder="Input Your Phone Number">
              Send welcome email
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              buttonType="submit"
              type="primary"
              title="CONTINUE"
              style={{ width: "100%", backgroundColor: colorTheme }}
              isLoading={isLoadingStudent}
            />
            <Button
              buttonType="button"
              type="secondary"
              title="Skip for now"
              onClick={() => {
                nextStep();
              }}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                color: colorTheme,
              }}
            />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </div>
  );
};

export default AddStudent;
