import React, { useEffect, useState } from "react";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import {
  Col,
  ConfigProvider,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Radio,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import CustomButton from "../../../../components/Button/custom-button";
import { CalendarOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import hexToRGBA from "../../../../helper/hexToRbga";
import { DateRange } from "react-date-range";
import dayjs from "dayjs";
import noPayouts from "../../../../assets/images/no-payouts.png";
import InputText from "../../../../components/input/text";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import {
  sendGetRequest,
  sendPacthRequest,
  sendPostRequest,
} from "../../../../services/students";
import { useCookies } from "react-cookie";

const { Text } = Typography;

const tabs = [
  { label: "Overview", value: "overview" },
  { label: "All Activity", value: "allActivity" },
  { label: "Payouts", value: "payouts" },
];

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const Balance = () => {
  const [cookies] = useCookies();
  const [action, setAction] = useState({
    payoutData: [],
    activityData: [],
    managePayouts: false,
    selectedTab: "overview",
    status: false,
    selectedStatus: "All",
    datePicker: false,
    payoutSchedule: false,
    selectedInterval: 0,
    choosePeriod: false,
    selectedPeriod: "day",
    chooseDay: false,
    selectedDay: "Monday",
    chooseDateOfMonth: false,
    selectedDateOfMonth: "1st",
    payoutFunds: false,
  });

  const isWeek = action.selectedPeriod === "week";

  const date = new Date();
  const first = new Date(date.getFullYear(), date.getMonth(), 1);

  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: first,
      endDate: date,
      key: "selection",
    },
  ]);

  const { user, studio_detail } = useSelector((state) => state.user);

  const user_token = user?.token || cookies.user?.token;

  const studio_id = user?.studio_id || cookies.user?.studio_id;

  const colorTheme = studio_detail?.styles?.header_color;

  useEffect(() => {
    const getAllActivity = async () => {
      try {
        const { data } = await sendGetRequest(
          user_token,
          studio_id,
          "payouts/activities"
        );
        setAction((prev) => ({ ...prev, activityData: data.data }));
      } catch (error) {
        message.error(<GenerateErrorMessage error={error} />);
      }
    };

    getAllActivity();
  }, [studio_id, user_token]);

  useEffect(() => {
    const getPayoutData = async () => {
      try {
        const { data } = await sendGetRequest(
          user_token,
          studio_id,
          `/payouts?arrival_date[start_date]=${dayjs(
            selectedDate[0].startDate
          ).format("YYYY-MM-DD")}&arrival_date[end_date]=${dayjs(
            selectedDate[0].endDate
          ).format(
            "YYYY-MM-DD"
          )}&status=${action.selectedStatus.toLocaleLowerCase()}`
        );
        console.log(data);
        setAction((prev) => ({ ...prev, payoutData: data.data }));
      } catch (error) {
        message.error(<GenerateErrorMessage error={error} />);
      }
    };

    getPayoutData();
  }, [action.selectedStatus, selectedDate, studio_id, user_token]);

  const content = (
    <Space direction="vertical" className="w-[220px]">
      <Text
        className="cursor-pointer hover:text-gray-500"
        onClick={() =>
          setAction((prev) => ({
            ...prev,
            managePayouts: false,
            payoutSchedule: true,
          }))
        }
      >
        Manage payout schedule
      </Text>

      <Text
        disabled
        className="cursor-pointer hover:text-gray-500"
        onClick={() => {
          return;
        }}
      >
        Manage bank account
      </Text>
    </Space>
  );

  const contentStatus = (
    <Space direction="vertical" className="w-[120px]">
      {["All", "Paid", "Pending", "Failed", "Cancelled"].map((status) => (
        <Text
          strong
          className="cursor-pointer hover:text-gray-500"
          onClick={() =>
            setAction((prev) => ({
              ...prev,
              status: false,
              selectedStatus: status,
            }))
          }
        >
          {status}
        </Text>
      ))}
    </Space>
  );

  const contentPeriod = (
    <Space direction="vertical" className="w-[80px]">
      {["day", "week", "month"].map((period) => (
        <Text
          key={period}
          strong
          className="cursor-pointer hover:text-gray-500"
          onClick={() =>
            setAction((prev) => ({
              ...prev,
              choosePeriod: false,
              selectedPeriod: period,
            }))
          }
        >
          {period}
        </Text>
      ))}
    </Space>
  );

  const contentDays = (
    <Space direction="vertical" className="w-[120px]">
      {daysOfWeek.map((day) => (
        <Text
          key={day}
          strong
          className="cursor-pointer hover:text-gray-500"
          onClick={() =>
            setAction((prev) => ({
              ...prev,
              chooseDay: false,
              selectedDay: day,
            }))
          }
        >
          {day}
        </Text>
      ))}
    </Space>
  );

  const getOrdinalSuffix = (num) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = num % 10;
    if (remainder > 3 || Math.floor((num % 100) / 10) === 1) {
      return { num: num, str: num + suffixes[0] };
    }
    return { num: num, str: num + suffixes[remainder] || suffixes[0] };
  };

  const dates = Array.from({ length: 31 }, (_, i) => getOrdinalSuffix(i + 1));

  const contentMonths = (
    <Space direction="vertical" className="w-[60px] overflow-y-scroll h-60">
      {dates.map((i) => (
        <Text
          key={i.str}
          strong
          className="cursor-pointer hover:text-gray-500"
          onClick={() =>
            setAction((prev) => ({
              ...prev,
              chooseDateOfMonth: false,
              selectedDateOfMonth: i.num,
            }))
          }
        >
          {i.str}
        </Text>
      ))}
    </Space>
  );

  const dataOverview = [
    {
      title: "USD balance",
      subData: [
        { subTitle: "On the way to your bank account", value: 0 },
        { subTitle: "Funds on hold", value: 0 },
        { subTitle: "Total", value: 0 },
      ],
    },
    {
      title: "Upcoming to your bank",
      subData: [
        { subTitle: `Today for `, value: 0 },
        { subTitle: "Total", value: 0 },
      ],
    },
    {
      title: "On the to your bank",
      subData: [{ subTitle: "Total", value: 0 }],
    },
  ];

  const columnsRecently = [
    { title: "Transaction", dataIndex: "transaction", key: "transaction" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
  ];

  const columnsAllActivity = [
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Available on", dataIndex: "available_on", key: "available_on" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Net", dataIndex: "net", key: "net" },
    { title: "Fee", dataIndex: "fee", key: "fee" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
  ];

  const columnsPayouts = [
    { title: "Arrive by", dataIndex: "arrive by", key: "arrive by" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Bank/card", dataIndex: "bank", key: "bank" },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  const handlePayoutSchedule = async () => {
    let params = {
      interval: action.selectedInterval ? "manual" : action.selectedPeriod,
    };
    if (action.selectedPeriod === "week")
      params.weekly_acnhor = action.selectedDay;
    if (action.selectedPeriod === "month")
      params.weekly_acnhor = action.selectedDateOfMonth;
    try {
      await sendPacthRequest(user_token, studio_id, params);
      message.success("Success!");
    } catch (error) {
      message.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handlePayOutFunds = async (values) => {
    try {
      await sendPostRequest(user_token, studio_id, values);
      message.success("Success!");
    } catch (error) {
      message.error(<GenerateErrorMessage error={error} />);
    }
  };

  const CustomDropdown = ({ action, setAction, content, title }) => (
    <Popover
      open={action}
      onOpenChange={(e) => {
        setAction(e);
      }}
      trigger={"click"}
      content={content}
      placement="bottomRight"
    >
      <div className="cursor-pointer hover:bg-slate-50 h-[45px] border border-[#CCCCCC] px-4 flex items-center gap-2 font-bold">
        <span>{title}</span>
        <CaretDownOutlined />
      </div>
    </Popover>
  );

  return (
    <OrganismLayout title="Balance" maxWidth="44rem">
      <ConfigProvider
        theme={{
          components: {
            Radio: {
              buttonSolidCheckedBg: colorTheme,
              buttonSolidCheckedActiveBg: colorTheme,
              buttonColor: "#BDBDBD",
              buttonSolidCheckedHoverBg: colorTheme,
              colorBgTextHover: colorTheme,
            },
            Table: {
              headerBorderRadius: 0,
              headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
            },
          },
        }}
      >
        <Row justify="space-between" align="middle" className="mb-2">
          <Col>
            <h2 className="font-bold">Balances</h2>
          </Col>

          <Col>
            {action.selectedTab === "overview" ? (
              <Space size="large">
                <CustomButton
                  title="Pay out funds"
                  type="primary"
                  // isLoading={"funds on hold = 0"}
                  onClick={() =>
                    setAction((prev) => ({ ...prev, payoutFunds: true }))
                  }
                />

                <CustomDropdown
                  action={action.managePayouts}
                  content={content}
                  setAction={(e) =>
                    setAction((prev) => ({ ...prev, managePayouts: e }))
                  }
                  title="Manage payouts"
                />
              </Space>
            ) : action.selectedTab === "payouts" ? (
              <Space size="large">
                <div
                  className="h-[45px] px-4 border border-[#CCCCCC] flex items-center gap-2 hover:bg-slate-50 pointer"
                  onClick={() =>
                    setAction((prev) => ({ ...prev, datePicker: true }))
                  }
                >
                  <Text>
                    {`${dayjs(selectedDate[0].startDate).format(
                      "DD MMM, YYYY"
                    )} - ${dayjs(selectedDate[0].endDate).format(
                      "DD MMM, YYYY"
                    )}`}
                  </Text>
                  <CalendarOutlined />
                </div>

                <CustomDropdown
                  action={action.status}
                  content={contentStatus}
                  setAction={(e) =>
                    setAction((prev) => ({ ...prev, status: e }))
                  }
                  title="Status"
                />

                <CustomButton title="Export" type="primary" />
              </Space>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <Radio.Group
          size="large"
          options={tabs}
          buttonStyle="solid"
          optionType="button"
          value={action.selectedTab}
          onChange={(e) => {
            setAction((prev) => ({ ...prev, selectedTab: e.target.value }));
          }}
        />

        <div className="my-8">
          {false ? (
            <Row>
              <Col span={12}>
                <div className="bg-[#FFF4CA] p-3 flex justify-between items-center mb-4">
                  <Text strong>
                    Add a bank account to pay out your USD balance
                  </Text>
                  <CustomButton type="primary" title="Add bank account" />
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {action.selectedTab === "overview" ? (
            <Space direction="vertical" size="large">
              {dataOverview.map((i, idx) => (
                <Row key={idx} justify="space-between">
                  <Col span={24}>
                    <h6>{i.title}</h6>
                  </Col>
                  {i.subData.map((sub) => (
                    <React.Fragment key={sub.subTitle}>
                      <Col
                        span={12}
                        className={
                          sub.subTitle === "Total" ? "font-semibold" : ""
                        }
                      >
                        {sub.subTitle}
                      </Col>
                      <Col>{sub.value}</Col>
                    </React.Fragment>
                  ))}
                </Row>
              ))}

              <Row justify="space-between">
                <Col span={true ? 12 : 24}>
                  <h6>Recently completed</h6>
                </Col>

                {true ? (
                  <Col>
                    <h6
                      className={`hover:text-[${colorTheme}] hover:opacity-75`}
                      style={{ color: colorTheme, cursor: "pointer" }}
                      onClick={() =>
                        setAction((prev) => ({
                          ...prev,
                          selectedTab: "payouts",
                        }))
                      }
                    >
                      View all payouts
                    </h6>
                  </Col>
                ) : (
                  <></>
                )}

                <Col className="mt-4" span={24}>
                  {true ? (
                    <Table columns={columnsRecently} />
                  ) : (
                    <Text strong type="secondary">
                      You have not yet had any completed payouts
                    </Text>
                  )}
                </Col>
              </Row>
            </Space>
          ) : action.selectedTab === "allActivity" ? (
            <Table columns={columnsAllActivity} />
          ) : false ? (
            <Table columns={columnsPayouts} />
          ) : (
            <div className="flex justify-center text-center">
              <Space
                direction="vertical"
                size="large"
                align="center"
                className="w-[400px]"
              >
                <img src={noPayouts} alt="logo-no-payouts" />

                <h5>No Payouts</h5>

                <Text type="secondary">
                  Payouts will show up here, along with the date they’re
                  expected to arrive in your bank account
                </Text>
              </Space>
            </div>
          )}
        </div>

        <Modal
          centered
          open={action.datePicker}
          onCancel={() => setAction((prev) => ({ ...prev, datePicker: false }))}
          footer={false}
        >
          <div className="py-2 text-center">
            <DateRange
              className="shadow-sm"
              editableDateInputs={true}
              onChange={(item) => setSelectedDate([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={selectedDate}
            />
          </div>
        </Modal>

        <Modal
          centered
          closable={false}
          open={action.payoutSchedule}
          footer={false}
          className="p-2"
        >
          <h5 className="text-center">Payout Schedule</h5>

          <Text>
            Set a schedule to automatically move revenue from your payments
            balance into your bank account.
          </Text>

          <div className="my-4">
            <Radio.Group
              onChange={(e) =>
                setAction((prev) => ({
                  ...prev,
                  selectedInterval: e.target.value,
                }))
              }
              value={action.selectedInterval}
            >
              <Space direction="vertical">
                <Radio value={0}>
                  <div className="flex items-center gap-2">
                    <Text>Automatic every</Text>
                    <CustomDropdown
                      content={contentPeriod}
                      title={action.selectedPeriod}
                      action={action.choosePeriod}
                      setAction={(e) =>
                        setAction((prev) => ({ ...prev, choosePeriod: e }))
                      }
                    />

                    {action.selectedPeriod !== "day" ? (
                      <Space>
                        <Text>on the</Text>

                        <CustomDropdown
                          content={isWeek ? contentDays : contentMonths}
                          title={
                            isWeek
                              ? action.selectedDay
                              : action.selectedDateOfMonth
                          }
                          action={
                            isWeek ? action.chooseDay : action.chooseDateOfMonth
                          }
                          setAction={(e) =>
                            setAction((prev) =>
                              isWeek
                                ? {
                                    ...prev,
                                    chooseDay: e,
                                  }
                                : { ...prev, chooseDateOfMonth: e }
                            )
                          }
                        />
                      </Space>
                    ) : (
                      <></>
                    )}
                  </div>
                </Radio>
                <Radio value={1}>
                  Manual - You’ll no longer be able to see which transactions
                  are bundled in a payout.
                </Radio>
              </Space>
            </Radio.Group>
          </div>

          <Row justify="end" gutter={[16, 0]}>
            <Col>
              <CustomButton
                type="secondary"
                title="Cancel"
                onClick={() =>
                  setAction((prev) => ({ ...prev, payoutSchedule: false }))
                }
              />
            </Col>

            <Col>
              <CustomButton
                type="primary"
                title="Save"
                onClick={handlePayoutSchedule}
              />
            </Col>
          </Row>
        </Modal>

        <Modal
          centered
          closable={false}
          open={action.payoutFunds}
          footer={false}
          className="p-2"
        >
          <h5 className="text-center">Pay Out Funds</h5>

          <Form layout="vertical" onFinish={handlePayOutFunds}>
            <InputText
              required={false}
              type="number"
              name="amount"
              label="Amount to pay out"
              prefix={"$"}
              placeholder="Amount to pay out"
            />
            <Form.Item name="notes" label="Internal note">
              <Input.TextArea rows={4} placeholder="Type your text here..." />
            </Form.Item>

            <Space size="large">
              <Text strong>Fee:</Text>
              <Text>0</Text>
            </Space>

            <Row justify="end" gutter={[16, 0]}>
              <Col>
                <CustomButton
                  type="secondary"
                  title="Cancel"
                  onClick={() =>
                    setAction((prev) => ({ ...prev, payoutFunds: false }))
                  }
                />
              </Col>

              <Col>
                <CustomButton type="primary" title="Save" buttonType="submit" />
              </Col>
            </Row>
          </Form>
        </Modal>
      </ConfigProvider>
    </OrganismLayout>
  );
};

export default Balance;
