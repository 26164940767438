import React from "react";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { Col, Row, Space, Steps, Table, Tag } from "antd";

const DetailBalance = () => {
  const details = [
    { title: "Payout completed", value: "2 Jan 2014" },
    { title: "Payout ID", value: "212014" },
    { title: "Internal note", value: "lorem" },
    { title: "Fee", value: "0" },
    { title: "Amount", value: "0" },
  ];

  const accountDetails = [
    { title: "Bank Account", value: "BCA" },
    { title: "Account Holder Name", value: "Aoka" },
    { title: "Account Number", value: "123123" },
  ];

  const summaryTh = ["Count", "Gross", "Fees", "Total"];

  const summaryData = [
    { title: "Charges", count: 2, gross: 2, fees: 2, total: 6 },
    { title: "Refunds", count: 2, gross: 2, fees: 2, total: 6 },
    { title: "Adjustments", count: 2, gross: 2, fees: 2, total: 6 },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      align: "end",
    },
    {
      title: "Gross",
      dataIndex: "gross",
      key: "gross",
      align: "end",
    },
    {
      title: "Fees",
      dataIndex: "fees",
      key: "fees",
      align: "end",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "end",
    },
  ];

  return (
    <OrganismLayout>
      <Row gutter={[0, 6]} className="mb-4">
        <Col span={24}>
          <Space align="center" size="large">
            <h6>$911</h6>
            <Tag color="success">Paid</Tag>
          </Space>
        </Col>

        {details.map((item) => (
          <React.Fragment key={item.title}>
            <Col span={6}>{item.title}</Col>
            <Col span={18}>{item.value}</Col>
          </React.Fragment>
        ))}
      </Row>

      <h6>Timline</h6>
      <Steps
        className="mb-4"
        progressDot
        current={3}
        direction="vertical"
        items={[
          {
            title: "Payout",
            description: "This is a description. This is a description.",
          },
          {
            title: "In Progress",
            description: "This is a description. This is a description.",
          },
          {
            title: "Waiting",
            description: "This is a description.",
          },
        ]}
      />

      <h6>Account details</h6>
      <Row gutter={[0, 6]} className="mb-4">
        {accountDetails.map((item) => (
          <React.Fragment key={item.title}>
            <Col span={6}>{item.title}</Col>
            <Col span={18}>{item.value}</Col>
          </React.Fragment>
        ))}
      </Row>

      {/* <Row>
        <Col span={4}></Col>
        {summaryTh.map((item) => (
          <Col key={item} span={4}>
            <b>{item}</b>
          </Col>
        ))}
      </Row>

      {summaryData.map((item) => (
        <Row key={item.title}>
          <Col span={4}>{item.title}</Col>
          <Col span={4}>{item.count}</Col>
          <Col span={4}>{item.gross}</Col>
          <Col span={4}>{item.Fees}</Col>
          <Col span={4}>{item.total}</Col>
        </Row>
      ))} */}

      <div className="max-w-xl">
        <Table columns={columns} dataSource={summaryData} pagination={false} />

        <Row justify="end" gutter={[64, 0]} className="mt-2">
          <Col>
            <h6>Payouts</h6>
          </Col>

          <Col>
            <h6>$911.00</h6>
          </Col>
        </Row>
      </div>
    </OrganismLayout>
  );
};

export default DetailBalance;
