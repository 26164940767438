import UserActionTypes from "./user.types";

const INITIAL_STATE = {
  studio_detail: null,
  user: {},
  isLoading: false,
  message: "",
  statusVerify: "default",
  login_email: "",
  auth: {},
  loginFromStudio: false,
  status: "default",
  status_change_role: "default",
  active_menu: "Dashboard",
};

const UserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.GET_STUDIO_DETAIL:
      return {
        ...state,
      };
    case UserActionTypes.GET_STUDIO_DETAIL_SUCCESS:
      return {
        ...state,
        studio_detail: action.payload,
      };
    case UserActionTypes.GET_STUDIO_DETAIL_FAILED:
      return {
        ...state,
        message: action.message,
      };
    case UserActionTypes.SET_MENU_ACTIVE:
      return {
        ...state,
        active_menu: action.payload,
      };
    case UserActionTypes.REQUEST_OTP:
      return {
        ...state,
        isLoading: true,
        login_email: "",
        statusVerify: "default",
        message: "",
      };
    case UserActionTypes.REQUEST_OTP_RESPONSE:
      return {
        ...state,
        login_data: action.payload,
      };
    case UserActionTypes.REQUEST_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        login_email: action.payload,
        statusVerify: "success",
        message: "",
      };
    case UserActionTypes.REQUEST_OTP_FAILED:
      return {
        ...state,
        isLoading: false,
        login_email: "",
        statusVerify: "failed",
        message: action.message,
      };
    case UserActionTypes.RESEND_OTP:
      return {
        ...state,
        login_email: "",
        status: "default",
        message: "",
      };
    case UserActionTypes.RESEND_OTP_SUCCESS:
      return {
        ...state,
        login_email: action.payload,
        status: "success",
        message: "",
      };
    case UserActionTypes.RESEND_OTP_FAILED:
      return {
        ...state,
        login_email: "",
        status: "failed",
        message: action.message,
      };
    case UserActionTypes.REQUEST_OTP_ACTIVATE:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionTypes.REQUEST_OTP_ACTIVATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // user: action.payload,
      };
    case UserActionTypes.REQUEST_OTP_ACTIVATE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case UserActionTypes.ACTIVATE_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionTypes.ACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case UserActionTypes.ACTIVATE_ACCOUNT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case UserActionTypes.VERIFY_OTP:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionTypes.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        statusVerify: "success",
      };
    case UserActionTypes.VERIFY_OTP_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        statusVerify: "failed",
      };
    case UserActionTypes.VERIFY_OTP_DEFAULT:
      return {
        ...state,
        statusVerify: "default",
        message: "",
      };
    case UserActionTypes.LOGIN_FROM_STUDIO:
      return {
        ...state,
        loginFromStudio: true,
      };
    case UserActionTypes.SET_STATUS_DEFAULT:
      return {
        ...state,
        status: "default",
        login_data: [],
      };
    case UserActionTypes.SET_CHANGE_ROLE:
      return {
        ...state,
      };
    case UserActionTypes.SET_CHANGE_ROLE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        status_change_role: "success",
      };
    case UserActionTypes.SET_CHANGE_ROLE_FAILED:
      return {
        ...state,
        status_change_role: "failed",
      };
    case UserActionTypes.SET_NEW_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UserActionTypes.LOGOUT:
      return {
        ...state,
        user: {},
        auth: {},
        login_data: {},
        login_email: "",
        message: "",
        isLoading: false,
        loginFromStudio: false,
        status: "default",
        statusVerify: "default",
      };
    default:
      return state;
  }
};

export default UserReducer;
