import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Dots from "../../assets/images/dots.svg";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import { ModalLogin } from "./modal";
import { getStudioInfo } from "../../services/studios";

import {
  requestOtp,
  setStatusVerifyDefault,
  requestOtpActivate,
  setNewUser,
  setLoginFromStudio,
  setStatusDefault,
} from "../../redux/user/user.action";
import * as authService from "../../services/auth";
import StudioLoginForm from "./login-form";

const StudioLogin = () => {
  const subdomain = window.location.host.split(".")[0];

  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, message, statusVerify, login_data, studio_detail } =
    useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [logo, setLogo] = useState("");
  const [colorTheme, setColorTheme] = useState("");
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalReactivateStudent, setShowModalReactivateStudent] =
    useState(false);
  const [showModalReactivateTeacher, setShowModalReactivateTeacher] =
    useState(false);
  const [registerWithSocmed, setRegisterWithSocmed] = useState(false);
  const [method, setMethod] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const responseSocialAuth = async (provider, response) => {
    try {
      console.log(response, "response");

      const access_token = response?.accessToken;
      setMethod(provider);
      setAccessToken(access_token);
      setEmail(response.profileObj?.email ?? response?.email);

      if (access_token) {
        const result = await authService.loginWithSocmed(
          provider,
          access_token
        );
        console.log("result", result);
        if (result.data.data.token) {
          dispatch(setLoginFromStudio());
          dispatch(setNewUser(result.data?.data));
          history.push("/dashboard");
        }
      }
    } catch (error) {
      if (error.response.data.message === "User not found") {
        setShowModalRegister(true);
        setRegisterWithSocmed(true);
      }
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleRegisterWithSocmed = async () => {
    try {
      const result = await authService.registerWithSocmed(method, accessToken);
      console.log(result, "result");
      if (result.data.data.token) {
        dispatch(setLoginFromStudio());
        dispatch(setNewUser(result.data?.data));
        history.push("/dashboard");
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (subdomain === process.env.REACT_APP_HOST.split(".")[0]) {
      dispatch(requestOtp(email));
    } else {
      dispatch(requestOtp(email, subdomain));
    }
  };

  const handleClose = () => {
    setShowModalRegister(false);
  };

  const redirectToRegister = () => {
    registerWithSocmed
      ? handleRegisterWithSocmed()
      : history.push("/register-student");
  };

  const sendRequestOtpActivate = () => {
    let params = {
      email: email,
      role: login_data?.role,
    };

    dispatch(requestOtpActivate(params, subdomain));
    if (login_data?.role === "teacher") {
      setShowModalReactivateTeacher(false);
      setEmail("");
    }
    login_data?.role === "student" &&
      history.push({ pathname: "/verify", state: { isReactivate: true } });
  };

  const handleGetStudioInfo = async () => {
    try {
      const res = await getStudioInfo(subdomain);
      setLogo(res.data.data.studio_logo_link);
      setColorTheme(res.data.data.studio_header_color);
    } catch (error) {
      console.log(error.config);
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    if (message === "User not registered") {
      setShowModalRegister(true);
      dispatch(setStatusVerifyDefault());
    }
  }, [dispatch, message]);

  useEffect(() => {
    if (login_data?.is_account_active === 0 && login_data?.role === "student") {
      setShowModalReactivateStudent(true);
    } else if (
      login_data?.is_account_active === 0 &&
      login_data?.role === "teacher"
    ) {
      setShowModalReactivateTeacher(true);
    }
  }, [login_data]);

  useEffect(() => {
    if (statusVerify === "success" && login_data?.is_account_active === 1) {
      dispatch(setStatusDefault());
      history.push("/verify");
    }
  }, [dispatch, history, login_data?.is_account_active, statusVerify]);

  useEffect(() => {
    handleGetStudioInfo();
  }, []);

  return (
    <div className="h-full">
      <div className="md:flex min-h-screen w-full">
        <img
          src={Dots}
          className="w-56 absolute top-[230px] -left-[100px] z-0 md:top-[75%]"
          alt="dots"
        />
        <img
          src={Dots}
          className="w-56 absolute top-0 right-0 md:right-[40%] z-0 "
          alt="dots"
        />
        <div
          className="md:w-3/5"
          style={{
            backgroundColor:
              colorTheme || studio_detail?.styles?.header_color || "#60369B",
            padding: 30,
          }}
        >
          <div className="text-white text-title">
            {studio_detail?.studio_name}
          </div>
          <div
            style={{ height: "100%" }}
            className="d-flex flex-column justify-content-center align-items-center flex-wrap text-white"
          >
            <img
              src={studio_detail?.logo_link || logo}
              alt="logo"
              className="logo-style"
            />
            <h4 className="mt-4">WELCOME TO GIGSCLASS</h4>
            <div style={{ width: 400, textAlign: "center", color: "#BAC2DB" }}>
              <p>
                One app fit for the entire studios. Making learn fun and
                exciting
              </p>
              <p>Flexible learning inspired by the pandemic</p>
            </div>
          </div>
        </div>

        <div className="flex  justify-center md:w-2/5 px-20 items-center">
          <StudioLoginForm
            subdomain={subdomain}
            colorTheme={colorTheme}
            email={email}
            handleClick={handleClick}
            handleChange={handleChange}
            responseSocialAuth={responseSocialAuth}
          />
        </div>
      </div>

      <ModalLogin
        show={showModalRegister}
        title="Email Not Registered"
        label="Continue register with"
        email={email}
        titleButton1={"CHANGE"}
        titleButton2={"CONFIRM"}
        handleClose={handleClose}
        handleSubmit={redirectToRegister}
      />

      <ModalLogin
        show={showModalReactivateStudent}
        title="Welcome Back!"
        label="Continue to reactivate your account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"CONFIRM"}
        handleClose={() => setShowModalReactivateStudent(false)}
        handleSubmit={sendRequestOtpActivate}
      />

      <ModalLogin
        show={showModalReactivateTeacher}
        title="Your Account Is Currently Being Deactivated"
        label="Please contact the studio owner to re-adding your teacher account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"SEND EMAIL"}
        handleClose={() => setShowModalReactivateTeacher(false)}
        handleSubmit={sendRequestOtpActivate}
      />
    </div>
  );
};

export default StudioLogin;
