import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { useCookies } from "react-cookie";
import { getTeacherDetail } from "./../../../../redux/owner/owner.action";
import "react-calendar/dist/Calendar.css";
import axiosInstance from "../../../../axiosInstance";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { ConfigProvider, Modal, Popover, Space, Table } from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";
import { CaretDownOutlined } from "@ant-design/icons";
import Button from "../../../../components/Button/button.component";
import AtomLoading from "../../../../components/loding-indicator";

const TeacherDetail = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState({ visible: false, name: "", email: "" });

  const [active, setActive] = useState(1);

  const [classHeld, setClassHeld] = useState([]);
  const [upcomingClasses, setUpcomingClasses] = useState([]);

  const { teacher_detail, isLoadingTeacher } = useSelector(
    (state) => state.owner
  );
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const teacher_name = `${teacher_detail?.first_name} ${
    teacher_detail?.last_name !== null ? teacher_detail?.last_name : ""
  }`;
  const id = props.location.pathname?.split("/")[3];

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const mobileSize = useMemo(() => window.screen.width < 576, []);

  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  useEffect(() => {
    if (id) dispatch(getTeacherDetail(user_token, studio_id, id));
  }, [dispatch, id, studio_id, user_token]);

  const columnsClassHeld = [
    {
      title: "Project name",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Date",
      dataIndex: "class_date",
      key: "class_date",
    },
    {
      title: "Est. Turnover",
      dataIndex: "turnover",
      key: "turnover",
      render: (text) => <span>IDR {text}</span>,
    },
    {
      title: "Participant",
      dataIndex: "participants",
      key: "participants",
    },
    {
      title: "Attended",
      dataIndex: "attended",
      key: "attended",
    },
  ];

  const columnsUpcomingClass = [
    {
      title: "Project name",
      dataIndex: "class_name",
      key: "class_name",
    },
    {
      title: "Date",
      dataIndex: "date_time_class",
      key: "date_time_class",
    },
    {
      title: "Participant",
      dataIndex: "participant",
      key: "participant",
    },
  ];

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/teachers/${teacher_detail?.id}`, config);

      setShow1({ visible: false });
      toast.success("Teacher account is successfully deleted!");
      history.push("/admin/teacher");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const startDate = moment();
  const endDate = moment().endOf("month");

  const getTeacherDashboardClassHeld = async (action_type, id) => {
    try {
      const res = await axiosInstance.get(
        `/dashboard-teachers?start_date=${moment(startDate).format(
          "YYYY-MM-DD"
        )}&end_date=${endDate.format(
          "YYYY-MM-DD"
        )}&actiontype=${action_type}&teacher_id=${id}`,
        config
      );

      setClassHeld(res?.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeacherDashboardUpcomingClass = async (action_type, id) => {
    try {
      const res = await axiosInstance.get(
        `/dashboard-teachers?start_date=${moment(startDate).format(
          "YYYY-MM-DD"
        )}&end_date=${endDate.format(
          "YYYY-MM-DD"
        )}&actiontype=${action_type}&teacher_id=${id}`,
        config
      );

      setUpcomingClasses(res?.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTeacherDashboardClassHeld("class-held", teacher_detail?.id);
    getTeacherDashboardUpcomingClass("upcoming-classes", teacher_detail?.id);
  }, [teacher_detail?.id]);

  return (
    <>
      {isLoadingTeacher ? (
        <AtomLoading />
      ) : (
        <OrganismLayout fluid style={{ height: "100%" }} className="bg-light">
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
                  headerBorderRadius: 0,
                },
              },
            }}
          >
            <div className="flex gap-4">
              <div>
                <img
                  className="h-20 w-20 rounded-full"
                  src={teacher_detail?.profile_picture}
                  alt="logo"
                />
              </div>

              <div className="flex flex-col gap-1">
                <span className="font-bold text-[2rem] flex ">
                  {teacher_detail?.first_name} {teacher_detail?.last_name}
                  <Popover
                    placement="bottom"
                    content={() => (
                      <Space direction="vertical">
                        <span
                          onClick={() => {
                            history.push({
                              pathname: `/admin/teacher/${teacher_detail?.id}/edit`,
                              state: { id: teacher_detail?.id },
                            });
                          }}
                          className="font-semibold cursor-pointer"
                        >
                          Edit Profile
                        </span>

                        <span
                          onClick={() => {
                            setShow(false);
                            setShow1({
                              id: teacher_detail?.id,
                              visible: true,
                              name: `${teacher_detail?.first_name} ${teacher_detail?.last_name}`,
                              email: teacher_detail?.email,
                            });
                          }}
                          className="font-semibold cursor-pointer text-[#EB5757]"
                        >
                          Delete
                        </span>
                      </Space>
                    )}
                  >
                    <CaretDownOutlined style={{ fontSize: 18 }} />
                  </Popover>
                </span>

                <b className="capitalize text-[#BDBDBD]">
                  {teacher_detail?.gender}
                </b>
                <span>{teacher_detail?.email}</span>
                <span>{teacher_detail?.phone_number}</span>
              </div>
            </div>

            <div
              style={{ border: `solid 1px ${hexToRGBA(colorTheme, 0.3)}` }}
              className="my-8"
            >
              <h5 className="m-3">Class Held</h5>
              <Table
                columns={columnsClassHeld}
                dataSource={classHeld?.map((item) => {
                  return {
                    key: item.id,
                    class_name: item.class_name,
                    class_date: item.class_date,
                    turnover: item.turnover,
                    participants: item.participants,
                    attended: item.attended,
                  };
                })}
              />
            </div>

            <div style={{ border: `solid 1px ${hexToRGBA(colorTheme, 0.3)}` }}>
              <h5 className="m-3">Upcoming Class</h5>
              <Table
                columns={columnsUpcomingClass}
                dataSource={upcomingClasses?.map((item) => {
                  return {
                    key: item.id,
                    class_name: item.class_name,
                    date_time_class: item.date_time_class,
                    participant: item.participant,
                  };
                })}
              />
            </div>
          </ConfigProvider>
        </OrganismLayout>
      )}

      <Modal
        open={show1?.visible}
        onOk={() => setShow1({ visible: false })}
        onCancel={() => setShow1({ visible: false })}
        footer={null}
      >
        <div className="text-center p-3 flex flex-col items-center gap-2">
          <h1 className=" font-bold">Delete Teacher</h1>
          <p className="text-[20px] font-semibold">Delete this Teacher?</p>
          <div className="border border-slate-300 w-full">
            <p className="m-0 p-4">
              <b style={{ color: colorTheme }}>{show1?.name}</b>

              <br />
              <span className="text-[#475569]">{show1?.email}</span>
            </p>
          </div>
          <div
            style={{ border: "1px solid #E2685147" }}
            className="text-center p-3"
          >
            <b>
              Please delete all class assignments related to the teacher before
              deleting the teacher account
            </b>
          </div>

          <div className="flex flex-col gap-3 mt-4">
            <Button
              title="DELETE"
              type="primary"
              onClick={() => handleDelete()}
              style={{
                backgroundColor: "#EB5757",
                borderRadius: 0,
              }}
            />

            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow1({ visible: false })}
              style={{
                backgroundColor: "white",
                color: colorTheme,
                border: "none",
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TeacherDetail;
