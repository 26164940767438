import React, { useEffect, useState, useRef, useMemo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Row, Col, Modal } from "react-bootstrap";
import Pagination from "./../../../../components/pagination";
import AtomLoading from "../../../../components/loding-indicator";
import calender from "../../../../assets/images/calender.svg";
import Classes from "../../../../assets/images/classes.svg";
import Attendants from "../../../../assets/images/attendants.svg";
import Registrants from "../../../../assets/images/registrants.svg";
import Transactions from "../../../../assets/images/transactions.svg";
import {
  getProfile,
  getUserRole,
} from "../../../../redux/student/student.action";
import {
  getStatisticProfile,
  getStatisticCancellation,
  getStatisticUpcomingClass,
  getStatisticClassInProgress,
  getOnboardingStatus,
  getTeacher,
} from "../../../../redux/owner/owner.action";
import {
  setLoginFromStudio,
  setMenuActive,
  setNewUser,
} from "../../../../redux/user/user.action";
import EmptyData from "../../../../components/no-data";
import { changeRole } from "../../../../services/auth";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import { DateRange } from "react-date-range";
import Button from "../../../../components/Button/button.component";
import { getStudioDetail } from "../../../../redux/user/user.action";
import CardDashboard from "../../../../components/CardDashboard/cardDashboard.component";
import hexToRGBA from "../../../../helper/hexToRbga";
import Sidebar from "../../../../components/SidebarOwner";
import { CaretDownOutlined } from "@ant-design/icons";
import { ConfigProvider, Menu, Popover, Tag } from "antd";

const DashBoard = () => {
  const dispatch = useDispatch();

  const [active1, setActive1] = useState(1);
  const [active2, setActive2] = useState(1);
  const [active3, setActive3] = useState(1);
  // const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [cookies, removeCookies] = useCookies(["user"]);
  // const [listActivities, setListActivities] = useState([]);
  // const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState({ visible: false });

  let date = new Date();
  let last = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);

  const [state, setState] = useState([
    {
      startDate: last,
      endDate: date,
      key: "selection",
    },
  ]);

  const {
    profile_statistic,
    list_cancellation_request,
    list_upcoming_class,
    list_class_in_progress,
    isLoading,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const { user_role, profile } = useSelector((state) => state.student);

  const role =
    loginFromStudio || isRegisterStudent ? user?.role : cookies.user?.role;
  const userRoles = user_role.roles
    ? user_role.roles?.filter((item) => item !== role)
    : [];
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const summary = [
    {
      title: "Classes",
      value: profile_statistic?.total_class,
      pathname: "/admin/list-statistic",
      subText: "completed",
      subValue: profile_statistic?.total_class_completed,
    },
    {
      title: "Registrants",
      value: profile_statistic?.total_registrant,
      pathname: "/admin/list-statistic",
      subText: "capacity",
      subValue: profile_statistic?.registrant_capacity,
    },
    {
      title: "Attendants",
      value: profile_statistic?.total_attendants,
      pathname: "/admin/list-statistic",
      subText: "registered",
      subValue: profile_statistic?.attendants_registered,
    },
    {
      title: "Transactions",
      value: profile_statistic?.total_transaction,
      pathname: "/admin/list-statistic",
      subText: "completed",
      subValue: profile_statistic?.transaction_completed,
    },
  ];

  const setRoles = async (e) => {
    try {
      const params = {
        role: e.key,
      };

      const res = await changeRole(user_token, studio_id, params);
      dispatch(setNewUser(res.data.data));

      // if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      // }
      dispatch(setLoginFromStudio());
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  useEffect(() => {
    dispatch(getUserRole(user_token, studio_id));
    dispatch(getProfile(user_token, studio_id));
    dispatch(getOnboardingStatus(user_token, studio_id));
    dispatch(getTeacher(user_token, studio_id, 1));
    dispatch(setMenuActive("Dashboard"));
  }, [dispatch, studio_id, user_token]);

  useEffect(() => {
    dispatch(
      getStatisticProfile(
        user_token,
        studio_id,
        moment(state[0].startDate).format("YYYY-MM-DD"),
        moment(state[0].endDate).format("YYYY-MM-DD")
      )
    );
  }, [dispatch, state, studio_id, user_token]);

  // useEffect(() => {
  //   dispatch(
  //     getStatisticPaymentVerification(
  //       user_token,
  //       studio_id,
  //       moment(state[0].startDate).format("YYYY-MM-DD"),
  //       active
  //     )
  //   );
  // }, [state, active]);

  useEffect(() => {
    dispatch(
      getStatisticClassInProgress(
        user_token,
        studio_id,
        moment(state[0].startDate).format("YYYY-MM-DD"),
        moment(state[0].endDate).format("YYYY-MM-DD"),
        active1
      )
    );
  }, [state, active1, dispatch, user_token, studio_id]);

  useEffect(() => {
    dispatch(
      getStatisticCancellation(
        user_token,
        studio_id,
        moment(state[0].startDate).format("YYYY-MM-DD"),
        moment(state[0].endDate).format("YYYY-MM-DD"),
        active2
      )
    );
  }, [state, active2, dispatch, user_token, studio_id]);

  useEffect(() => {
    dispatch(
      getStatisticUpcomingClass(
        user_token,
        studio_id,
        moment(state[0].startDate).format("YYYY-MM-DD"),
        moment(state[0].endDate).format("YYYY-MM-DD"),
        active3
      )
    );
  }, [state, active3, dispatch, user_token, studio_id]);

  useEffect(() => {
    dispatch(getStudioDetail(user_token, studio_id));
  }, [studio_id, user_token, dispatch]);

  const items = [
    {
      key: `1`,
      label: "Select Role",
      children: userRoles.map((el) => ({
        key: el,
        label: el.toLowerCase().replace(/\w/, (x) => x.toUpperCase()),
      })),
    },
  ];

  const content = (
    <div className="w-[220px]">
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemSelectedColor: "rgba(0, 0, 0, 0.88)",
            },
          },
        }}
      >
        <Menu
          mode="inline"
          items={items}
          className="font-bold capitalize"
          onClick={setRoles}
        />
      </ConfigProvider>
    </div>
  );

  return (
    <Sidebar>
      <div className="d-flex row">
        {isLoading ? (
          <AtomLoading />
        ) : (
          <div>
            <div className="mx-auto mb-5 fs-12 px-1">
              <div>
                <div className="flex justify-between items-center">
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="heading1 fw-bold">
                        {profile?.first_name} {profile?.last_name}
                      </span>
                      <Popover
                        open={open}
                        onOpenChange={() => setOpen((prev) => !prev)}
                        trigger="click"
                        placement="bottom"
                        content={content}
                      >
                        <CaretDownOutlined onClick={() => setOpen(true)} />
                      </Popover>
                    </div>

                    <br />
                    <span className="heading2 fw-normal text-capitalize">
                      {role === "owner" ? "admin" : role}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <img alt="" src={calender} width={18} />
                    <span
                      onClick={() => setSelectedDate({ visible: true })}
                      className=" pointer custom-hover ms-2"
                    >
                      {`${moment(state[0].startDate).format(
                        "DD MMM, YYYY"
                      )} - ${moment(state[0].endDate).format("DD MMM, YYYY")}`}
                    </span>
                  </div>
                </div>
              </div>

              <div className="md:flex gap-2">
                {summary.map((e, i) => {
                  return (
                    <CardDashboard
                      key={i}
                      icon={
                        e.title === "Classes"
                          ? Classes
                          : e.title === "Registrants"
                          ? Registrants
                          : e.title === "Attendants"
                          ? Attendants
                          : e.title === "Transactions"
                          ? Transactions
                          : null
                      }
                      title={e.title}
                      number={e.value}
                      subnumber={e.subValue}
                      subText={e.subText}
                      // color={"#fafafa"}
                      color={hexToRGBA(
                        studio_detail?.styles?.header_color,
                        0.2
                      )}
                      pathname={e.pathname}
                      state={state}
                    />
                  );
                })}
              </div>

              <div className="md:flex gap-2">
                <div
                  className="my-2 p-0  bg-white md:w-3/5 "
                  style={{
                    minHeight: 200,
                    border: `1px solid ${hexToRGBA(
                      studio_detail?.styles?.header_color,
                      0.4
                    )}`,
                  }}
                >
                  <p className="font-bold p-2 m-0">Class In Progress</p>
                  <div
                    style={{
                      backgroundColor: hexToRGBA(
                        studio_detail?.styles?.header_color,
                        0.1
                      ),
                    }}
                    className="p-3"
                  >
                    <Row>
                      <Col>Name</Col>
                      <Col>Registrants</Col>
                      <Col>Attendants</Col>

                      <Col>Type</Col>
                    </Row>
                  </div>
                  {list_class_in_progress.data?.length > 0 ? (
                    list_class_in_progress.data?.map((el, idx) => (
                      <div key={idx} className="">
                        <Row className="px-3 pt-2">
                          <Col>{el?.class_name}</Col>
                          <Col>{el?.participant}</Col>
                          <Col>{el?.attended}</Col>
                          <Col>
                            <Tag color={el?.is_online ? "#2D9CDB" : "#27AE60"}>
                              {el?.is_online ? "Online" : "Onsite"}
                            </Tag>{" "}
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                </div>

                <div
                  className=" p-2 my-2  bg-white w-full md:w-2/5"
                  style={{
                    minHeight: 200,
                    border: `1px solid ${hexToRGBA(
                      studio_detail?.styles?.header_color,
                      0.4
                    )}`,
                  }}
                >
                  <p className="font-bold">Cancellation Request</p>
                  {list_cancellation_request.data?.length > 0 ? (
                    list_cancellation_request.data?.map((el, idx) => (
                      <div key={idx}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Link
                            to={{
                              pathname: `/admin/student/${el?.student_id}/detail`,
                              state: { id: el?.student_id, setOrder: true },
                            }}
                            className="text-black"
                          >
                            <b>{el?.student_name}</b>
                          </Link>
                          <span
                            className={`${
                              el?.category === "class"
                                ? "text-danger"
                                : "greyblue"
                            } heading3`}
                          >
                            {el?.category?.replace(/\b\w/g, (l) =>
                              l.toUpperCase()
                            )}
                          </span>
                        </div>
                        <Row className="heading3">
                          <Col>
                            {moment(el?.cancel_date).format(
                              "dddd, DD MMM YYYY"
                            )}
                          </Col>{" "}
                          <br />
                          <span>{el?.cancellation_reason ?? "-"}</span>
                        </Row>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                  {/* {list_cancellation_request.data?.length > 0 && (
                    <Pagination
                      active={active2}
                      setActive={setActive2}
                      current_page={
                        list_cancellation_request.meta.pagination?.current_page
                      }
                      total_pages={
                        list_cancellation_request.meta.pagination?.total_pages
                      }
                    />
                  )} */}
                </div>
              </div>

              <div>
                <div
                  style={{
                    minHeight: 200,
                    border: `1px solid ${hexToRGBA(
                      studio_detail?.styles?.header_color,
                      0.4
                    )}`,
                  }}
                  className="  bg-white my-2"
                >
                  <p className="font-semibold p-3 m-0">Upcoming Class</p>
                  <div
                    style={{
                      backgroundColor: hexToRGBA(
                        studio_detail?.styles?.header_color,
                        0.1
                      ),
                    }}
                    className="p-3"
                  >
                    <Row>
                      <Col>Project name</Col>
                      <Col>Date</Col>
                      <Col>Est. Turnover</Col>
                      <Col>Registrants</Col>
                      <Col>Type</Col>
                    </Row>
                  </div>
                  {list_upcoming_class.data?.length > 0 ? (
                    list_upcoming_class.data?.map((el, idx) => (
                      <div key={idx} className="">
                        <Row className="px-3 pt-2">
                          <Col>{el?.class_name}</Col>
                          <Col>{el?.date_time_class}</Col>
                          <Col>{el?.turnover}</Col>
                          <Col>{el?.participant}</Col>
                          <Col>
                            <Tag color={el?.is_online ? "#2D9CDB" : "#27AE60"}>
                              {el?.is_online ? "Online" : "Onsite"}
                            </Tag>
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                  {/* {list_upcoming_class.data?.length > 0 && (
                    <Pagination
                      active={active1}
                      setActive={setActive1}
                      current_page={
                        list_upcoming_class.meta.pagination?.current_page
                      }
                      total_pages={
                        list_upcoming_class.meta.pagination?.total_pages
                      }
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        centered
        contentClassName="rounded-lg bg-light overflow-auto"
        show={selectedDate?.visible}
        onHide={() => setSelectedDate({ visible: false })}
      >
        <div className="py-2 text-center">
          <DateRange
            className="shadow-sm mb-3"
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <div className="d-flex justify-content-center ">
            <Button
              type="primary"
              title="Close"
              onClick={() => setSelectedDate({ visible: false })}
              style={{ backgroundColor: studio_detail?.styles?.header_color }}
            />
          </div>
        </div>
      </Modal>
    </Sidebar>
  );
};

export default DashBoard;
