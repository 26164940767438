import { Form, Input } from "antd";
import React from "react";

const InputText = ({
                       width,
                       label,
                       name,
                       type,
                       alphanumeric,
                       readOnly,
                       required,
                       className,
                       placeholder,
                       ...props
                   }) => {
    const customValidator = (_, value) => {
        const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{}|\\:;"'<>,.?/~`]+$/;
        if (!regex.test(value)) {
            return Promise.reject(
                "Only alphanumeric and special characters are allowed!"
            );
        }
        return Promise.resolve();
    };
    return (
        <Form.Item
            label={label ? `${label}:` : null}
            className={className}
            name={name}
            rules={[
                type
                    ? {
                        required: required ?? true,
                        type: type,
                        message: `please input your ${label}!`,
                    }
                    : alphanumeric
                        ? {
                            validator: customValidator,
                        }
                        : {
                            required: required ?? true,
                            message: `Please input your ${label}!`,
                        },
            ]}
            normalize={(value) =>
                value && alphanumeric ? value.toUpperCase() : value
            }
        >
            <Input
                readOnly={readOnly}
                size="large"
                style={{ width: width ?? "100%" }}
                allowClear
                placeholder={
                    placeholder ? placeholder : "Type your text here"
                }
                {...props}
            />
        </Form.Item>
    );
};

export default InputText;
