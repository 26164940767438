import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Col, Form } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import { useCookies } from "react-cookie";
import {
  requestOtp,
  setStatusOtpDefault,
} from "./../../../../redux/student/student.action";
import AtomLoading from "../../../../components/loding-indicator";
import OrganismLayout from "../../../../components/Layout/organism-layout";

const ChangeEmailOwner = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [cookies, setCookies] = useCookies(["user"]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { isLoading, statusOtp } = useSelector((state) => state.student);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const currentEmail = props?.location?.state;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const reqOtp = (e) => {
    e.preventDefault();
    dispatch(requestOtp(user_token, studio_id, email));
  };

  useEffect(() => {
    if (statusOtp === "success") {
      history.push("/admin/verify-email");
      dispatch(setStatusOtpDefault());
    }
  }, [statusOtp, dispatch, history]);

  return (
    <OrganismLayout maxWidth='42rem'>
      {isLoading ? (
        <AtomLoading />
      ) : (
        <Col className='mx-auto p-3' style={{ maxWidth: "24rem" }}>
          <h1 className='heading1 text-center mb-5'>CHANGE EMAIL</h1>
          <p className='fs-12 text-center'>
            Please input your new email address. <br />A verification message
            will be sent to new email.
          </p>
          <Form onSubmit={reqOtp}>
            <Form.Group>
              <span className='custom-form-label'>Current Email Address</span>
              <div className='d-flex justify-content-between mt-2'>
                <span>{currentEmail}</span>
              </div>
              <TextInput
                required
                className='mt-4'
                label='New Email Address'
                placeholder='Input New Email Address...'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                onKeyUp={(e) => {
                  e.code === "Enter" && reqOtp();
                }}
              />
            </Form.Group>
            <div className='d-flex justify-content-center gap-4 mt-5'>
              <Button
                onClick={() => history.push("/profile")}
                type='outline-secondary'
                title='Cancel'
              />
              <Button buttonType='submit' type='primary' title='VERIFY' />
            </div>
          </Form>
        </Col>
      )}
    </OrganismLayout>
  );
};

export default ChangeEmailOwner;
