export const GenerateErrorMessage = ({ error }) => {
  if (!error) {
    return "Unexpected error happened";
  }

  const { response } = error;
  
  if (!response || !response.data) {
    return "Unexpected error happened";
  }

  const { data } = response;
  const { message, errors } = data;

  let detail = message || "Unexpected error happened";

  if (errors) {
    const errorList = Array.isArray(errors) ? errors : Object.values(errors);
    detail = errorList.flat().join(", ");
  }

  return detail;
};
