import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button/button.component";

const ModalDelete = ({ show, setShow, subject, messages, handleDelete }) => {
  return (
    <Modal
      centered
      contentClassName="rounded-lg"
      show={show?.visible}
      onHide={() => setShow({ visible: false })}
    >
      <div className="text-center p-3 d-flex flex-column align-items-center">
        <h2 className="heading1 m-0 mb-3">Delete {subject}</h2>
        <p>Please Confirm You Want to Delete This {subject}?</p>
        <div className="b-gray mx-5 rounded mb-3">
          {show?.name || show?.last_name  || show?.email || show?.plan_type || show?.bank_name ? (
            
          <p className="m-0 py-2 px-4">
          {show?.name && <b className="">
            {show?.name} {show?.last_name}
          </b> }
         
          {show?.name && <br />}
          {show?.email || show?.plan_type || show?.bank_name ? <span className="heading3">
            {show?.email ?? show?.plan_type ?? show?.bank_name}
          </span> :null}
          
        </p>
          ) : null}
        </div>
        {messages && 
        <div
          style={{ width: "18rem" }}
          className="heading3 p-3 bg-pink b-gray rounded mb-3"
        >
          {messages}
        </div>}
        {/* <span className="mb-3 text-danger fs-12"></span> */}
        <div className="d-flex justify-content-center gap-3">
          <Button
            title="Cancel"
            type="outline-secondary"
            onClick={() => setShow({ visible: false })}
          />
          <Button title="DELETE" type="primary" onClick={handleDelete} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalDelete;
