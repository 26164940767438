import React, { useState } from "react";
import TextInput from "../../../../components/TextInput/text-input.component";
import { Select, Button, message } from "antd";
import { useSelector } from "react-redux";
// import Button from "../../../../components/Button/button.component";
import GoogleMapsReact from "../../../../components/google-maps";
import AutoCompleteMaps from "../../../../components/autocomplete";
import { toast } from "react-toastify";
import { CloseOutlined } from "@ant-design/icons";

const LocationComponent = ({
  isEdit,
  payloadEdit,
  studio_detail,
  setPage,
  addLocation,
  isView,
  isClass = false,
}) => {
  const { timezone } = useSelector((state) => state.location);
  console.log({ timezone });
  const [payload, setPayload] = useState({
    id: "",
    name: "",
    timezone: "",
    address: "",
    latitude: "-3.745",
    longitude: "-38.523",
  });
  const [listPayload, setListPayload] = useState([]);
  const [more, setMore] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState(null);
  const [latLong, setLatLong] = useState({
    lat: -6.8885864,
    lng: 107.5784873,
  });

  React.useEffect(() => {
    if (payloadEdit) {
      setPayload(payloadEdit);
      setLatLong({
        lat: payloadEdit.latitude,
        lng: payloadEdit.longitude,
      });
      setFormattedAddress(payloadEdit.address);
    }
  }, [payloadEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formattedAddress === "" || formattedAddress === null) {
      toast.error("Please input address");
      return;
    }
    if (latLong.lat === "" || latLong.lat === null) {
      toast.error("Please input latitude");
      return;
    }
    if (latLong.lng === "" || latLong.lng === null) {
      toast.error("Please input longitude");
      return;
    }
    const newPayload = {
      ...payload,
      address: formattedAddress,
      latitude: latLong.lat,
      longitude: latLong.lng,
    };
    setListPayload((prev) => [...prev, newPayload]);
    setMore((prev) => !prev);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setPayload({
      id: "",
      name: "",
      timezone: "",
      address: "",
      latitude: "-3.745",
      longitude: "-38.523",
    });
    setLatLong({
      lat: -6.8885864,
      lng: 107.5784873,
    });
    setFormattedAddress("");
    setMore(false);
  };

  const handleRemove = (index) => {
    const newList = [...listPayload];
    newList.splice(index, 1);
    setListPayload(newList);
  };

  const handleAdd = () => {
    addLocation(listPayload);
  };

  const copyText = () => {
    navigator.clipboard
      .writeText(payload.id)
      .then(() => {
        message.success("Text copied successfully!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div>
      {!isClass && (
        <p className="font-bold text-[2rem] mb-5">
          {isEdit
            ? isView
              ? "View Location"
              : "Edit Location"
            : "Add Location"}
        </p>
      )}

      {isView && (
        <TextInput
          userCanCopy
          label="Location ID"
          readOnly={true}
          value={payload?.id || ""}
          onClick={copyText}
        />
      )}

      <TextInput
        autoFocus
        label="Location Name"
        placeholder="Location Name..."
        required={true}
        readOnly={isView}
        value={payload?.name || ""}
        onChange={(e) => {
          setPayload((prev) => ({ ...prev, name: e.target.value }));
        }}
      />

      <div className="">
        <label className="custom-form-label mb-1">Time Zone</label>
        <Select
          size={"large"}
          required={true}
          placeholder={"Select Time Zone"}
          className="w-full"
          value={payload?.timezone || ""}
          disabled={isView}
          showSearch={true}
          options={timezone?.map((item) => {
            return { value: item, label: item };
          })}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onChange={(e) => {
            setPayload((prev) => ({ ...prev, timezone: e }));
          }}
        />
      </div>

      <div className="mt-2">
        <label className="custom-form-label mb-2">Location Point</label>

        <AutoCompleteMaps
          formattedAddress={formattedAddress}
          required={true}
          setFormattedAddress={setFormattedAddress}
          setLatLong={setLatLong}
        />
      </div>
      <div>
        <GoogleMapsReact latLong={latLong} />
      </div>

      {!isView && (
        <div
          className={"w-full flex flex-row justify-center items-center my-3"}
        >
          {more ? (
            <Button
              type="outline"
              style={{
                border: `1px solid ${studio_detail?.styles?.header_color}`,
                color: studio_detail?.styles?.header_color,
                borderRadius: 0,
                paddingLeft: "4%",
                paddingRight: "4%",
                width: "190px",
              }}
              onClick={handleReset}
            >
              +Add more location
            </Button>
          ) : (
            <Button
              type="outline"
              size="large"
              className="w-4/6 rounded-sm text-white font-bold"
              style={{
                backgroundColor: studio_detail?.styles?.header_color,
              }}
              onClick={handleSubmit}
            >
              Add
            </Button>
          )}
        </div>
      )}

      {listPayload?.length > 0 && (
        <div className="mt-2">
          <label className="custom-form-label mb-1">Location chosen</label>
          {listPayload?.map((item, index) => {
            return (
              <div
                className={
                  "flex flex-row justify-between items-center cursor-pointer "
                }
                key={index}
              >
                <p>{item?.name}</p>
                <p className={"max-w-[50%]"}>{item?.address}</p>
                <CloseOutlined
                  className={`hover:text-[${studio_detail?.styles?.header_color}]`}
                  onClick={(e) => handleRemove(index)}
                />
              </div>
            );
          })}
        </div>
      )}

      <div className="my-5 d-flex justify-content-between gap-3">
        <Button
          type="default"
          size="large"
          style={{
            border: `1px solid ${studio_detail?.styles?.header_color}`,
            color: studio_detail?.styles?.header_color,
          }}
          onClick={() => {
            setPage("read");
          }}
          className="rounded-sm font-bold"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          // loading={listPayload?.length === 0 && !isView}
          onClick={() => handleAdd()}
          style={{ backgroundColor: studio_detail?.styles?.header_color }}
          className="rounded-sm font-bold"
        >
          {isEdit ? (isView ? "Edit" : "Save") : "Add"}
        </Button>
      </div>
    </div>
  );
};

export default LocationComponent;
