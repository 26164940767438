import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Input, Radio, Select, Checkbox, Modal } from "antd";
import "./owner.styles.scss";

import Button from "../../components/Button/button.component";

import { setclassNameSetup } from "../../redux/owner/owner.action";
import { getLocation } from "../../redux/location/location.action";
import { useCookies } from "react-cookie";
import AddLocations from "./add-locations-component";
import hexToRGBA from "../../helper/hexToRbga";
import { capitalize } from "../../helper/truncateString";

const ClassNameSetup = ({ nextStepSchedule, setSelectedStep, colorTheme }) => {
  const dispatch = useDispatch();

  const { addClass, addLocationStatus } = useSelector((state) => state.owner);
  const { location } = useSelector((state) => state.location);

  const [className, setClassName] = useState("");
  const [isOnline, setIsOnline] = useState(0);
  const [locationId, setLocationId] = useState("");
  const [room, setRoom] = useState(null);
  const [slots, setSlots] = useState(null);
  const [classLink, setClassLink] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [aboutClass, setAboutClass] = useState("");
  const [notes, setNotes] = useState("");
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState("11");
  const [credits, setCredits] = useState(0);
  const [selectedPlans, setSelectedPlans] = useState("all_plans");
  const [directPayment, setDirectPayment] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddLocation, setIsAddLocation] = useState(false);
  const [Category, setCategory] = useState([]);
  const [addCategory, setAddCategory] = useState("");
  const [classCategories, setClassCategories] = useState([]);

  const { user, loginFromStudio } = useSelector((state) => state.user);
  const { isRegisterStudent } = useSelector((state) => state.studio);

  const [cookies] = useCookies(["user"]);

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies?.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies?.user?.studio_id;

  useEffect(() => {
    if (addClass.class_name !== "") {
      nextStepSchedule();
    }
  }, [addClass.class_name]);

  useEffect(() => {
    dispatch(getLocation("?limit=0", user_token, studio_id));
  }, [addLocationStatus]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAddCategory = () => {
    setCategory([
      ...Category,
      { label: capitalize(addCategory), value: addCategory },
    ]);
    setAddCategory("");
    setIsModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = directPayment
      ? {
          // type: activityType,
          class_name: className,
          is_online: isOnline,
          room: room,
          slots: slots,
          class_link: classLink,
          additional_information: additionalInformation,
          notes: notes,
          direct_payment_allowed: "1",
          class_price: price.toString().replace(/\D/g, ""),
          class_tax: tax,
          credits: credits,
          all_plans_allowed: "1",
          // plan_ids: planIds,
          // plans_data: plansData,
          class_categories: classCategories,
          about_the_class: aboutClass,
        }
      : {
          class_name: className,
          is_online: isOnline,
          room: room,
          slots: slots,
          class_link: classLink,
          additional_information: additionalInformation,
          notes: notes,
          direct_payment_allowed: "0",
          all_plans_allowed: "1",
          credits: credits,
          // plan_ids: planIds,
          // plans_data: plansData,
          class_categories: classCategories,
          about_the_class: aboutClass,
        };

    params.location_ids = locationId;

    dispatch(setclassNameSetup(params));
  };

  return isAddLocation ? (
    <AddLocations
      isAddLocation={isAddLocation}
      colorTheme={colorTheme}
      setIsAddLocation={setIsAddLocation}
    />
  ) : (
    <div className="flex justify-center items-center p-4 flex-col gap-3">
      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label
          htmlFor="class_type"
          className="text-slate-900 text-sm font-bold"
        >
          Class type
        </label>

        <Select
          defaultValue={0}
          onChange={(value) => {
            setIsOnline(value);
          }}
          options={[
            {
              value: 0,
              label: "Onsite",
            },
            {
              value: 1,
              label: "Online",
            },
          ]}
          id="class_type"
          className="h-10"
        />
      </div>

      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label
          htmlFor="class_name"
          className="text-slate-900 text-sm font-bold"
        >
          Class Name
        </label>

        <Input
          placeholder="Healty and young yoga"
          onChange={(event) => {
            setClassName(event.target.value);
          }}
          id="class_name"
          className="h-10"
        />
      </div>

      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label
          htmlFor="available_slots"
          className="text-slate-900 text-sm font-bold"
        >
          Available Slots
        </label>

        <Input
          value={slots}
          onChange={(event) => {
            setSlots(event.target.value);
          }}
          type="number"
          min={1}
          placeholder="ex. 11"
          id="available_slots"
          className="h-10"
        />
      </div>

      {isOnline === 1 && (
        <div className="flex flex-col  w-full gap-2 md:w-[568px]">
          <label
            htmlFor="class_link"
            className="text-slate-900 text-sm font-bold"
          >
            Class Link
          </label>

          <Input
            required
            placeholder="https://zoom.us/yogayoung"
            onChange={(event) => {
              setClassLink(event.target.value);
            }}
            id="class_link"
            className="h-10"
          />
        </div>
      )}

      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label
          htmlFor="class_category"
          className="text-slate-900 text-sm font-bold"
        >
          Class Category
        </label>

        <Select
          mode="multiple"
          placeholder="Choose Category"
          onChange={(value) => {
            setClassCategories(value);
          }}
          options={Category}
          id="class_category"
          className="h-10"
          dropdownRender={(menu) => (
            <>
              {menu}
              <div
                onClick={showModal}
                className="w-full flex px-[15px] py-2 items-center text-neutral-800 text-sm font-semibold cursor-pointer hover:bg-slate-50"
              >
                Add a Category..
              </div>
            </>
          )}
        />
      </div>

      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label htmlFor="credits" className="text-slate-900 text-sm font-bold">
          Credits
        </label>

        <Input
          min={1}
          type="number"
          placeholder="Input credits..."
          onChange={(event) => {
            setCredits(event.target.value);
          }}
          id="credits"
          className="h-10"
        />
      </div>

      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label htmlFor="location" className="text-slate-900 text-sm font-bold">
          Location
        </label>

        <div
          className={`py-2 px-4`}
          style={{
            backgroundColor: hexToRGBA(colorTheme || "#E26851", 0.1),
            border: `1px solid ${hexToRGBA(colorTheme, 0.2)}`,
          }}
        >
          <span className="font-medium">
            Select a location to set as your timezone reference before
            enrolling.
          </span>
        </div>

        <Select
          id="location"
          mode="multiple"
          placeholder="Jakarta"
          options={location?.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          onChange={(e) => {
            setLocationId(e);
          }}
          className="h-10"
          dropdownRender={(menu) => (
            <>
              {menu}
              <div
                onClick={() => setIsAddLocation(true)}
                className="w-full flex px-[15px] py-2 items-center text-neutral-800 text-sm font-semibold cursor-pointer hover:bg-slate-50"
              >
                Add a Location
              </div>
            </>
          )}
        />
      </div>

      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label htmlFor="room" className="text-slate-900 text-sm font-bold">
          Room
        </label>

        <Input
          onChange={(event) => {
            setRoom(event.target.value);
          }}
          placeholder="ex. 2A"
          id="room"
          className="h-10"
        />
      </div>

      {isOnline ? (
        <>
          <div className="flex flex-col  w-full gap-2 md:w-[568px]">
            <label
              htmlFor="additional_information"
              className="text-slate-900 text-sm font-bold"
            >
              Additional Information
            </label>

            <Input.TextArea
              rows={5}
              placeholder="Meeting ID : 1233434 Password : yogayoung123"
              onChange={(event) => {
                setAdditionalInformation(event.target.value);
              }}
              id="additional_information"
              className="h-10"
            />
          </div>
        </>
      ) : null}

      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label
          htmlFor="about_class"
          className="text-slate-900 text-sm font-bold"
        >
          About The Class
        </label>

        <Input.TextArea
          rows={5}
          id="about_class"
          placeholder="Type about the class here..."
          onChange={(e) => setAboutClass(e.target.value)}
        />
      </div>

      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label htmlFor="notes" className="text-slate-900 text-sm font-bold">
          Notes
        </label>

        <Input.TextArea
          rows={5}
          id="notes"
          placeholder="add notes..."
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>

      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label className="text-slate-900 text-sm font-bold">
          Eligible Plans
        </label>

        <Radio
          onChange={(e) => setSelectedPlans(e.target.value)}
          checked={selectedPlans === "all_plans"}
          value={"all_plans"}
        >
          All Plans
          <br />
          <span className="text-xs">
            This class will have all plan options available
          </span>
        </Radio>
        <Checkbox
          checked={directPayment}
          onChange={(event) => {
            setDirectPayment(event.target.checked);
          }}
        >
          Direct Payment <br />
          <span className="text-xs">Students can use direct payment</span>
        </Checkbox>
        {directPayment ? (
          <div className="flex flex-row gap-3">
            <div className="flex flex-col md:w-[568px] w-full gap-2">
              <label
                htmlFor="room"
                className="text-slate-900 text-sm font-bold"
              >
                Price
              </label>

              <Input
                placeholder="Input Price..."
                value={price}
                onChange={(event) => {
                  setPrice(event.target.value);
                }}
                id="price"
                className="h-10"
              />
            </div>
            <div className="flex flex-col md:w-[568px] w-full gap-2">
              <label
                htmlFor="room"
                className="text-slate-900 text-sm font-bold"
              >
                Tax (%)
              </label>

              <Input
                type="number"
                placeholder="Input Tax..."
                value={tax}
                onChange={(event) => {
                  setTax(event.target.value);
                }}
                id="tax"
                className="h-10"
              />
            </div>
          </div>
        ) : null}
      </div>

      <div className="md:w-[568px] w-full">
        <Button
          buttonType="submit"
          type="primary"
          title="CONTINUE"
          style={{ width: "100%", backgroundColor: colorTheme }}
          onClick={handleSubmit}
        />

        <Button
          buttonType="button"
          type="secondary"
          title="Skip for now"
          onClick={() => {
            setSelectedStep(5);
          }}
          style={{ width: "100%", backgroundColor: "#fff", color: colorTheme }}
        />
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={668}
      >
        <div className="flex-col justify-start items-center gap-10 flex">
          <div className="h-[87px] flex-col justify-start items-center gap-4 flex">
            <div className="text-center text-slate-900 text-sm font-bold font-['Source Sans Pro'] leading-normal">
              Fill out the name of the New Category
            </div>
            {/* <div className='w-[568px] h-[47px] p-2 rounded border border-neutral-200 justify-between items-center inline-flex'>
              <div className="text-slate-900 text-sm font-normal font-['Source Sans Pro'] leading-normal">
                Wellness
              </div>
            </div> */}
            <Input
              className="w-[568px]  p-2 rounded border border-neutral-200"
              placeholder="Input Category Name..."
              value={addCategory}
              onChange={(event) => {
                setAddCategory(event.target.value);
              }}
            />
          </div>

          <div className="flex justify-center">
            <Button
              type="primary"
              title="Add Category"
              style={{ width: "100%", backgroundColor: colorTheme }}
              className="px-4"
              onClick={handleAddCategory}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ClassNameSetup;
