import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Row, Col, Form } from "react-bootstrap";
import { HiClock, HiUser } from "react-icons/hi";
import Button from "../../../../components/Button/button.component";
import "./../students.styles.scss";

import {
  getDetailOfferingClasses,
  getMyActivePlan,
} from "./../../../../redux/student/student.action";

import AtomLoading from "../../../../components/loding-indicator";
import AtomNumberFormat from "../../../../components/number-format";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import { toast } from "react-toastify";
import { sendGetRequest, sendPostRequest } from "../../../../services/students";

const PaymentClass = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio } = useSelector((state) => state.user);
  const { isLoadingClasses, class_detail, active_plan } = useSelector(
    (state) => state.student
  );

  const id = props.location.state?.id;
  const student_id = props.location.state?.student_id;
  const fromOwner = props.location.state?.fromOwner;
  const classDetail = class_detail[0]?.class_schedules[0];
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const [selectPlan, setSelectPlan] = useState(null);
  const [studentPlan, setStudentPlan] = useState(fromOwner ? [] : active_plan);

  const addClassForStudent = async () => {
    let params = {
      category: "class",
      class_schedule_id: classDetail?.class_schedule_id,
      payment_method: "offline",
      payment_type: "cash",
      student_id: student_id,
    };

    try {
      await sendPostRequest(user_token, studio_id, "orders", params);
      history.push("/online-payment-success");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const submitPayment = () => {
    if (!selectPlan) {
      toast.error("Please select payment!");
      return;
    }
    if (selectPlan.split(",")[2] === "plan") {
      history.push({
        pathname: "/confirm-class",
        state: {
          selected_plan: selectPlan,
          fromOwner: fromOwner,
          student_id: student_id,
        },
      });
    } else if (fromOwner) {
      addClassForStudent();
    } else {
      history.push({
        pathname: "/choose-payment",
        state: {
          buy_class: true,
          class_schedule_id: classDetail?.class_schedule_id,
        },
      });
    }
  };

  const getStudentPlan = useCallback(async () => {
    try {
      const { data } = await sendGetRequest(
        user_token,
        studio_id,
        `students/${student_id}/plan`
      );
      setStudentPlan(data);
    } catch (error) {
      console.log(error);
    }
  }, [student_id, studio_id, user_token]);

  useEffect(() => {
    dispatch(getDetailOfferingClasses(user_token, studio_id, id));
    fromOwner
      ? getStudentPlan()
      : dispatch(getMyActivePlan(user_token, studio_id));
  }, [dispatch, fromOwner, getStudentPlan, id, studio_id, user_token]);

  return (
    <OrganismLayout maxWidth="42rem">
      {isLoadingClasses ? (
        <AtomLoading />
      ) : (
        <Col className="mx-auto p-md-3" style={{ maxWidth: "32rem" }}>
          <div className="text-center">
            <h1 className="heading1 mb-5">Payment</h1>
            {false && (
              <p className="text-danger">
                Youd don't have valid membership plan for this class
              </p>
            )}
          </div>
          <div className="mb-3 b-gray rounded-10 p-3">
            <div className="fs-12 d-flex justify-content-between mb-2">
              <span>{classDetail?.is_online === 1 ? "Online" : "Offline"}</span>
              <span>
                <HiClock className="fs-5 text-pink" />{" "}
                {classDetail?.class_start_time} - {classDetail?.class_end_time}
              </span>
              <span>
                <HiUser className="fs-5 text-pink" />{" "}
                {classDetail?.teachers.join(",")}
              </span>
              <span></span>
            </div>
            <span className="fw-bold">{classDetail?.class_name}</span>
            <hr />
            <Row>
              <Col className="fw-bold">
                <p>Schedule</p>
              </Col>
              <Col className="fs-12">
                {class_detail[0]?.class_date}, {classDetail?.class_start_time} -{" "}
                {classDetail?.class_end_time}
              </Col>
            </Row>
            <Row>
              <Col className="fw-bold">
                <p>Slots</p>
              </Col>
              <Col className="fs-12">{classDetail?.slots} Remaining</Col>
            </Row>
            <Row>
              <Col className="fw-bold">
                <p>Credits</p>
              </Col>
              <Col className="fs-12">{classDetail?.credits}</Col>
            </Row>
            <Row>
              <Col className="fw-bold">
                <p>Price</p>
              </Col>
              <Col className="fs-12">
                <AtomNumberFormat
                  prefix="IDR "
                  value={classDetail?.total_amount ?? "-"}
                />
              </Col>
            </Row>
            <Row>
              <Col className="fw-bold">
                <p>Notes</p>
              </Col>
              <Col className="fs-12">{classDetail?.notes ?? "-"}</Col>
            </Row>
          </div>
          {false ? (
            <h2 className="text-center">
              This session for <strong>10 USD</strong>
            </h2>
          ) : (
            <Form>
              <p>How do you pay for it ?</p>

              {studentPlan.length > 0 ? (
                studentPlan
                  .filter(
                    (item) =>
                      (item.plan?.plan_type === "membership" &&
                        item?.current_usage_limit >= 1) ||
                      (item.plan?.plan_type === "class pass" &&
                        item?.current_credits >= classDetail?.credits)
                  )
                  .map((el, idx) => (
                    <Form.Group key={idx} className="fs-12 fw-bold">
                      <Form.Check
                        id={el.plan?.plan_name}
                        type="radio"
                        value={el.plan?.plan_name
                          .concat(",", el._id)
                          .concat(",", el?.category)}
                        label={el.plan?.plan_name}
                        name={el.plan?.plan_name}
                        onChange={(e) => setSelectPlan(e.target.value)}
                        checked={
                          selectPlan?.split(",")[0] === el.plan?.plan_name
                        }
                      />
                    </Form.Group>
                  ))
              ) : (
                <p className="text-gray">
                  Currently there's no direct payment method,try to buy a plan.
                </p>
              )}

              {classDetail?.direct_payment_allowed ? (
                <Form.Group className="fs-12 fw-bold">
                  <Form.Check
                    id="option3"
                    type="radio"
                    value={`Buy this class for IDR ${classDetail?.total_amount}`}
                    label={`Buy this class for IDR ${classDetail?.total_amount
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
                    name="option3"
                    onChange={(e) => setSelectPlan(e.target.value)}
                    checked={
                      selectPlan ===
                      `Buy this class for IDR ${classDetail?.total_amount}`
                    }
                  />
                </Form.Group>
              ) : null}

              <div className="d-flex flex-column align-items-center mt-4">
                <Button
                  type="primary"
                  title="CONFIRM"
                  onClick={submitPayment}
                />
                <div
                  onClick={() => history.goBack()}
                  className="fw-bolder greyblue mt-4 pointer custom-hover"
                >
                  Cancel
                </div>
              </div>
            </Form>
          )}
        </Col>
      )}
    </OrganismLayout>
  );
};

export default PaymentClass;
