import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Form } from "react-bootstrap";
import TextInput from "../../../../components/TextInput/text-input.component";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import {
  setStatusDefault,
  addStudentNotes,
  getClass,
  updateStudentNotes,
} from "../../../../redux/owner/owner.action";
import AtomLoading from "../../../../components/loding-indicator";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import hexToRGBA from "../../../../helper/hexToRbga";

export const AddNotes = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const student_id = props.location.state?.id;
  const notes_id = props.location.state?.notes_id;
  const class_id = props.location.state?.class_id;
  const state_title = props.location.state?.title;
  const state_description = props.location.state?.description;

  const isCreating = window.location.pathname.includes("add") ? true : false;

  const [cookies, setCookies] = useCookies(["user"]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [classId, setClassId] = useState("");

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { owner_classes, isLoadingStudent, addNoteStatus } = useSelector(
    (state) => state.owner
  );
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const list_class = owner_classes.data?.length > 0 ? owner_classes.data : [];
  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      title: title,
      description: description,
      class_id: isCreating ? classId.split(",")[0] : class_id,
    };

    isCreating
      ? dispatch(addStudentNotes(params, user_token, studio_id, student_id))
      : dispatch(updateStudentNotes(params, user_token, studio_id, notes_id));
  };

  useEffect(() => {
    dispatch(getClass(user_token, studio_id));
  }, []);

  useEffect(() => {
    if (!isCreating) {
      setTitle(state_title);
      setDescription(state_description);
      setClassId(class_id);
    }
  }, [!isCreating]);

  useEffect(() => {
    if (addNoteStatus === "success") {
      history.goBack();
      dispatch(setStatusDefault());
    }
  }, [addNoteStatus]);

  const classes = [
    { label: "Choose Your Class", value: "", selected: true },
    ...list_class,
  ];

  return (
    <OrganismLayout title="Add Notes" maxWidth="32rem">
      {false ? (
        <AtomLoading />
      ) : (
        <>
          <h3 className="font-bold mb-3">Add Notes</h3>
          <Form onSubmit={handleSubmit} className="w-full md:w-[568px]">
            <Form.Group style={{ marginBottom: 10 }}>
              <div className={`form-group`}>
                {/* <label className="custom-form-label">{label}</label> */}
                <select
                  onChange={(e) => setClassId(e.target.value)}
                  className="select-input p-2"
                  required
                >
                  {classes
                    ? classes.map((element, idx) => (
                        <option
                          key={idx}
                          selected={
                            isCreating
                              ? element.selected
                              : element.id === class_id
                          }
                          value={
                            element.id
                              ? element?.id.concat(",", element?.bank_name)
                              : element.value
                          }
                        >
                          {element.label ?? element.class_name}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </Form.Group>

            <Form.Group style={{ marginBottom: 10 }}>
              <TextInput
                required
                autoFocus
                label="Title"
                placeholder="Input Title..."
                value={title}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
              />
            </Form.Group>

            <Form.Group style={{ marginBottom: 10 }}>
              <label htmlFor="desc" className="custom-form-label">
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                required
                className="text-input"
                name="desc"
                id="desc"
                cols="30"
                rows="10"
                placeholder="Input Description..."
                value={description}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />
            </Form.Group>

            <div className="mt-3 flex justify-between">
              <Button
                buttonType="button"
                type="outline-secondary"
                title="Cancel"
                onClick={() => {
                  history.goBack();
                }}
                style={{
                  width: "25%",
                  backgroundColor: "#fff",
                  color: colorTheme,
                  borderRadius: 0,
                  border: `1px solid ${hexToRGBA(colorTheme, 0.2)}`,
                }}
              />
              <Button
                buttonType="submit"
                type="primary"
                title={isCreating ? "Add" : "Update"}
                style={{
                  width: "25%",
                  backgroundColor: colorTheme,
                  borderRadius: 0,
                }}
                isLoading={isLoadingStudent}
              />
            </div>
          </Form>
        </>
      )}
    </OrganismLayout>
  );
};
