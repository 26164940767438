import React, { useEffect } from "react";
import { Checkbox, Col, ConfigProvider, Form, Row } from "antd";
import TimePickerRange from "./TimePickerRange";
import DelForm from "../assets/images/deleteForm.svg";
import { PlusOutlined } from "@ant-design/icons";

export default function ScheduleFormlist({ name, colorTheme, form }) {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => (
              <Form.Item key={field.key}>
                <Row justify="space-between">
                  <Col span={4}>
                    {index === 0 ? (
                      <span className="flex items-center">
                        <Form.Item
                          name={[field.name, "day"]}
                          valuePropName="checked"
                          noStyle
                          initialValue={form.getFieldValue([`${name}Checked`])}
                        >
                          <Checkbox className="mr-2" />
                        </Form.Item>
                        {name}
                      </span>
                    ) : null}
                  </Col>

                  <Col>
                    <Form.Item className="w-full m-0" {...field}>
                      <TimePickerRange
                        start_time={form.getFieldValue([
                          name,
                          field.name,
                          "startTime",
                        ])}
                        end_time={form.getFieldValue([
                          name,
                          field.name,
                          "endTime",
                        ])}
                      />
                    </Form.Item>
                  </Col>

                  <Col>
                    {(fields.length > 1) & (index !== 0) ? (
                      <img
                        alt=""
                        src={DelForm}
                        className="cursor-pointer z-50"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}

                    {index === 0 && (
                      <div
                        style={{ backgroundColor: colorTheme }}
                        className="rounded-full h-10 w-10 flex justify-center items-center cursor-pointer"
                        onClick={() => add()}
                      >
                        <PlusOutlined className="text-white text-lg" />
                      </div>
                    )}
                  </Col>
                </Row>
              </Form.Item>
            ))}
          </>
        );
      }}
    </Form.List>
  );
}
