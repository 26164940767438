import { Row, Col, Navbar, Nav, Container } from "react-bootstrap";
import { useCookies } from "react-cookie";

import "./header-owner.styles.scss";
import { logout, setMenuActive } from "../../redux/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import AtomLoading from "../loding-indicator";
import axios from "axios";
import { useEffect } from "react";

const HeaderOwner = ({ title }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [cookies, setCookies, removeCookies] = useCookies([
    "user",
    "subdomain",
  ]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { studio_detail, user, loginFromStudio, active_menu } = useSelector(
    (state) => state.user,
  );

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;

  const menu = [
    { name: "Dashboard", href: "/" },
    { name: "Student", href: "/admin/students" },
    { name: "Classes", href: "/admin/classes" },
    { name: "Teachers", href: "/admin/teachers" },
    { name: "Profile", href: "/admin/profile" },
    { name: "Settings", href: "/admin/settings" },
  ];

  const handleActive = (params) => {
    dispatch(setMenuActive(params));
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        handleLogout();
      }
      return error;
    },
  );

  const handleLogout = () => {
    if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
    }

    dispatch(logout(user_token));

    history.push("/admin/login");

  }

  return studio_detail ? (
    <div  style={{ height: "100%", maxWidth: "324px", minHeight: "100vh" , position: "fixed" }}>
      <div className='p-0 d-flex flex-column'>
        <div
          className='d-flex flex-column flex-shrink-0 p-3 h-screen  min-w-[301px]'
          style={{
            backgroundColor: studio_detail?.styles?.header_color,
            maxWidth: "301px",
          }}
        >
          <div className='flex flex-row items-center gap-1  py-2 px-2'>
            <a
              href='/admin/dashboard'
              className='flex flex-row items-center  link-dark text-decoration-none'
            >
              <img
                alt='studio-logo'
                src={
                  studio_detail?.logo_link ??
                  user?.studio_logo ??
                  cookies.user?.studio_logo
                }
                className='rounded-full w-[64px] h-[64px] mr-2'
              />
            </a>
            <div className="className='m-0 font-black text-3xl text-white">
             
                {user?.studio_name ?? cookies.user?.studio_name}
              
            </div>
          </div>

          <Nav className='flex-column'>
            {menu.map((el, idx) => (
              <div className='flex flex-row items-center px-4'>
                <div
                  className={`${
                    el.name === active_menu ? "dot-active" : "dot"
                  } rounded-circle`}
                ></div>
                <Nav.Link
                  onClick={() => handleActive(el.name)}
                  key={idx}
                  as={Link}
                  to={el.href}
                  className={`${el.name === active_menu && "active"} font-semibold text-white`}
                >
                  {el.name}
                </Nav.Link>
              </div>
            ))}
          </Nav>
        </div>


      </div>
    </div>
  ) : (
    <AtomLoading />
  );
};

export default HeaderOwner;