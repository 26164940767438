import React, { useState, useCallback, useEffect } from "react";
import { Form, Input, Select, Space } from "antd";
import { toast } from "react-toastify";
import hexToRGBA from "../../../../helper/hexToRbga";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";
import {
  getProfile,
  updateProfile,
  requestOtp,
  setStatusOtpDefault,
  verifyOtpChangeEmail,
  setStatusVerifyEmailDefault,
  changeEmail,
} from "./../../../../redux/student/student.action";
import UploadPicture from "../../../../components/uploadPicture";
const ModalEditProfile = ({ themeColor, isOpen, page, setPage, onCancel }) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["user"]);
  const [previewUrl, setPrevieUrl] = useState("");
  const [profileUser, setProfile] = useState(null);
  const [valueUser, setValueUser] = useState({});
  const { isRegisterStudent } = useSelector((state) => state.studio);
  // const [page, setPage] = useState("editProfile");
  const [otp, setOtp] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const { profile, isLoading, statusOtp, statusVerifyEmail } = useSelector(
    (state) => state.student
  );
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const submitProfile = (values) => {
    let params = { ...values };
    if (profileUser) params.profile_picture = profileUser;

    dispatch(updateProfile(user_token, studio_id, params));

    setTimeout(() => {
      dispatch(getProfile(user_token, studio_id));
    }, 1000);
  };

  const handleVerfyEmail = () => {
    const params = {
      email: profile.email,
      otp: Number(otp),
    };

    if (newEmail) {
      const newEmailVerif = {
        email: newEmail,
        otp: Number(otp),
      };
      dispatch(verifyOtpChangeEmail(user_token, studio_id, newEmailVerif));
      dispatch(changeEmail(user_token, studio_id, newEmail));
    } else {
      dispatch(verifyOtpChangeEmail(user_token, studio_id, params));
    }
  };

  const handleChangeEmail = () => {
    dispatch(requestOtp(user_token, studio_id, newEmail));
  };
  const resendCode = () => {
    dispatch(requestOtp(user_token, studio_id, newEmail || profile.email));
  };

  useEffect(() => {
    dispatch(getProfile(user_token, studio_id));
  }, [dispatch, studio_id, user_token]);

  useEffect(() => {
    if (page === "veryfyEmail") {
      if (newEmail) {
        dispatch(requestOtp(user_token, studio_id, newEmail));
      } else {
        dispatch(requestOtp(user_token, studio_id, profile.email));
      }
    }
  }, [page]);

  useEffect(() => {
    if (statusVerifyEmail === "success" && !newEmail) {
      setPage("changeEmail");
      dispatch(setStatusVerifyEmailDefault());
    }
    console.log(statusVerifyEmail);
  }, [statusVerifyEmail]);

  useEffect(() => {
    console.log(isOpen);
  }, [isOpen]);

  return (
    <div className="flex flex-col items-center">
      {page === "editProfile" && (
        <>
          <div className="font-semibold">Edit Profile</div>
          <Form
            initialValues={profile}
            size="large"
            className="w-full"
            layout="vertical"
            onFinish={submitProfile}
          >
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your First Name",
                },
              ]}
            >
              <Input className="h-10" placeholder="Input Your First Name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name",
                },
              ]}
            >
              <Input placeholder="Input Your Last Name" className="h-10" />
            </Form.Item>
            <div className="flex flex-col gap-3 mb-4">
              <div className="flex justify-between">
                <span className="font-bold">Email Address</span>
                <u
                  className="greyblue pointer custom-hover"
                  onClick={() => setPage("veryfyEmail")}
                >
                  Change
                </u>
              </div>
              <Input value={profile.email} disabled />
            </div>
            <Form.Item label="Phone Number" name="phone_number">
              <Input type="number" placeholder="Phone Number" />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please input your Gender",
                },
              ]}
            >
              <Select
                options={[
                  { value: "male", label: "Male" },
                  { value: "female", label: "Female" },
                ]}
                placeholder="Select Your Gender"
                className="h-10"
              />
            </Form.Item>

            <UploadPicture
              valuePicture={profile?.profile_picture}
              fileData={(file) => setProfile(file)}
              themeColor={themeColor}
            />

            <div className="mt-5 flex justify-between">
              <Button
                onClick={onCancel}
                type="outline-secondary"
                title="Cancel"
                style={{
                  border: `1px solid ${themeColor}`,
                  color: themeColor,
                  borderRadius: 0,
                }}
              />
              <Button
                style={{
                  backgroundColor: themeColor,
                  borderRadius: 0,
                }}
                buttonType="submit"
                type="primary"
                title="UPDATE"
              />
            </div>
          </Form>
        </>
      )}
      {page === "veryfyEmail" && (
        <>
          <div className="flex-col justify-start items-center gap-10 flex">
            <div className="text-slate-900 text-[35px] font-bold font-['Source Sans Pro']">
              Input OTP
            </div>
            <div className="flex-col justify-center items-center gap-10 flex">
              <div className="text-center text-zinc-800 text-xl font-semibold font-['Source Sans Pro']">
                We emailed you 6 (six) digits code to {profile.email}
                <br />
                Enter the code below to confirm your email address
              </div>
              <Input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-[470px] h-24 p-2 rounded border border-neutral-200 justify-center items-center text-[40px] text-center font-bold"
              />

              <div className="text-center text-zinc-800 text-base font-normal font-['Source Sans Pro'] leading-normal">
                The verification code above is only valid for 30 minutes.
                <br />
                Please don't share this code with anyone, including those
                <br />
                on behalf of Studio Name or Gigsclassroom
              </div>
            </div>
            <div className="text-center">
              <span className="text-zinc-800 text-xl font-normal font-['Source Sans Pro'] leading-normal">
                Didn't receive the code?{" "}
              </span>
              <span
                onClick={resendCode}
                className="text-blue-800 text-xl font-bold font-['Source Sans Pro'] leading-normal"
              >
                Resend Code
              </span>
            </div>
          </div>
          <div
            onClick={handleVerfyEmail}
            className="w-[260px] h-[50px] px-[100px] py-2.5 bg-blue-800 justify-center items-center gap-2.5 inline-flex my-4"
          >
            <div className="text-white text-2xl font-semibold font-['Source Sans Pro']">
              Verify
            </div>
          </div>
        </>
      )}
      {page === "changeEmail" && (
        <Space
          size={"large"}
          direction="vertical"
          className="flex justify-center items-center p-4"
        >
          <div className="text-slate-900 text-[35px] font-bold font-['Source Sans Pro']">
            Input New Email Address
          </div>
          <Input
            placeholder="New Email"
            size="large"
            className="w-full text-center"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <div
            onClick={() => setPage("veryfyEmail")}
            className="px-[100px] py-2.5 bg-blue-800 justify-center items-center gap-2.5 inline-flex"
          >
            <div className="text-white text-2xl font-semibold font-['Source Sans Pro']">
              Submit
            </div>
          </div>
        </Space>
      )}
    </div>
  );
};

export default ModalEditProfile;
