import React, {useState} from "react";
import {GoogleMap, MarkerF, useJsApiLoader,} from "@react-google-maps/api";

const containerStyle = {
    width: "auto",
    height: "230px",
    borderRadius: "10px",
};

const GoogleMaps = ({
                        setPickAddress,
                        draggable,
                        latLong,
                        setLatLong,
                        withPickupPin,
                        isPreview,
                    }) => {
    const [libraries] = useState(["places"]);

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const [map, setMap] = useState(null);

    const defaultLocation = latLong
        ? latLong
        : { lat: -6.8885864, lng: 107.5784873 };

    // Reverse Geocode
    const onDragPickup = (e) => {
        /* eslint-disable */
        const geocoder = new google.maps.Geocoder();

        const latlng = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };
        setLatLong(latlng);

        geocoder
            .geocode({ location: latlng })
            .then((response) => {
                setPickAddress(response.results[0].formatted_address);
            })
            .catch((e) => window.alert("Geocoder failed due to: " + e));
    };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={defaultLocation}
            zoom={15}
            options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
            }}
            onLoad={(map) => setMap(map)}
        >
            {draggable ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `440px`,
                        height: `40px`,
                        padding: `5px 24px 5px 5px`,
                        borderRadius: `100px`,
                        boxShadow: `0px 1px 2px rgba(68, 68, 79, 0.1) `,
                        position: "absolute",
                        left: "calc(50% - 440px/2)",
                        top: 16,
                        background: " #FFFFFF",
                        fontSize: "12px",
                    }}
                >
                    <b>
                        Geser pin lokasi pickup/drop untuk memposisikan lokasi yang tepat
                    </b>
                </div>
            ) : null}

            <MarkerF
                position={defaultLocation}
                draggable={false}
                onDragEnd={onDragPickup}
            />
        </GoogleMap>
    ) : null;
};

export default GoogleMaps;
