import axiosInstance from "../axiosInstance";

export const getStudioInfo = async (subdomain) => {
  const config = {
    headers: {
      "content-type": "application/json",
    },
  };

  const res = await axiosInstance.get(`/studios/${subdomain}/info`, config);
  return res;
};

export const changeHeader = async (params, idStudio, token_jwt) => {
  const formData = new FormData();
  if (params.header_image) formData.append("header_image", params.header_image);
  if (params.logo) formData.append("logo", params.logo);
  formData.append("styles[header_color]", params.backgroundColor);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token_jwt}`,
    },
  };

  await axiosInstance.post(`/studios/${idStudio}/styles`, formData, config);
};
