import React from "react";
import { FaEllipsisH } from "react-icons/fa";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

const DropdownCard = ({
  idx,
  index,
  show,
  setShow,
  setIndex,
  data,
  className,
  positionTop,
  positionBottom,
  positionRight,
  positionLeft,
  width,
  dashboard,
}) => {
  const styles = {
    top: positionTop && 0,
    bottom: positionBottom,
    right: positionRight && 0,
    left: positionLeft,
  };
  return (
    <>
      {dashboard ? (
        <div onClick={() => setShow((show) => !show)}>
          <FaEllipsisH className="custom-hover pointer" />
        </div>
      ) : (
        <button
          onClick={() => {
            setShow((show) => !show);
            setIndex(idx);
          }}
          className="option-button d-flex align-items-center justify-content-center"
        >
          <HiOutlineDotsHorizontal />
        </button>
      )}
      {index === idx && show && (
        <div
          style={{ ...styles, width: width ?? "9rem", zIndex: 10 }}
          className="shadow position-absolute rounded-3 bg-white p-2 fs-12"
        >
          {data?.map((item, i) => (
            <div onClick={item?.onClick} key={i}>
              <a href={item?.href} className="text-decoration-none">
                <span className={`${className} pointer`}>{item?.name}</span>

                {i + 1 === data?.length ? null : (
                  <hr className="dropdown-divider" />
                )}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DropdownCard;
