import { useEffect, useState } from "react";
import Sidebar from "../../../../components/SidebarOwner";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button/button.component";
import hexToRGBA from "../../../../helper/hexToRbga";
import TableCustom from "../../../../components/Table";
import {
  addLocation,
  deleteLocation,
  getListTimezone,
  getLocation,
  updateLocation,
} from "../../../../redux/location/location.action";
import LocationComponent from "./location-component";
import ModalDelete from "../../../../components/Modal/delete";
import { useCookies } from "react-cookie";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Timezone",
    dataIndex: "timezone",
    key: "timezone",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
];

const Locations = () => {
  const { location } = useSelector((state) => state.location);

  const dispatch = useDispatch();
  const [page, setPage] = useState("read");
  const [payload, setPayload] = useState({
    name: "",
    timezone: "",
    address: "",
    latitude: "-3.745",
    longitude: "-38.523",
  });
  const { studio_detail } = useSelector((state) => state.user);
  const [show, setShow] = useState({
    visible: false,
    id: 0,
  });
  const [cookies] = useCookies(["user"]);

  const user_token = cookies?.user?.token;
  const studio_id = cookies?.user?.studio_id;

  useEffect(() => {
    dispatch(getListTimezone(user_token, studio_id));
    dispatch(getLocation("?limit=0", user_token, studio_id));
  }, [dispatch, page, show, studio_id, user_token]);

  const addLocations = () => {
    setPage("add");
  };

  const handlerAdd = (payload) => {
    const customPayload = {
      locations: payload,
    };
    dispatch(addLocation(customPayload));
    setPage("read");
  };
  const handlerEdit = (payload) => {
    if (page !== "edit") {
      setPage("edit");
    } else {
      const customPayload = {
        locations: payload,
      };
      dispatch(updateLocation(customPayload));
      setPage("read");
    }
  };

  const handleDelete = () => {
    if (show?.id) {
      dispatch(deleteLocation(show?.id));
      setPage("read");
      setShow({
        visible: false,
      });
    }
  };
  return (
    <Sidebar>
      <div className={`${page !== "read" && "md:w-[600px]"}`}>
        {page === "read" && (
          <>
            <p className="font-bold text-4xl">Locations</p>
            <div
              style={{
                border: `1px solid ${hexToRGBA(
                  studio_detail?.styles?.header_color,
                  0.2
                )}`,
                borderBottomWidth: 0,
              }}
              className="mt-8 p-2 flex justify-end min-h-full"
            >
              <Button
                type="outline-secondary"
                title="Add Location"
                onClick={addLocations}
                style={{
                  border: `1px solid ${studio_detail?.styles?.header_color}`,
                  color: studio_detail?.styles?.header_color,
                  borderRadius: 0,
                }}
              />
            </div>
            <TableCustom
              columns={columns}
              data={location || []}
              headColor={studio_detail?.styles?.header_color}
              actionsBtn={(id, info) => {
                switch (info) {
                  case "edit":
                    setPage("edit");
                    if (location) {
                      const select = location.find((item) => item.id === id);
                      setPayload(select);
                    }
                    break;

                  case "delete":
                    setShow({ visible: true, id: id });
                    break;

                  case "view":
                    setPage("view");
                    if (location) {
                      const select = location.find((item) => item.id === id);
                      setPayload(select);
                    }
                    break;

                  default:
                    break;
                }
              }}
            />
          </>
        )}

        {page === "add" && (
          <LocationComponent
            addLocation={handlerAdd}
            isEdit={false}
            studio_detail={studio_detail}
            setPage={setPage}
          />
        )}

        {page === "edit" && (
          <LocationComponent
            addLocation={handlerEdit}
            isEdit={true}
            payloadEdit={payload}
            studio_detail={studio_detail}
            setPage={setPage}
          />
        )}

        {page === "view" && payload && (
          <LocationComponent
            addLocation={handlerEdit}
            isEdit={true}
            payloadEdit={payload}
            studio_detail={studio_detail}
            setPage={setPage}
            isView={true}
          />
        )}
        <ModalDelete
          setShow={setShow}
          show={show}
          handleDelete={handleDelete}
          subject={"Location"}
        />
      </div>
    </Sidebar>
  );
};

export default Locations;
