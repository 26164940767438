import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { HiOutlineInformationCircle } from "react-icons/hi";
import "./text-input.styles.scss";
import { CopyOutlined } from "@ant-design/icons";

const TextInput = ({
  value,
  type,
  label,
  onChange,
  placeholder,
  className,
  labelClassName,
  inputClassName,
  style,
  disabled,
  onKeyUp,
  max,
  min,
  autoFocus,
  readOnly,
  required,
  addTooltip,
  price,
  userCanCopy,
  onClick,
}) => {
  const valueToString = value ? value.toString() : "";

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Class credits is needed to book classes on our platform using a plan with
      class pass type.
    </Tooltip>
  );

  return (
    <div className={`form-group ${className} my-2 relative`}>
      <label className={`custom-form-label ${labelClassName}`}>
        {label}

        {label && required ? (
          <span className="text-danger">&nbsp;*</span>
        ) : null}

        {addTooltip ? (
          <OverlayTrigger
            delay={{ hide: 400 }}
            placement="top"
            overlay={renderTooltip}
          >
            <div
              style={{ fontSize: "14px" }}
              className="d-flex align-items-center"
            >
              <HiOutlineInformationCircle className="text-warning" />
            </div>
          </OverlayTrigger>
        ) : null}
      </label>

      <input
        required={required}
        type={"text"}
        value={
          type === "number"
            ? valueToString?.replace(/\D/g, "")
            : type === "price"
            ? valueToString
                ?.replace(/\D/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : value
        }
        onChange={onChange}
        placeholder={placeholder}
        className={`${inputClassName} text-input`}
        style={style}
        readOnly={readOnly}
        disabled={disabled}
        onKeyUp={onKeyUp}
        maxLength={max}
        minLength={min}
        autoFocus={autoFocus}
      />

      {userCanCopy && (
        <CopyOutlined
          className="absolute bottom-0 top-6 right-2"
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default TextInput;
