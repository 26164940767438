import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

const containerStyle = {
  width: "auto",
  height: "230px",
  borderRadius: "4px",
};

const GoogleMaps = ({ latLong }) => {
  const [libraries] = useState(["places"]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC80VCtsrIrl30jL0vw-tM7xYCKR9ZmNak",
    libraries,
  });

  const [map, setMap] = useState(null);

  const defaultLocation = latLong
    ? latLong
    : { lat: -6.17511, lng: 106.865036 };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={defaultLocation}
      zoom={15}
      options={{
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
      onLoad={(map) => setMap(map)}
    >
      <MarkerF position={defaultLocation} draggable={false} />
    </GoogleMap>
  ) : null;
};

export default GoogleMaps;
