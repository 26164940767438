import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Row, Col, Form } from "react-bootstrap";
import Footer from "./../../../../components/Footer/footer.component";
import HeaderOwner from "./../../../../components/HeaderOwner/header-owner.component";
import TextInput from "../../../../components/TextInput/text-input.component";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import AtomLoading from "../../../../components/loding-indicator";
import {
  updateGeneralTerms,
  setStatusDefault,
  getGeneralTerms,
} from "../../../../redux/studio/studio.action";
import OnboardingStatus from "../../../../components/onboarding-status";
import { setMenuActive } from "../../../../redux/user/user.action";
import OrganismLayout from "../../../../components/Layout/organism-layout";

export const TermsAndConditions = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [cookies, setCookies] = useCookies(["user"]);

  const { onboarding_status } = useSelector((state) => state.owner);
  const { general_terms, status, isLoading, isRegisterStudent } = useSelector(
    (state) => state.studio
  );
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const isOnboarding = props.location.state?.isOnboarding;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      general_term_title: title,
      general_term_description: description,
    };

    dispatch(updateGeneralTerms(params, user_token, studio_id));
  };

  useEffect(() => {
    dispatch(getGeneralTerms(user_token, studio_id));
    dispatch(setMenuActive("Settings"));
  }, [dispatch, studio_id, user_token]);

  useEffect(() => {
    setTitle(general_terms?.title);
    setDescription(general_terms?.description);
  }, [general_terms]);

  useEffect(() => {
    if (status === "success") {
      if (isOnboarding) {
        if (onboarding_status?.cancellation_setting === false) {
          history.push({
            pathname: "/setting-cancellation",
            state: { isOnboarding: true },
          });
        } else if (onboarding_status?.payment_information === false) {
          history.push({
            pathname: "/admin/setting-payment-gateway",
            state: { isOnboarding: true },
          });
        } else {
          window.location.reload();
        }
      } else {
        window.location.reload();
      }
    }

    dispatch(setStatusDefault());
  }, [status, isOnboarding]);

  return (
    <OrganismLayout>
      <div fluid style={{ minHeight: "100vh" }}>
        {/* <HeaderOwner title="General Terms" /> */}
        {isLoading ? (
          <AtomLoading />
        ) : (
          <>
            {isOnboarding && (
              <OnboardingStatus selectedStep={2} activeStep={5} />
            )}
            <div>
              <div>
                <div className="md:w-[600px]">
                  <p className=" text-[2rem] font-bold mb-16">General Terms</p>
                  <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <Form.Group style={{ marginBottom: 10 }}>
                      <TextInput
                        required
                        autoFocus
                        label="Title"
                        placeholder="Input Title..."
                        value={title || ""}
                        onChange={(event) => {
                          setTitle(event.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group style={{ marginBottom: 10 }}>
                      <label htmlFor="desc" className="custom-form-label">
                        Description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        required
                        className="text-input"
                        name="desc"
                        id="desc"
                        cols="30"
                        rows="10"
                        placeholder="Input Description..."
                        value={description || ""}
                        onChange={(event) => {
                          setDescription(event.target.value);
                        }}
                      />
                    </Form.Group>
                    <div className="mt-3 d-flex justify-content-between gap-3">
                      <Button
                        style={{
                          border: `1px solid ${studio_detail?.styles?.header_color}`,
                          color: studio_detail?.styles?.header_color,
                          borderRadius: 0,
                        }}
                        type={"outline-secondary"}
                        title={isOnboarding ? "Skip for Now" : "Cancel"}
                        onClick={() => {
                          if (isOnboarding) {
                            onboarding_status?.cancellation_setting === false
                              ? history.push({
                                  pathname: "/admin/setting-cancellation",
                                  state: { isOnboarding: true },
                                })
                              : onboarding_status?.payment_information === false
                              ? history.push({
                                  pathname: "/admin/setting-payment-gateway",
                                  state: { isOnboarding: true },
                                })
                              : history.goBack();
                          } else {
                            history.goBack();
                          }
                        }}
                      />

                      <Button
                        buttonType="submit"
                        type="primary"
                        title="SAVE"
                        isLoading={isLoading}
                        style={{
                          backgroundColor: studio_detail?.styles?.header_color,
                          borderRadius: 0,
                        }}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </OrganismLayout>
  );
};
