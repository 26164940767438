import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Row, Col, Modal } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";

import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useCookies } from "react-cookie";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  changeStatusClass,
  getClassDetail,
  getClassParticipant,
  getClassTimeParticipant,
  updatePaymentStatus,
  updateStatusAttended,
} from "../../../../redux/owner/owner.action";
import StudentModal from "../Students/student-modal";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import {
  addSession,
  deleteSession,
  editSession,
  getSchedules,
} from "../../../../services/classes";
import { toast } from "react-toastify";
import DatePicker from "../../../../components/date-picker";
import TimePicker from "react-time-picker";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { Dropdown, Menu, Tag, Typography, Table } from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import hexToRGBA from "../../../../helper/hexToRbga";
import AtomNumberFormat from "../../../../components/number-format";

const DetailClass = (props) => {
  const mobileSize = useMemo(() => window.screen.width < 576, []);
  const history = useHistory();
  const dispatch = useDispatch();

  const currentDate = new Date();
  const [cookies, setCookies] = useCookies(["user"]);
  const [selectedSchedule, setSelectedSchedule] = useState(currentDate);
  const [active, setActive] = useState(1);
  const [active1, setActive1] = useState(1);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [modalChangeStatus, setModalChangeStatus] = useState(false);
  const [newSession, setNewSession] = useState({});
  const [modifySession, setModifySession] = useState({});
  const [deletedSession, setDeletedSession] = useState({});
  const [time, setTime] = useState("");
  const [search, setSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [index, setIndex] = useState("");
  const [index1, setIndex1] = useState("");
  const [fetch, setFetch] = useState(true);
  const [status, setStatus] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [isAttended, setIsAttended] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("10:00");
  const [title, setTitle] = useState("Add");
  const [open, setOpen] = useState(false);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const {
    class_detail,
    class_participant,
    class_time_participant,
    isLoading,
    isLoadingClass,
    isLoadingClassParticipant,
  } = useSelector((state) => state.owner);

  const id = props.location.state?.id;
  const isBrowseClasses = props.location?.state;
  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const status_class =
    modalChangeStatus.status === "active" ? "cancel" : "active";

  const colorTheme = studio_detail?.styles?.header_color;

  const setDate = (e) => {
    setSelectedDate(e);
    setShow2(false);
    setTime("");
  };

  const setScheduleDate = (e) => {
    setSelectedSchedule(e);
    setShow9(false);
  };

  const attendedStatus = [
    { label: "Select Status", value: "", selected: true },
    { label: "Attended", value: 1, selected: false },
    { label: "Unattended", value: 0, selected: false },
  ];

  const payment_status =
    show6?.payment_status === "pending"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Settlement", value: "settlement", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "payment verification"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Settlement", value: "settlement", selected: false },
          { label: "Refund Request", value: "refund request", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "settlement"
      ? [
          { label: "Select Status", value: "", selected: true },
          { label: "Refund Request", value: "refund request", selected: false },
          { label: "Cancelled", value: "cancelled", selected: false },
        ]
      : show6?.payment_status === "refund request" && [
          { label: "Select Status", value: "", selected: true },
          { label: "Refunded", value: "refunded", selected: false },
        ];

  const handleChangeSatusClass = () => {
    dispatch(
      changeStatusClass(
        user_token,
        studio_id,
        modalChangeStatus.id,
        status_class
      )
    );
    setModalChangeStatus({ visible: false });
    dispatch(getClassDetail(user_token, studio_id, id));
  };

  const updateClassAttendance = (e) => {
    e.preventDefault();
    let params = {
      order_id: show4?.id,
      is_attended: isAttended,
    };

    dispatch(updateStatusAttended(user_token, studio_id, params));

    setFetch(true);
    setShow4({ visible: false });
    setShow1(false);
  };

  const cancelClass = (class_order_number) => {
    let params = {
      payment_status: "cancelled",
    };

    dispatch(
      updatePaymentStatus(user_token, studio_id, params, class_order_number)
    );

    setFetch(true);
    setShow5({ visible: false });
    setShow1(false);
  };

  const updateStatusPayment = (e) => {
    e.preventDefault();
    let params = {
      payment_status: paymentStatus,
    };

    dispatch(updatePaymentStatus(user_token, studio_id, params, show6?.id));

    setFetch(true);
    setShow6({ visible: false });
    setShow1(false);
  };

  const handleGetSchedules = useCallback(async () => {
    setLoadingSchedules(true);
    try {
      const res = await getSchedules(user_token, studio_id, id, active1);
      setSchedules(res.data);
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setLoadingSchedules(false);
    }
  }, [active1, id, studio_id, user_token]);

  const handleModifySession = async (session_id) => {
    setLoading(true);
    try {
      let params = {
        class_date: moment(selectedSchedule).format("YYYY-MM-DD"),
        class_start_time: startTime,
        class_end_time: endTime,
      };

      title === "Add"
        ? await addSession(user_token, studio_id, params, session_id)
        : await editSession(user_token, studio_id, params, session_id);

      handleGetSchedules();
      toast.success(
        title === "Add" ? "Succesfully Added!" : "Succesfully Updated!"
      );
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setLoading(false);
      setShow8(false);
      setScheduleDate(currentDate);
      setStartTime("09:00");
      setEndTime("10:00");
      setModifySession({ visible: false });
    }
  };

  const handleDeleteSession = async (session_id) => {
    setLoading(true);
    try {
      await deleteSession(user_token, studio_id, session_id);
      handleGetSchedules();
      setDeletedSession({ visible: false });
      toast.success("Succesfully Deleted!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setLoading(false);
      setShow8(false);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getClassDetail(user_token, studio_id, id));
    }
  }, [dispatch, id, user_token, studio_id]);

  useEffect(() => {
    handleGetSchedules();
  }, [handleGetSchedules, active1]);

  useEffect(() => {
    if (selectedDate !== "") {
      dispatch(
        getClassTimeParticipant(
          user_token,
          studio_id,
          id,
          moment(selectedDate).format("YYYY-MM-DD")
        )
      );
    }
  }, [active, selectedDate, dispatch, id, user_token, studio_id]);

  useEffect(() => {
    if (fetch) {
      dispatch(
        getClassParticipant(
          user_token,
          studio_id,
          id,
          active,
          searchInput,
          moment(selectedDate).format("YYYY-MM-DD"),
          time.split("-")[0],
          time.split("-")[1]
        )
      );
      setFetch(false);
    }
  }, [fetch]);

  useEffect(() => {
    dispatch(
      getClassParticipant(
        user_token,
        studio_id,
        id,
        active,
        searchInput,
        moment(selectedDate).format("YYYY-MM-DD"),
        time.split("-")[0],
        time.split("-")[1]
      )
    );
  }, [
    searchInput,
    selectedDate,
    time,
    active,
    dispatch,
    id,
    user_token,
    studio_id,
  ]);

  const columns = [
    {
      title: "Date",
      dataIndex: "class_date",
      key: "class_date",
      render: (text) => moment(text).format("DD MMM YYYY"),
    },
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
    },
    {
      title: "Time",
      dataIndex: "class_start_time",
      key: "class_start_time",
      render: (text, record) =>
        `${record.class_start_time} - ${record.class_end_time}`,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() => {
                  if (
                    moment(currentDate).format("YYYY-MM-DD HH:mm") >
                    `${record.class_date} ${record.class_start_time}`
                  ) {
                    toast.error("Cannot Edit Session in the Past.");
                    return;
                  }
                  setTitle("Edit");
                  setModifySession({
                    visible: true,
                    id: record.id,
                  });
                  setSelectedSchedule(new Date(record.class_date));
                  setStartTime(record.class_start_time);
                  setEndTime(record.class_end_time);
                }}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                key="2"
                danger
                onClick={() => {
                  setDeletedSession({
                    id: record.id,
                    visible: true,
                    day: record.day,
                    date: moment(record.class_date).format("ll"),
                    time: `${record.class_start_time} - ${record.class_end_time}`,
                  });
                }}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined className="text-[25px]" />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <OrganismLayout title="Classes" maxWidth="56rem" className="p-0">
        <Row className="d-flex flex-column mx-auto">
          <Col className="p-3 mb-4 bg-body rounded-lg">
            <div className="d-flex justify-content-between mb-3">
              <div className="flex flex-col">
                <h3 className="flex align-items-center font-bold">
                  {class_detail?.class_name}
                </h3>
                <div>
                  <Tag
                    color={class_detail?.is_online ? "#9C15FF" : "#0296CF"}
                    className={"capitalize"}
                  >
                    {class_detail?.is_online ? "Online" : "Onsite"}
                  </Tag>
                </div>
              </div>

              <div>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: "Edit Class",
                        onClick: () =>
                          history.push({
                            pathname: `/admin/class/${id}/edit`,
                            state: { id: id },
                          }),
                      },
                      {
                        key: "2",
                        label: "Add Session",
                        onClick: () => {
                          setTitle("Add");
                          setModifySession({
                            visible: true,
                            id: id,
                          });
                        },
                      },
                      {
                        key: "3",
                        label:
                          class_detail?.status === "active"
                            ? "Deactivate"
                            : "Activate",
                        onClick: () => {
                          setModalChangeStatus({
                            id: id,
                            visible: true,
                            name: class_detail?.class_name,
                            date: moment(class_detail?.created_at).format("ll"),
                            status: class_detail?.status,
                          });
                          setShow(false);
                        },
                        danger: true,
                      },
                    ],
                  }}
                >
                  <div
                    onMouseOver={() => setOpen(!open)}
                    className="flex flex-row gap-1 flex-nowrap items-center justify-between cursor-pointer"
                  >
                    <Tag
                      color={
                        class_detail?.status === "active"
                          ? "#27AE60"
                          : "#EB5757"
                      }
                      className={"capitalize"}
                    >
                      {class_detail?.status}
                    </Tag>
                    {open ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  </div>
                </Dropdown>
              </div>
            </div>

            {class_detail?.is_online ? (
              <Typography.Link
                className="font-semibold"
                href={class_detail?.class_link}
                style={{
                  color: colorTheme,
                }}
              >
                Link: {class_detail?.class_link}
              </Typography.Link>
            ) : (
              <></>
            )}

            <div className="flex flex-col items-start max-w-[50%]">
              {class_detail?.locations?.length > 0 ? (
                <span>
                  {class_detail?.locations.map((el) => el.name).join(", ")}
                </span>
              ) : (
                <></>
              )}
              {class_detail?.is_online ? (
                <> </>
              ) : (
                <p className="max-w-[50%] whitespace-normal">
                  Room {class_detail?.room}
                </p>
              )}
            </div>

            <p className="whitespace-pre-wrap text-xs">
              {class_detail?.about_the_class}
            </p>

            <div className="flex flex-col gap-2 mt-10">
              {class_detail?.is_online ? (
                <div className="flex flex-row">
                  <span
                    className="min-w-[150px] font-semibold whitespace-nowrap "
                    style={{ color: colorTheme }}
                  >
                    Additional Info
                  </span>
                  <span>{class_detail?.additional_information}</span>
                </div>
              ) : null}
              <div className="flex flex-row">
                <span
                  className="min-w-[150px] font-semibold whitespace-nowrap "
                  style={{ color: colorTheme }}
                >
                  Class Category
                </span>
                {class_detail?.class_categories?.map((item, i) => (
                  <Tag
                    key={i}
                    className="p-1 capitalize"
                    style={{
                      backgroundColor: colorTheme,
                      color: "#fff",
                    }}
                  >
                    {item.name}
                  </Tag>
                ))}
              </div>

              <div className="flex flex-row">
                <span
                  className="min-w-[150px] font-semibold whitespace-nowrap "
                  style={{ color: colorTheme }}
                >
                  Price
                </span>
                <AtomNumberFormat
                  prefix="IDR "
                  value={class_detail?.class_price ?? 0}
                />
              </div>

              <div className="flex flex-row">
                <span
                  className="min-w-[150px] font-semibold whitespace-nowrap "
                  style={{ color: colorTheme }}
                >
                  Tax
                </span>
                <span>{class_detail?.class_tax ?? 0} %</span>
              </div>

              <div className="flex flex-row">
                <span
                  className="min-w-[150px] font-semibold whitespace-nowrap "
                  style={{ color: colorTheme }}
                >
                  Credits
                </span>
                <span>{class_detail?.credits ?? 0}</span>
              </div>

              <div className="flex flex-row">
                <span
                  className="min-w-[150px] font-semibold whitespace-nowrap "
                  style={{ color: colorTheme }}
                >
                  Teacher
                </span>
                <span style={{ color: colorTheme }}>
                  {class_detail?.teachers}
                </span>
              </div>

              <div className="flex flex-row">
                <span
                  className="min-w-[150px] font-semibold whitespace-nowrap "
                  style={{ color: colorTheme }}
                >
                  Notes
                </span>
                <div className="flex flex-col">
                  {/* {Array.from({ length: 5 }, (_, i) => ( */}
                  <div className="flex flex-row gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke={colorTheme}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 12.611L8.923 17.5 20 6.5"
                      ></path>
                    </svg>
                    <span className="text-sm">{class_detail?.notes}</span>
                  </div>
                  {/* ))} */}
                </div>
              </div>
            </div>
            <div className="mt-10">
              <h5>
                {class_detail?.all_plans_allowed === 1
                  ? "All Plans Allowed"
                  : "Seleted Plans"}
              </h5>

              <div>
                {class_detail.plans?.map((el, idx) => (
                  <Tag
                    key={idx}
                    className="p-1 m-1"
                    style={{
                      color: colorTheme,
                      backgroundColor: hexToRGBA(colorTheme, 0.1),
                    }}
                    onClick={() =>
                      history.push({
                        pathname: "/admin/detail-plan",
                        state: { id: el._id },
                      })
                    }
                  >
                    {el.plan_name}
                  </Tag>
                ))}
              </div>
            </div>

            <div className="mt-10">
              <h5>Sessions</h5>
              <Table
                columns={columns}
                dataSource={schedules.data}
                rowKey="id"
                size="large"
                bordered
                pagination={false}
                scroll={{ x: true }}
                locale={{ emptyText: "No Data" }}
                components={{
                  header: {
                    cell: ({ className, ...restProps }) => (
                      <th
                        {...restProps}
                        style={{
                          backgroundColor: colorTheme
                            ? hexToRGBA(colorTheme, 0.1)
                            : "#fafafa",
                        }}
                        className={className}
                      />
                    ),
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </OrganismLayout>

      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        size={"sm"}
        centered
        contentClassName="rounded-lg"
      >
        <div className="p-3 text-center">
          <Calendar
            locale="en"
            tileClassName={"rounded-circle"}
            className="border-0"
            prevLabel={<FaAngleLeft className="fs-4 greyblue" />}
            nextLabel={<FaAngleRight className="fs-4 greyblue" />}
            // minDate={selectedDate}
            onChange={(e) => setDate(e)}
            value={selectedDate}
          />
          <div onClick={() => setShow2(false)} className="">
            <p className="pt-3 m-0 pointer custom-hover greyblue">Cancel</p>
          </div>
        </div>
      </Modal>

      <StudentModal
        title="Edit Booking Status"
        data={show7}
        setShow={() => setShow7({ visible: false })}
        status={status}
        currentStatus={show7?.booking_status}
      />

      <StudentModal
        title="Edit Attended Status"
        data={show4}
        setShow={() => setShow4({ visible: false })}
        status={attendedStatus}
        currentStatus={show4?.attended_status}
        onChange={(e) => setIsAttended(e.target.value)}
        handleSubmit={updateClassAttendance}
      />

      <StudentModal
        title="Edit Payment Status"
        data={show6}
        setShow={() => setShow6({ visible: false })}
        status={payment_status}
        currentStatus={show6?.payment_status}
        onChange={(e) => setPaymentStatus(e.target.value)}
        handleSubmit={updateStatusPayment}
      />

      <Modal
        centered
        contentClassName="rounded-lg"
        show={show5?.visible}
        onHide={() => setShow1({ visible: false })}
      >
        <div className="text-center p-3 d-flex flex-column align-items-center">
          <h2 className="heading1 m-0 mb-3">Cancel Order</h2>
          <p>Cancel this Order?</p>
          <div className="b-gray mx-5 rounded mb-3">
            <p className="m-0 py-2 px-4">
              <b className="">{show5.name}</b>
              <br />
              <span className="heading3">for {show5?.student_name}</span>
            </p>
          </div>
          <span className="mb-3 text-danger fs-12">
            You need to return the money manually to your customer
          </span>
          <div className="d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow5({ visible: false })}
            />
            <Button
              title="Submit"
              type="primary"
              onClick={() => cancelClass(show5?.id)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={modalChangeStatus?.visible}
        onHide={() => setModalChangeStatus({ visible: false })}
      >
        <div className="mx-5 p-3">
          <div className="text-center">
            <h2 className="heading1 mb-3">
              {modalChangeStatus?.status === "active"
                ? "Deactivate "
                : "Activate "}
              Class
            </h2>
            <p className="pb-3">
              Please confirm you want to
              {modalChangeStatus?.status === "active"
                ? " deactivate "
                : " activate "}{" "}
              this Class.
            </p>
            <div className="b-gray justify-content-center rounded-lg mb-3 p-3">
              <Row className="mb-3">
                <Col>
                  <span className="lightaquamarine">Class Name</span> <br />
                  {modalChangeStatus?.name}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="lightaquamarine">Date</span> <br />
                  {modalChangeStatus?.date}
                </Col>
              </Row>
            </div>
            {modalChangeStatus?.status === "active" && (
              <span className="text-danger fs-12">
                Deactivate this class will cancel all the bookings schedules.
              </span>
            )}
          </div>
          <div className="mt-3 d-flex justify-content-center gap-3">
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setModalChangeStatus({ visible: false })}
            />
            <Button
              isLoading={isLoading}
              title="SEND REQUEST"
              type="primary"
              onClick={handleChangeSatusClass}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={deletedSession?.visible}
        onHide={() => setDeletedSession({ visible: false })}
      >
        <div className="py-4 px-3 text-center">
          <h2 className="font-medium">
            Are you sure want to delete this session ?
          </h2>
          <div className="mx-5 p-2 my-4 rounded">
            <Row>
              <Col>
                <p>{deletedSession?.date}</p>
              </Col>
              <Col>
                <p>{deletedSession?.day}</p>
              </Col>
              <Col className="">
                <p>{deletedSession?.time}</p>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-center gap-5">
            <Button
              type="secondary"
              title="Cancel"
              onClick={() => setDeletedSession({ visible: false })}
            />
            <Button
              isLoading={loading}
              type="primary"
              style={{
                backgroundColor: studio_detail?.styles?.header_color,
              }}
              title="Delete"
              onClick={() => handleDeleteSession(deletedSession?.id)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        centered
        contentClassName="rounded-lg"
        show={modifySession?.visible}
        onHide={() => setModifySession({ visible: false })}
      >
        <div className="py-3 px-4">
          <h2 className="text-center heading1">
            {title === "Add" ? "Add Schedule" : "Edit Schedule"}
          </h2>
          <Row className="mb-3">
            <Col>
              <DatePicker
                title="Select Date"
                currentDate={currentDate}
                selectedDate={selectedSchedule}
                show={show9}
                setShow={setShow9}
                setDate={setScheduleDate}
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <div className={`form-group`}>
                <label className="custom-form-label mt-0">Start Time</label>
                <TimePicker
                  format="HH:mm"
                  onChange={(event) => {
                    setStartTime(event);
                  }}
                  disableClock={true}
                  value={startTime}
                />
              </div>
            </Col>
            <Col>
              <div className={`form-group`}>
                <label className="custom-form-label mt-0">End Time</label>
                <TimePicker
                  format="HH:mm"
                  onChange={(event) => {
                    setEndTime(event);
                  }}
                  disableClock={true}
                  value={endTime}
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-center gap-5 mt-4">
            <Button
              type="secondary"
              title="Cancel"
              onClick={() => {
                setScheduleDate(currentDate);
                setModifySession({ visible: false });
              }}
              style={{ color: colorTheme, backgroundColor: "ffffff" }}
            />

            <Button
              style={{ backgroundColor: colorTheme }}
              isLoading={loading}
              type="primary"
              title="Confirm"
              onClick={() => {
                handleModifySession(modifySession?.id);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DetailClass;
