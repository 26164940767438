import { toast } from "react-toastify";
import axios from "../../axiosInstance";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import LocationTypes from "./location.types";

const TIMEZONE = "/timezones";
const LOCATION = "/locations";

export const getListTimezone = (token_jwt, studio_id) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state?.user?.user?.token || token_jwt}`,
          "Studio-id": state?.user?.user?.studio_id || studio_id,
        },
      };

      const res = await axios.get(TIMEZONE, config);

      dispatch({
        type: LocationTypes.GET_TIMEZONE,
        payload: res?.data?.data || [],
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
      dispatch({
        type: LocationTypes.GET_TIMEZONE,
        message: [],
      });
    }
  };
};

export const addLocation = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state?.user?.user?.token}`,
          "Studio-id": state?.user?.user?.studio_id,
        },
      };
      const res = await axios.post(LOCATION, data, config);
      dispatch({
        type: LocationTypes.ADD_LOCATION,
        payload: res?.data?.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const updateLocation = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state?.user?.user?.token}`,
          "Studio-id": state?.user?.user?.studio_id,
        },
      };
      const res = await axios.patch(LOCATION, data, config);
      dispatch({
        type: LocationTypes.UPDATE_LOCATION,
        payload: res?.data?.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const deleteLocation = (param) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state?.user?.user?.token}`,
          "Studio-id": state?.user?.user?.studio_id,
        },
      };
      const res = await axios.delete(LOCATION + "/" + param, config);
      dispatch({
        type: LocationTypes.DELETE_LOCATION,
        payload: res?.data?.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const findLocation = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state?.user?.user?.token}`,
          "Studio-id": state?.user?.user?.studio_id,
        },
      };
      const res = await axios.post(LOCATION, data, config);
      dispatch({
        type: LocationTypes.UPDATE_LOCATION,
        payload: res?.data?.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};

export const getLocation = (param, token_jwt, studio_id) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${state?.user?.user?.token || token_jwt}`,
          "Studio-id": state?.user?.user?.studio_id || studio_id,
        },
      };
      let path;
      if (param) {
        path = LOCATION + param;
      } else {
        path = LOCATION;
      }
      const res = await axios.get(path, config);
      dispatch({
        type: LocationTypes.GET_LOCATION,
        payload: res?.data?.data,
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };
};
