import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import "./register.styles.scss";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Form, Input, ConfigProvider, Select, Checkbox } from "antd";
import Button from "../../components/Button/button.component";
import google from "./../../assets/images/google_icon.png";
import facebook from "./../../assets/images/facebook_icon.svg";

import {
  registerStudio,
  registerStudioWithSocmedSuccess,
  setStatusDefault,
} from "../../redux/studio/studio.action";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import { registerWithSocmedMain } from "../../services/auth";
import UploadPicture from "../../components/uploadPicture";

const CreateStudio = ({ nextStep, setStep }) => {
  const dispatch = useDispatch();

  const { isLoading, status } = useSelector((state) => state.studio);

  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    if (status === "success") {
      nextStep();
      dispatch(setStatusDefault());
    }
  }, [dispatch, nextStep, status]);

  const responseSocialAuth = async (provider, response) => {
    try {
      const access_token = response?.accessToken;

      if (access_token) {
        const result = await registerWithSocmedMain(provider, access_token);

        if (result.data.data.token) {
          dispatch(registerStudioWithSocmedSuccess(result.data.data));
          setStep(3);
        }
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleSubmit = (value) => {
    const {
      owner_first_name,
      owner_last_name,
      owner_email,
      gender,
      phone_number,
    } = value;

    let params = {
      owner_first_name,
      owner_last_name,
      owner_email,
      gender,
    };
    if (phone_number) params.phone_number = phone_number;
    if (profilePicture) params.profile_picture = profilePicture;

    dispatch(registerStudio(params));
  };

  return (
    <div className="flex flex-col w-full justify-center px-8 py-8 md:px-24">
      <h2 className="font-bold my-5">Start Create Your Own Studio</h2>
      <div>
        <ConfigProvider
          theme={{
            components: {},
          }}
        >
          <Form size="large" layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="First Name"
              name="owner_first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your First Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="owner_last_name"
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="owner_email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email Address",
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item label="Phone Number" name="phone_number">
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please input your Gender",
                },
              ]}
              initialValue="male"
            >
              <Select
                options={[
                  { value: "male", label: "Male" },
                  { value: "female", label: "Female" },
                ]}
                placeholder="Select Your Gender"
              />
            </Form.Item>
            <Form.Item>
              <UploadPicture fileData={(file) => setProfilePicture(file)} />
            </Form.Item>
            <Form.Item name="remember">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <div className="d-flex justify-content-center">
              <Button
                buttonType="submit"
                type="primary"
                title="START MY CLASS"
                style={{
                  width: "100%",
                  backgroundColor: "#E26851",
                  borderRadius: 0,
                }}
                className=" mt-5 mb-3"
              />
            </div>
          </Form>
        </ConfigProvider>
      </div>

      <div className="d-flex justify-content-center flex-warp align-items-center flex-column">
        <div className="custom-form-label mt-3 mb-3">or continue with</div>
        <div className="flex items-center justify-center">
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            size="small"
            render={(renderProps) => (
              <img
                className="cursor-pointer"
                onClick={renderProps.onClick}
                style={{
                  height: "25px",
                  width: "25px",
                  marginRight: "10px",
                }}
                src={facebook}
                alt="logo"
              />
            )}
            fields="name,email,picture"
            callback={(res) => responseSocialAuth("facebook", res)}
            cssClass="facebook-btn mt-3 custom-hover"
          />
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => {
              return (
                <img
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="google-btn custom-hover"
                  style={{
                    height: "25px",
                    width: "25px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  src={google}
                  alt="img"
                />
              );
            }}
            onSuccess={(res) => responseSocialAuth("google", res)}
            onFailure={(res) => responseSocialAuth("google", res)}
          />
        </div>
        <span className="mt-3 regular-text">
          By continuing, you agree to{" "}
          <a
            target="_blank"
            href="/terms-and-conditions"
            className="font-semibold no-underline text-[#E26851]"
          >
            Gigsclassroom’s General Terms
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="/privacy-policy"
            className="font-semibold no-underline text-[#E26851]"
          >
            Privacy Policy
          </a>
        </span>
      </div>
    </div>
  );
};

export default CreateStudio;
