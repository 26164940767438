import React from "react";
import "./button.styles.scss";
import { useSelector } from "react-redux";

const CustomButton = ({
  title,
  onClick,
  type,
  style,
  className,
  icon,
  isLoading,
  buttonType,
  ...props
}) => {
  const { studio_detail } = useSelector((state) => state.user);

  const colorTheme = studio_detail?.styles?.header_color || "#E26851";

  const styles =
    type === "primary"
      ? { ...style, backgroundColor: colorTheme, borderRadius: 0 }
      : {
          ...style,
          border: `1px solid ${colorTheme}`,
          color: colorTheme,
          borderRadius: 0,
        };

  return (
    <button
      {...props}
      type={buttonType !== "submit" ? "button" : "submit"}
      disabled={isLoading}
      onClick={onClick}
      className={`${
        isLoading && "bg-secondary text-white disabled"
      } button ${className} ${type}`}
      style={styles}
    >
      {icon && !isLoading ? (
        <img
          alt=""
          src={icon}
          style={{ height: "25px", width: "25px", marginRight: "10px" }}
        />
      ) : null}
      {title}
    </button>
  );
};

export default CustomButton;
