import BankActionTypes from "./bank.types";

const INITIAL_STATE = {
  banks: [],
  bank_detail: [],
  isLoading: false,
  status: "default",
};

const BanksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BankActionTypes.GET_BANKS:
      return {
        ...state,
        isLoading: true,
      };
    case BankActionTypes.GET_BANKS_SUCCESS:
      return {
        ...state,
        banks: action.payload,
        isLoading: false,
      };
    case BankActionTypes.GET_BANKS_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case BankActionTypes.GET_BANKS_DETAIL:
      return {
        ...state,
        isLoading: true,
      };
    case BankActionTypes.GET_BANKS_DETAIL_SUCCESS:
      return {
        ...state,
        bank_detail: action.payload,
        isLoading: false,
      };
    case BankActionTypes.GET_BANKS_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.message,
      };
    case BankActionTypes.ADD_BANK:
      return {
        ...state,
        isLoading: true,
      };
    case BankActionTypes.ADD_BANK_SUCCESS:
      return {
        ...state,
        status: "success",
        isLoading: false,
      };
    case BankActionTypes.ADD_BANK_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case BankActionTypes.EDIT_BANK:
      return {
        ...state,
        isLoading: true,
      };
    case BankActionTypes.EDIT_BANK_SUCCESS:
      return {
        ...state,
        status: "success",
        isLoading: false,
      };
    case BankActionTypes.EDIT_BANK_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case BankActionTypes.DELETE_BANK:
      return {
        ...state,
        isLoading: true,
      };
    case BankActionTypes.DELETE_BANK_SUCCESS:
      return {
        ...state,
        status: "success",
        isLoading: false,
      };
    case BankActionTypes.DELETE_BANK_FAILED:
      return {
        ...state,
        status: "failed",
        isLoading: false,
        message: action.message,
      };
    case BankActionTypes.SET_STATUS_DEFAULT:
      return {
        ...state,
        status: "default",
      };
    default:
      return state;
  }
};

export default BanksReducer;
