import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import "./register.styles.scss";

import { FaExclamationCircle } from "react-icons/fa";
import { Form, Input, ConfigProvider } from "antd";

import Button from "../../components/Button/button.component";

import { EditStudio } from "../../redux/studio/studio.action";
import ColorPicker from "../../components/Color Picker";
import UploadPicture from "../../components/uploadPicture";
import { changeHeader } from "../../services/studios";

const StudioSetup = ({ nextStep }) => {
  const dispatch = useDispatch();

  const { studio, status, isLoading } = useSelector((state) => state.studio);
  const { user } = useSelector((state) => state.user);

  const [logoImage, setLogoImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#00897B");

  const studio_id = studio.studios?.at(-1).id ?? user.studios[0].id;

  const mobileSize = useMemo(() => window.screen.width < 576, []);

  useEffect(() => {
    if (status === "success") {
      nextStep();
    }
  }, [nextStep, status]);

  useEffect(() => {
    console.log({ user, studio });
  }, [studio, user]);

  const handleSubmit = (value) => {
    const { studio_name, subdomain } = value;
    const params = {
      studio_name,
      subdomain,
      // header_image: logoImage,
      logo: logoImage,
    };

    const bgColor = {
      backgroundColor: backgroundColor,
    };
    dispatch(
      EditStudio(params, studio._id ?? studio_id, user.token ?? studio.token)
    );
    dispatch(
      changeHeader(bgColor, studio._id ?? studio_id, user.token ?? studio.token)
    );
  };

  return (
    <div className="flex justify-center items-center pt-5 px-5 flex-col">
      <h1 className="font-bold my-5">Setup Your Basic Class Settings</h1>
      <ConfigProvider
        theme={{
          components: {
            Form: {},
          },
        }}
      >
        <Form
          className="w-full md:w-[600px]"
          layout="vertical"
          onFinish={handleSubmit}
          size="large"
        >
          <Form.Item label="Studio Name" name="studio_name">
            <Input placeholder="Yoga class" />
          </Form.Item>
          <div className="url-warning d-flex align-items-center my-3">
            <FaExclamationCircle
              color="#EB5757"
              size={20}
              style={{ marginRight: "10px" }}
            />
            <p className="regular-text m-0 fw-bold">
              Pick a short and easy to remember URL. <br />
              <span className="fst-italic" style={{ color: "#EB5757" }}>
                You can’t change this URL.
              </span>
            </p>
          </div>
          <Form.Item
            name="subdomain"
            extra="Spaces and special characters are not allowed (excluding dashes)"
          >
            <Input suffix=".gigsclass.id" />
          </Form.Item>
          <UploadPicture
            title="Upload Logo"
            fileData={(logo) => setLogoImage(logo)}
          />
          <ColorPicker
            className={mobileSize ? "w-auto" : ""}
            backgroundColor={backgroundColor}
            setBackgroundColor={setBackgroundColor}
          />
          <Button
            buttonType="submit"
            type="primary"
            title="LAUNCH MY CLASS"
            className="mt-3 mb-3"
            style={{
              width: "100%",
              backgroundColor: "#E26851",
              borderRadius: 0,
            }}
          />
        </Form>
      </ConfigProvider>
    </div>
  );
};

export default StudioSetup;
