import React from "react";
import { Typography, List } from "antd";

const { Title, Paragraph } = Typography;

const termsAndConditions = [
  "General",
  "Agreements",
  "Execution of the agreement",
  "Duration and termination of the agreement",
  "Confidentiality",
  "Prices",
  "Payment",
  "Liability",
  "Indemnity",
  "Force majeure",
  "Intellectual property",
  "User and member data",
  "Applicable law and choice of forum",
];

const TermsAndConditionsPage = () => (
  <Typography className="m-8">
    <Title level={2}>Terms and Conditions</Title>
    <Paragraph>
      <ol>
        {termsAndConditions.map((term, i) => (
          <li key={i}>
            <a href={`#${term}`}>{term}</a>
          </li>
        ))}
      </ol>
    </Paragraph>
    <Title level={5} id="General">
      1. General
    </Title>
    <Paragraph>
      Gigsclass's contract party in transactions to which Terms and Conditions
      apply is referred to as the "user". These Terms and Conditions apply to
      all agreements concluded with Gigsclass, even if third parties are
      involved in the execution. The provisions in these Terms and Conditions
      can only be deviated from in writing. The other provisions of these Terms
      and Conditions remain in full force. The deviating provisions only apply
      to the agreement in which the deviation has been agreed. Gigsclass has the
      right to change or supplement these Terms and Conditions.
    </Paragraph>
    <Title level={5} id="Agreements">
      2. Agreements
    </Title>
    <Paragraph>
      In case of a registration by a user via the Gigsclass website, an
      agreement for the delivery of a service is established as soon as
      Gigsclass accepts the registration. Gigsclass has the right to refuse a
      registration with motivation, or apply special conditions to the provision
      of services. If a registration is refused, the user will be notified
      within five working days after registration.
    </Paragraph>
    <Paragraph>
      Agreements are also established after written acceptance by Gigsclass of
      an order given by the user for the provision of additional services, or
      because Gigsclass has started with the execution of an order.
    </Paragraph>
    <Paragraph>
      An agreement can be revoked by Gigsclass if the user does not meet the
      agreed conditions or failed to do so in the past. Gigsclass will notify
      the user within five working days after receipt of the order.
    </Paragraph>
    <Paragraph>
      The user and Gigsclass agree that a valid agreement is established by
      electronic communication as soon as the user registration on the Gigsclass
      website has been confirmed by Gigsclass. The fact that there is no written
      signature does not mean the offer and the acceptance of the offer are not
      binding.
    </Paragraph>
    <Paragraph>
      Gigsclass is not bound by obvious mistakes on the Gigsclass website or
      mistakes in agreements; Gigsclass is entitled to make the necessary
      changes.
    </Paragraph>
    <Paragraph>
      Before concluding an agreement, the user must ensure that the correct
      choice of service to be provided by Gigsclass is made.
    </Paragraph>

    <Title level={5} id="Execution of the agreement">
      3. Execution of the agreement
    </Title>
    <Paragraph>
      Gigsclass will execute an agreement to the best of its knowledge and
      ability and in accordance with the requirements of good workmanship, on
      the basis of the current state of technology.
    </Paragraph>
    <Paragraph>
      If and insofar as required for the proper execution of the agreement,
      Gigsclass has the right to have certain services performed by third
      parties.
    </Paragraph>
    <Paragraph>
      The user, when registering on the website of Gigsclass, is obliged to
      provide Gigsclass with all information that Gigsclass indicates will be
      necessary or of which the user knows or could reasonably know that it is
      necessary for the proper execution of the agreement. If the information
      required for the execution of the agreement is not provided to Gigsclass,
      Gigsclass has the right to suspend the execution of the agreement.
    </Paragraph>
    <Paragraph>
      The user guarantees that all information provided to Gigsclass is correct
      and complete. Gigsclass is not liable for damage resulting from incorrect
      and/or incomplete information provided by the user, unless this inaccuracy
      or incompleteness should have been known to Gigsclass.
    </Paragraph>
    <Paragraph>
      If the user concludes that Gigsclass has failed in the performance of the
      service, he or she must inform Gigsclass of this in writing without delay,
      and no later than within fourteen days. Gigsclass will, where appropriate,
      be granted a reasonable time period to repair the defect. All costs are
      for the user, unless Gigsclass is liable as referred to in article 8.
    </Paragraph>

    <Title level={5} id="Duration and termination of the agreement">
      4. Duration and termination of the agreement
    </Title>
    <Paragraph>
      The agreement between Gigsclass and the user is entered into for an
      indefinite period of time, unless stated otherwise.
    </Paragraph>
    <Paragraph>
      The agreement can be terminated by both parties without motivation, with a
      notice period of one month.
    </Paragraph>
    <Paragraph>
      A termination by any party must be submitted in writing.
    </Paragraph>

    <Title level={5} id="Confidentiality">
      5. Confidentiality
    </Title>
    <Paragraph>
      Both parties are obliged to keep confidential information that they have
      received from each other or from other sources within the framework of the
      agreement confidential. Information is considered confidential if
      confidentiality has been communicated by the other party or if
      confidentiality results from the nature of the information.
    </Paragraph>
    <Paragraph>
      If, by law or because of a court order, Gigsclass is obliged to provide
      confidential information to a third party appointed by the law or a
      competent court, and Gigsclass does not have a legal or court recognized
      right to refuse, then Gigsclass is not obliged to give compensation to the
      user, and the user is not entitled to terminate the agreement on the basis
      of any damage caused as a result. This provision also applies to the user,
      in case the user is obliged to provide confidential information.
    </Paragraph>

    <Title level={5} id="Prices">
      6. Prices
    </Title>
    <Paragraph>
      All prices stated on the website are either in rupiah (IDR).
    </Paragraph>
    <Paragraph>
      The costs for the use of Gigsclass are charged either monthly or annually.
    </Paragraph>
    <Paragraph>
      Gigsclass is entitled to adjust current prices at any time by means of a
      written notification with a notice period of at least one month.
    </Paragraph>

    <Title level={5} id="Payment">
      7. Payment
    </Title>
    <Paragraph>
      Payment of amounts due by the user must take place without deduction,
      suspension or settlement within the payment term set in the agreement, on
      an account specified by Gigsclass.
    </Paragraph>
    <Paragraph>
      If payment has not taken place after the payment period has expired, the
      user is in default without the need for a notice of default.
    </Paragraph>
    <Paragraph>
      Payments made by the user first serve to settle all (collection) costs and
      statutory interest due, and only then payable invoices, the longest
      outstanding first, even if the user states that the payment is for a more
      recent invoice.
    </Paragraph>
    <Paragraph>
      In case of non-compliance by the user with the (payment) obligations,
      Gigsclass is entitled to terminate the agreement with immediate effect, or
      to suspend (further) delivery of services until the moment the user has
      fully complied with the (payment) obligations, including costs.
    </Paragraph>
    <Paragraph>
      If at least two regular payment reminders have been sent, and no payment
      has followed, Gigsclass is entitled to offer the user a final payment term
      of 24 hours. If no payment follows this last urgent payment reminder,
      Gigsclass is entitled to block access to the software. Gigsclass is not
      liable for any damage of whatever nature and size which is the result of
      inaccessibility of the software.
    </Paragraph>
    <Paragraph>
      Gigsclass has the right to settle all claims, due or not due and/or
      conditional.
    </Paragraph>

    <Title level={5} id="Liability">
      8. Liability
    </Title>
    <Paragraph>
      Gigsclass does not accept any liability for damage, consequential damage,
      company damage, damage as a result of delay, indirect damage, missed
      savings or loss of profit or turnover, nor for damage related to related
      services, unless the damage is the result of intentional or gross
      negligence by its employees.
    </Paragraph>
    <Paragraph>
      Gigsclass is only liable insofar as this liability is covered by its
      insurance. Gigsclass is never liable for damage caused by emergency
      personnel or for shortcomings as a result of force majeure.
    </Paragraph>
    <Paragraph>
      If for any reason the insurer does not pay out, or the damage is not
      covered by the insurance, the liability of Gigsclass is limited to the
      invoice amount involved in the agreement.
    </Paragraph>
    <Paragraph>
      A claim for compensation must be submitted to Gigsclass in writing no
      later than fourteen days after the user has discovered the damage or could
      reasonably have discovered it, failing which the right to compensation
      will lapse.
    </Paragraph>

    <Title level={5} id="Indemnity">
      9. Indemnity
    </Title>
    <Paragraph>
      The user indemnifies Gigsclass against all claims from third parties,
      whether or not they regard intellectual property rights on information
      provided by the user that is used in the execution of the agreement,
      including data entered by the user and its members in the Gigsclass
      software.
    </Paragraph>
    <Paragraph>
      The user also indemnifies Gigsclass from any other claim against Gigsclass
      from third parties (including members) in connection with the execution of
      an agreement concluded between the user and Gigsclass.
    </Paragraph>
    <Paragraph>
      The user furthermore indemnifies Gigsclass for any claims by third parties
      that suffer damage attributable to the user in connection with the
      execution of the agreement.
    </Paragraph>

    <Title level={5} id="Force majeure">
      10. Force majeure
    </Title>
    <Paragraph>
      Force majeure, in addition to how it is defined in law and jurisprudence,
      means all external causes, foreseen or unforeseen, on which Gigsclass is
      not able to exert influence, but that make it unable to meet its
      obligations, explicitly also including non-availability of technical
      functionalities of third parties.
    </Paragraph>
    <Paragraph>
      In the event of force majeure, the execution of the agreement will be
      suspended for as long as the situation of force majeure (explicitly
      including software downtime) makes it impossible for Gigsclass to execute
      the agreement.
    </Paragraph>
    <Paragraph>
      If a force majeure situation on the part of Gigsclass lasts for more than
      two months, both parties have the right to dissolve the agreement free of
      charge, without the obligation to pay compensation.
    </Paragraph>

    <Title level={5} id="Intellectual property">
      11. Intellectual property
    </Title>
    <Paragraph>
      Any documents and software provided by Gigsclass to the user are intended
      exclusively to be used by the user for the purpose agreed between the user
      and Gigsclass, and may not be reproduced, made public, or given to third
      parties by the user without prior permission from Gigsclass. The
      intellectual property in these documents and software rests with
      Gigsclass, and they remain the property of Gigsclass, unless otherwise
      agreed.
    </Paragraph>
    <Paragraph>
      Gigsclass is entitled to take technical measures to protect its
      products/services. If Gigsclass has secured its products/services by
      technical means, the user is not permitted to remove and/or evade this
      protection.
    </Paragraph>
    <Paragraph>
      If and as long as the user meets his obligations under the agreement
      concluded with Gigsclass, he will receive a non-exclusive and
      non-transferable license to use the software made available to him by
      Gigsclass. The intellectual property of the software created by Gigsclass
      rests with Gigsclass and remains the property of Gigsclass. Gigsclass
      guarantees that it is the creator of what is manufactured, delivered
      and/or made available in the sense of the Copyright Act.
    </Paragraph>
    <Paragraph>
      In case of a violation of the provisions in this article, Gigsclass will
      immediately gain the right to deny access to the software to the user.
    </Paragraph>

    <Title level={5} id="User and member data">
      12. User and member data
    </Title>
    <Paragraph>
      The data entered in the Gigsclass software by the user and his members is
      and remains the property of the user and his members. Gigsclass will not
      process or edit this data, other than in the context of the functioning of
      the software. With regard to this aspect, a separate processing agreement
      is concluded between the user and Gigsclass.
    </Paragraph>
    <Paragraph>
      The user is responsible for the data entered into the Gigsclass software,
      and responsible and liable for loss of or damage to data entered by the
      user or his members, unless liability of Gigsclass can be demonstrated.
    </Paragraph>
    <Paragraph>
      For the processing of personal data, Gigsclass (the processor) agrees to a
      processor agreement with the studio (the personal data provider). In
      addition, the Gigsclass Privacy Statement and EU General Data Protection
      Regulation apply to the processing of personal data.
    </Paragraph>
    <Paragraph>
      In case of damage or loss of user data, Gigsclass cannot be held
      responsible.
    </Paragraph>

    <Title level={5} id="Applicable law and choice of forum">
      13. Applicable law and choice of forum
    </Title>
    <Paragraph>
      Dutch law is exclusively applicable to all agreements concluded with
      Gigsclass.
    </Paragraph>
    <Paragraph>
      All disputes related to or arising from agreements concluded with
      Gigsclass will be submitted to the competent court in the court district
      in which Gigsclass is located, unless the law expressly designates another
      judge as competent.
    </Paragraph>

    <i>Last update: June 18, 2024</i>
  </Typography>
);

export default TermsAndConditionsPage;
