import React, { useState } from "react";
import { TimePicker, Space } from "antd";
import dayjs from "dayjs";

const TimePickerRange = ({ value = {}, onChange, start_time, end_time }) => {
  const format = "HH:mm";
  const [startTime, setStartTime] = useState(
    start_time ? dayjs(start_time, format) : undefined
  );
  const [endTime, setEndTime] = useState(
    end_time ? dayjs(end_time, format) : undefined
  );

  const triggerChange = (changedValue) => {
    onChange?.({
      startTime,
      endTime,
      ...value,
      ...changedValue,
    });
  };

  const onStartDateChange = (time, timeString) => {
    setStartTime(time);
    triggerChange({
      startTime: time,
    });
  };

  const onEndDateChange = (time, timeString) => {
    setEndTime(time);
    triggerChange({
      endTime: time,
    });
  };

  return (
    <Space className=" mx-5">
      <TimePicker
        name="startTime"
        onChange={onStartDateChange}
        placeholder="start time"
        value={startTime}
        format={format}
      />
      {" - "}
      <TimePicker
        name="endTime"
        onChange={onEndDateChange}
        placeholder="end time"
        value={endTime}
        format={format}
      />
    </Space>
  );
};

export default TimePickerRange;
