import React, {useEffect, useRef, useState} from "react";
import {useCookies} from "react-cookie";
import {useDispatch, useSelector} from "react-redux";

import moment from "moment";

import DropdownCard from "../../../../../components/Button/dropdown.component";
import Button from "../../../../../components/Button/button.component";
import AtomNumberFormat from "../../../../../components/number-format";
import AtomLoading from "../../../../../components/loding-indicator";

import {Link, useHistory} from "react-router-dom";
import {Row, Col, Modal} from "react-bootstrap";
import {
    deactivePlan,
    deletePlan,
    getDetailPlans,
    setStatusDefault,
} from "../../../../../redux/owner/owner.action";
import {useOutsideOnclick} from "../../../../../services/clickOutside";
import OrganismLayout from "../../../../../components/Layout/organism-layout";
import ModalDelete from "../../../../../components/Modal/delete";
import {CaretDownOutlined, CaretUpOutlined, MoreOutlined} from "@ant-design/icons";
import {Dropdown} from "antd";

const DetailPlan = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);

    const [cookies, setCookies] = useCookies(["user"]);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [collapsed, setCollapsed] = useState(false);

    const {isRegisterStudent} = useSelector((state) => state.studio);
    const {isLoading, detail_plan, deactive_status} = useSelector(
        (state) => state.owner
    );
    const {user, loginFromStudio, studio_detail} = useSelector((state) => state.user);

    const id = props.location.state?.id;
    const user_token =
        loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
    const studio_id =
        loginFromStudio || isRegisterStudent
            ? user.studio_id
            : cookies.user.studio_id;

    useOutsideOnclick(wrapperRef, setShow);

    const handleDeletePlan = (id) => {
        dispatch(deletePlan(user_token, studio_id, id));

        history.goBack();
    };

    const handleDeactivatePlan = (id, value) => {
        dispatch(deactivePlan(user_token, studio_id, id, value));

        setShow(false);
        setShow2({visible: false});
    };

    useEffect(() => {
        if (deactive_status === "success") {
            dispatch(getDetailPlans(user_token, studio_id, id));
            dispatch(setStatusDefault());
        } else {
            dispatch(getDetailPlans(user_token, studio_id, id));
        }
    }, [deactive_status, dispatch, id, studio_id, user_token]);

    return (
        <>
            <OrganismLayout title="Detail Plan" maxWidth="28rem">
                {isLoading ? (
                    <AtomLoading/>
                ) : (
                    <Col className="">
                        <div className="d-flex justify-content-between">
                            <div>
                  <span onClick={(prev) => setCollapsed(!collapsed)} className={"cursor-pointer"}>
                      <b
                          className={"capitalize cursor-pointer"}>{detail_plan?.plan_name}</b> {collapsed ? <CaretUpOutlined /> :<CaretDownOutlined/> }  <br/>
                  </span>
                                {collapsed && <span>
                                    Updated At{" "}
                                    {moment(detail_plan?.updated_at).format("DD MMM YYYY")}
                </span>}

                            </div>

                        </div>
                        <hr style={{height: "2px"}}/>
                        <Row className="heading3 flex flex-col gap-3">
                            <Col className={"flex flex-row"}><h6 className={"min-w-[170px]"}>Price</h6> <h6
                                className={"font-light"}><AtomNumberFormat prefix="IDR " value={detail_plan?.price}/>
                            </h6></Col>
                            <Col className={"flex flex-row"}><h6 className={"min-w-[170px]"}>Tax</h6> <h6
                                className={"font-light"}>{detail_plan?.tax} (%)</h6></Col>
                            <Col className={"flex flex-row"}><h6
                                className={"min-w-[170px]"}>{detail_plan?.time_type === "duration"
                                ? "Duration"
                                : detail_plan?.time_type === "valid_date"
                                    ? "Valid Date"
                                    : "Duration"}</h6> <h6
                                className={"font-light"}>{detail_plan?.time_type === "valid_date"
                                ? `${detail_plan?.start_valid_date} - ${detail_plan?.end_valid_date}`
                                : `${detail_plan?.valid_for} ${detail_plan?.duration_type}`}</h6></Col>
                            <Col className={"flex flex-row"}><h6
                                className={"min-w-[170px]"}>{detail_plan?.plan_type === "membership"
                                ? "Usage Limit"
                                : "Credits"}</h6> <h6 className={"font-light"}>{detail_plan?.plan_type === "membership"
                                ? detail_plan?.usage_limit
                                : detail_plan?.credits}</h6></Col>
                            <Col className={"flex flex-row"}><h6 className={"min-w-[170px]"}>Notes</h6> <h6
                                className={"font-light"}>{detail_plan?.notes ?? "-"}</h6></Col>
                            <Col className={"flex flex-row"}><h6
                                className={"min-w-[170px]"}>{detail_plan?.all_classes_allowed === 1
                                ? "All Class Allowed"
                                : "Selected Class"}</h6> <h6
                                className={"font-light"}>{detail_plan.classes?.map((el, idx) => (
                                <li key={idx}>
                                    <Link
                                        className="text-black"
                                        to={{
                                            pathname: `/admin/class/${el._id}/detail`,
                                            state: {id: el._id},
                                        }}
                                    >
                                        {el.class_name}
                                    </Link>
                                </li>
                            ))}</h6></Col>
                        </Row>


                        <div className="text-center">

                        </div>
                        <ul>

                        </ul>
                        <div className="d-flex justify-content-start pt-5">
                            <Button
                                type="outline-secondary"
                                title="Back"
                                onClick={() => history.goBack()}
                                style={{ outlineColor: studio_detail?.styles?.header_color,
                                border:  `1px solid ${studio_detail?.styles?.header_color}` ,
                                color: studio_detail?.styles?.header_color }}
                            />
                        </div>
                    </Col>
                )}
            </OrganismLayout>

            <ModalDelete
                show={show1}
                setShow={setShow1}
                subject="Plan"
                messages="Deleting this data will delete all membership data."
                handleDelete={() => handleDeletePlan(show1?.id)}
            />

            <Modal
                centered
                contentClassName="rounded-lg"
                show={show2?.visible}
                onHide={() => setShow2({visible: false})}
            >
                <div className="mx-5 p-3">
                    <div className="text-center">
                        <h2 className="heading1 mb-3">{show2?.title} Plan</h2>
                        <p className="pb-3">
                            Please confirm you want to {show2?.title} this plan.
                        </p>{" "}
                        <div className="b-gray justify-content-center rounded-lg mb-5 p-3">
                            <Row className="mb-3">
                                <Col>
                                    <span className="lightaquamarine">Plan Name</span> <br/>
                                    {show2?.name}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="lightaquamarine">Plan Type</span> <br/>
                                    {show2?.plan_type}
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center gap-3">
                        <Button
                            title="Cancel"
                            type="outline-secondary"
                            onClick={() => setShow2({visible: false})}
                        />
                        <Button
                            title={show2?.title}
                            type="primary"
                            onClick={() => handleDeactivatePlan(show2?.id, show2.value)}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DetailPlan;
