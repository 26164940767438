import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import AtomLoading from "../../../../components/loding-indicator";
import {
  getCancellationSetting,
  setStatusDefault,
  updateCancellation,
} from "../../../../redux/studio/studio.action";
import OnboardingStatus from "../../../../components/onboarding-status";
import { setMenuActive } from "../../../../redux/user/user.action";
import OrganismLayout from "../../../../components/Layout/organism-layout";

import { Checkbox, ConfigProvider, Form, Input } from "antd";
export const Cancellation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [cookies, setCookies] = useCookies(["user"]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { onboarding_status } = useSelector((state) => state.owner);
  const {
    cancellation_setting,
    status_cancellation,
    isLoading,
    isLoadingCancellation,
  } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const isOnboarding = props.location.state?.isOnboarding;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  useEffect(() => {
    dispatch(getCancellationSetting(user_token, studio_id));
    dispatch(setMenuActive("Settings"));
  }, [dispatch, studio_id, user_token]);

  useEffect(() => {
    if (onboarding_status?.cancellation_setting || cancellation_setting) {
      const {
        cancellation_before_hours,
        cancellation_policy_title,
        cancellation_policy_description,
      } = cancellation_setting;

      form.setFieldsValue({
        cancellation_before_hours,
        cancellation_policy_title,
        cancellation_policy_description,
      });
    }
  }, [cancellation_setting, onboarding_status?.cancellation_setting]);

  useEffect(() => {
    if (status_cancellation === "success") {
      dispatch(getCancellationSetting(user_token, studio_id));
    }
    dispatch(setStatusDefault());
  }, [
    status_cancellation,
    isOnboarding,
    dispatch,
    user_token,
    studio_id,
    history,
    onboarding_status?.payment_information,
  ]);

  const handleSubmit = ({
    cancellation_before_hours,
    cancellation_policy_title,
    cancellation_policy_description,
    roles = ["Owner"],
  }) => {
    let params = {
      cancellation_before_hours,
      cancellation_policy_title,
      cancellation_policy_description,
      roles: roles.join(),
    };

    dispatch(updateCancellation(params, user_token, studio_id));
  };

  return (
    <OrganismLayout>
      {isLoadingCancellation ? (
        <AtomLoading />
      ) : (
        <>
          {isOnboarding && <OnboardingStatus selectedStep={2} activeStep={6} />}
          <div className="md:w-[600px]">
            <p className="text-2xl font-bold">Cancellation</p>
            <div className="text-lg font-bold my-4">
              Configure Cancellation Period
            </div>
            <ConfigProvider
              theme={{
                components: {
                  Form: {
                    labelFontSize: "",
                  },
                },
              }}
            >
              <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                className="md:w-[568px] w-full"
                initialValues={{
                  roles: cancellation_setting?.roles,
                }}
              >
                <Form.Item label="Hours" name="cancellation_before_hours">
                  <Input type="number" placeholder="1" className="h-10" />
                </Form.Item>
                <div className="text-sm text-zinc-500 font-normal">
                  'The hour(s) in which a student can cancel for a class before
                  the class starts'
                </div>
                <div className="font-semibold text-lg  my-4 ">
                  Cancellation Policy
                </div>
                <Form.Item label="Title" name="cancellation_policy_title">
                  <Input placeholder="Input Title..." className="h-10" />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="cancellation_policy_description"
                >
                  <Input.TextArea
                    placeholder="Input Description..."
                    rows={5}
                    className="h-10"
                  />
                </Form.Item>
                <Form.Item
                  name="roles"
                  label="Choose Roles That Can Access The Cancellation"
                >
                  <Checkbox.Group>
                    <Checkbox disabled value="Owner">
                      Owner
                    </Checkbox>
                    <Checkbox value="Teacher">Teachers</Checkbox>
                  </Checkbox.Group>
                </Form.Item>

                <div className="flex justify-between">
                  <Button
                    style={{
                      border: `1px solid ${studio_detail?.styles?.header_color}`,
                      color: studio_detail?.styles?.header_color,
                      borderRadius: 0,
                    }}
                    type={"outline-secondary"}
                    title={isOnboarding ? "Skip for Now" : "Cancel"}
                    onClick={() => {
                      if (isOnboarding) {
                        onboarding_status?.payment_information === false
                          ? history.push({
                              pathname: "/admin/setting-payment-gateway",
                              state: { isOnboarding: true },
                            })
                          : history.goBack();
                      } else {
                        history.goBack();
                      }
                    }}
                  />
                  <Button
                    style={{
                      backgroundColor: studio_detail?.styles?.header_color,
                      borderRadius: 0,
                    }}
                    buttonType="submit"
                    type="primary"
                    title="SAVE"
                    isLoading={isLoading}
                  />
                </div>
              </Form>
            </ConfigProvider>
          </div>
        </>
      )}
    </OrganismLayout>
  );
};
