import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import "./owner.styles.scss";

import Button from "../../components/Button/button.component";
import {
  Form,
  Input,
  Radio,
  ConfigProvider,
  InputNumber,
  Space,
  Select,
  DatePicker,
} from "antd";
import {
  addPlan,
  getClass,
  setStatusDefault,
} from "../../redux/owner/owner.action";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { CloseCircleOutlined } from "@ant-design/icons";

const listDuration = [
  { label: "Days", value: "days" },
  { label: "Weeks", value: "weeks" },
  { label: "Months", value: "months" },
  { label: "Years", value: "years" },
];

const AddPlan = ({ nextStep, setSelectedStep, colorTheme }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [cookies] = useCookies(["subdomain", "user"]);
  const { addPlanStatus, owner_classes } = useSelector((state) => state.owner);
  const { user } = useSelector((state) => state.user);

  const user_token = user?.token || cookies?.user.token;
  const studio_id = user?.studio_id || cookies?.user?.studio_id;
  const list_class = owner_classes.data?.length > 0 ? owner_classes.data : [];

  const [selectedClass, setSelectedClass] = useState("all_classes");
  const [classId, setClassId] = useState("");
  const [classData, setClassData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getClass(user_token, studio_id, 1));
  }, [dispatch, studio_id, user_token]);

  useEffect(() => {
    if (addPlanStatus === "success") {
      dispatch(setStatusDefault());
      nextStep();
      setSelectedStep();
    }
    // dispatch(getClass(user_token, studio_id));
  }, [addPlanStatus]);

  const handleAddIdClass = () => {
    if (classId === "") {
      toast.error("Please Choose a Class");
      return;
    } else if (classData.find((x) => x.id === classId)) {
      toast.error("Class already exist!");
      return;
    }

    let classes = list_class?.find((e) => e?.id === classId);
    let params = { id: classes?.id, class_name: classes.class_name };

    setClassData([...classData, params]);
  };

  const removeClass = (idx) => {
    let deletedItem = classData[idx];
    const afterDelete = classData.filter((e) => e !== deletedItem);
    setClassData(afterDelete);
  };

  const handleSubmit = ({
    notes,
    plan_type,
    plan_name,
    price,
    tax,
    usage_limit,
    start_valid_date,
    end_valid_date,
    time_type,
    valid_for,
    duration_type,
    credits,
  }) => {
    let params = "";

    let finalStartDate = dayjs(start_valid_date).format("YYYY-MM-DD");

    let finalEndDate = dayjs(end_valid_date).format("YYYY-MM-DD");

    let classIds = "";

    classData?.map((e, idx) => {
      if (idx === 0) {
        classIds = e.id;
      } else {
        classIds = classIds?.concat(",", e.id);
      }
    });

    let allClass = list_class?.map((item) => item.id);

    if (plan_type === "membership" && time_type === "valid_date") {
      params = {
        plan_type,
        plan_name,
        price: price.toString().replace(/\D/g, ""),
        tax,
        time_type,
        start_valid_date: finalStartDate,
        end_valid_date: finalEndDate,
        usage_limit,
        notes,
        all_classes_allowed: selectedClass === "all_classes" ? "1" : "0",
        // class_ids: selectedClass === "all_classes" ? allClass : [classIds],
      };
    } else if (plan_type === "membership" && time_type === "duration") {
      params = {
        plan_type,
        plan_name,
        price: price.toString().replace(/\D/g, ""),
        tax,
        time_type,
        valid_for,
        duration_type,
        usage_limit,
        notes,
        all_classes_allowed: selectedClass === "all_classes" ? "1" : "0",
        // class_ids: selectedClass === "all_classes" ? allClass : [classIds],
      };
    } else if (plan_type === "class pass") {
      params = {
        plan_type,
        plan_name,
        price: price.toString().replace(/\D/g, ""),
        tax,
        credits,
        valid_for,
        duration_type,
        usage_limit,
        notes,
        all_classes_allowed: selectedClass === "all_classes" ? "1" : "0",
        // class_ids: selectedClass === "all_classes" ? allClass : [classIds],
      };
    }

    if (selectedClass !== "all_classes") params.class_ids = [classIds];

    console.log({ params });

    dispatch(addPlan(params, user_token, studio_id));
  };

  return (
    <div className="flex flex-col items-center min-h-screen">
      <h2 className="font-semibold my-6">Add your first plan</h2>
      <ConfigProvider
        theme={{
          components: {},
        }}
      >
        <Form
          layout="vertical"
          form={form}
          className="md:w-[568px] w-full"
          onFinish={handleSubmit}
          initialValues={{
            plan_type: "membership",
            time_type: "valid_date",
            tax: 11,
          }}
        >
          <div className="font-semibold text-xl text-center mb-8">Add Plan</div>

          <Form.Item name="plan_type">
            <Radio.Group className="flex justify-between">
              <Radio value="membership">Membership</Radio>
              <Radio value="class pass">Class pass</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Name" name="plan_name">
            <Input className="h-10" placeholder="Input plan name" />
          </Form.Item>

          <Form.Item label="Price (Exclude Taxes)" name="price">
            <Input className="h-10" placeholder="Input Price (Exclude Taxes)" />
          </Form.Item>

          <Form.Item label="Tax (%)" name="tax">
            <InputNumber className="h-10 w-full" placeholder="Tax" />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.plan_type !== currentValues.plan_type
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("plan_type") === "class pass" ? (
                <Form.Item label="Add Credits" name="credits">
                  <InputNumber
                    className="h-10 w-full"
                    placeholder="Add Credits.."
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.plan_type !== currentValues.plan_type
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("plan_type") === "membership" ? (
                <Form.Item name="time_type">
                  <Radio.Group className="flex justify-between">
                    <Radio value="valid_date">Valid Date</Radio>
                    <Radio value="duration">Duration</Radio>
                  </Radio.Group>
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.time_type !== currentValues.time_type
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("plan_type") === "class pass" ||
              getFieldValue("time_type") === "duration" ? (
                <Space.Compact className="w-full gap-4">
                  <Form.Item
                    label="Valid for"
                    name="valid_for"
                    className="w-full"
                  >
                    <InputNumber
                      className="h-10 w-full"
                      placeholder="Input Valid For Month"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Duration"
                    name="duration_type"
                    className="w-full"
                  >
                    <Select
                      options={listDuration}
                      className="h-10 w-full"
                      placeholder="Select Month"
                    />
                  </Form.Item>
                </Space.Compact>
              ) : (
                <Space.Compact className="w-full gap-4">
                  <Form.Item
                    label="Start Valid Date"
                    name="start_valid_date"
                    className="w-full"
                  >
                    <DatePicker size="large" className="w-full" />
                  </Form.Item>
                  <Form.Item
                    label="End Valid Date"
                    name="end_valid_date"
                    className="w-full"
                  >
                    <DatePicker size="large" className="w-full" />
                  </Form.Item>
                </Space.Compact>
              )
            }
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.plan_type !== currentValues.plan_type
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("plan_type") === "membership" ? (
                <Form.Item label="Usage Limit" name="usage_limit">
                  <Input type="number" className="h-10" placeholder="100" />
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item label="Notes" name="notes">
            <Input.TextArea
              rows={5}
              className="h-10 w-full"
              placeholder="Add Notes..."
            />
          </Form.Item>

          <Form.Item label={"Eligible Class"} name="eligible_class">
            <Radio.Group
              className={"flex flex-col"}
              defaultValue={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
              value={selectedClass}
            >
              <div className="flex p-2 rounded">
                <div className="flex items-center h-5">
                  <Radio
                    id="helper-radio-4"
                    value={"all_classes"}
                    className="w-4 h-4"
                  ></Radio>
                </div>
                <div className="ms-2 text-sm">
                  <div className="font-medium">
                    <label htmlFor="helper-radio-4">All Classes</label>
                    <p
                      id="helper-radio-text-4"
                      className="text-xs font-normal text-[#475569] "
                    >
                      Make this plan available to all classes and all classes in
                      the future
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex p-2 rounded">
                <div className="flex items-center h-5">
                  <Radio
                    id="helper-radio-5"
                    className="w-4 h-4"
                    value={"specific"}
                    // style={{
                    //   "--active-color": colorTheme,
                    // }}
                  ></Radio>
                </div>
                <div className="ms-2 text-sm w-full">
                  <div className="font-medium w-full flex flex-col gap-3">
                    <label htmlFor="helper-radio-5">Specific Classes</label>
                    <div
                      className={
                        "flex flex-col gap-3 items-start cursor-pointer"
                      }
                    >
                      <label htmlFor={"select-class"}>Choose Classes</label>
                      <Select
                        size={"large"}
                        disabled={selectedClass !== "specific"}
                        id={"select-class"}
                        value={classId}
                        onSelect={(e) => setClassId(e)}
                        options={
                          owner_classes?.data?.map((item) => ({
                            label: item.class_name,
                            value: item.id,
                          })) || []
                        }
                      />
                      <Button
                        type="primary"
                        title="Add"
                        onClick={handleAddIdClass}
                        isLoading={selectedClass !== "specific"}
                        style={{
                          backgroundColor: colorTheme,
                        }}
                      />
                    </div>

                    <label>Classes Chosen</label>
                    {classData?.length > 0 &&
                      classData?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              "w-full flex flex-row justify-between items-center align-middle cursor-pointer"
                            }
                          >
                            <p>{item?.class_name}</p>
                            <p>-</p>
                            <CloseCircleOutlined
                              className={
                                "hover:-translate-y-1 hover:scale-110 hover:text-red-800 duration-300"
                              }
                              onClick={() => removeClass(index)}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Button
              buttonType="submit"
              type="primary"
              title="CONTINUE"
              style={{ width: "100%", backgroundColor: colorTheme }}
            />
            <Button
              buttonType="button"
              type="secondary"
              title="Skip for now"
              onClick={() => {
                nextStep();
              }}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                color: colorTheme,
              }}
            />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </div>
  );
};

export default AddPlan;
