import "./App.scss";

import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { configureStore, configurePersistor } from "./redux/store";
import { CookiesProvider } from "react-cookie";
const store = configureStore();
const persistor = configurePersistor(store);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <CookiesProvider>
            <Routes />
          </CookiesProvider>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
