import React from "react";

const HeaderSection = ({ stepSchedule, studio_detail }) => {
  const name = ["Class Name Setup", "Schedules", "Choose Teacher"];
  return (
    <div className="flex flex-row items-center justify-between w-1/2">
      <h5 className="">{name[stepSchedule - 1]}</h5>
      <div className="d-flex align-items-center justify-content-center gap-2">
        {Array.from({ length: 3 }).map((_, idx) => (
          <div
            key={idx}
            style={{
              height: "12px",
              width: "12px",
              boxShadow: "inset 2px 2px 2px 0 rgba(0, 0, 0, 0.16)",
              borderRadius: "100%",
              backgroundColor: `${
                stepSchedule > idx
                  ? studio_detail?.styles?.header_color
                  : "#C4C4C4"
              }`,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default HeaderSection;
