import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import React from "react";

const AtomNumberFormat = ({ prefix, value }) => {
  return (
    <NumberFormat
      decimalSeparator=","
      displayType="text"
      prefix={prefix || ""}
      thousandSeparator="."
      value={value}
    />
  );
};

AtomNumberFormat.propTypes = {
  prefix: PropTypes.string,
  value: PropTypes.any,
};

export default AtomNumberFormat;
