import React from "react";

const Pagination = ({
  active,
  setActive,
  total_pages,
  current_page,
  className,
}) => {
  const pages = Array(total_pages).fill();

  return (
    <div
      style={{ fontSize: "14px" }}
      className={`d-flex justify-content-center align-items-center gap-1 ${
        className ? className : null
      }`}
    >
      <div
        onClick={() => {
          if (current_page > 1) setActive(current_page - 1);
        }}
        className={`${
          current_page > 1
            ? "greyblue pointer custom-hover"
            : "custom-form-label disabled"
        } text-decoration-none px-2`}
      >
        Before
      </div>
      {pages.map((el, idx) =>
        idx < 10 ? (
          <div
            key={idx}
            onClick={() => setActive(idx + 1)}
            className={`${
              active === idx + 1 ? "custom-button2" : "custom-form-label"
            } rounded px-2 pointer custom-hover3`}
          >
            {idx + 1}
          </div>
        ) : (
          idx + 1 === 10 && (
            <div
              key={idx}
              onClick={() => setActive(idx + 1)}
              className={`${
                active === idx + 1 ? "custom-button2" : "custom-form-label"
              } rounded px-2 pointer custom-hover3`}
            >
              ...
            </div>
          )
        )
      )}
      <div
        onClick={() => {
          if (total_pages > 1 && current_page < total_pages)
            setActive(current_page + 1);
        }}
        className={`${
          total_pages > 1 && current_page < total_pages
            ? "greyblue pointer custom-hover"
            : "custom-form-label disabled"
        } text-decoration-none px-2`}
      >
        Next
      </div>
    </div>
  );
};

export default Pagination;
