import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Button from "../../components/Button/button.component";

export const ModalLogin = ({
  show,
  title,
  label,
  email,
  handleClose,
  isLoading,
  handleSubmit,
  titleButton1,
  titleButton2,
}) => {
  return (
    <Modal size='sm' show={show} centered>
      <Row>
        <Col className='p-4 text-center'>
          <h2 className='heading2 mb-3'>{title}</h2>
          <span className='custom-form-label'>{label}</span> <br />
          <b className='fs-12'>{email}</b>
          <div className='flex flex-row gap-2'>
            <Button
              title={titleButton1}
              type='outline-secondary'
              className='mt-4'
              onClick={handleClose}
              isLoading={isLoading}
            />
            <br />
            <Button
              title={titleButton2}
              type='primary'
              className='mt-4'
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
