import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import "./owner.styles.scss";

import Button from "../../components/Button/button.component";
import { Form, ConfigProvider } from "antd";
// import TimePicker from "react-time-picker";

import { addSchedule, addDuration } from "../../redux/owner/owner.action";
import { toast } from "react-toastify";
import ScheduleFormlist from "../../components/ScheduleFormlist";
import DatePickerCustom from "../../components/DatePickerCustom";
import dayjs from "dayjs";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const ClassDurationSetup = ({
  nextStepSchedule,
  setSelectedStep,
  colorTheme,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const { addClass } = useSelector((state) => state.owner);

  const currentDate = new Date();

  const [repeatAmount, setRepeatAmount] = useState("1");
  const [repeatDuration, setRepeatDuration] = useState("weeks");
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isNeverEnds, setIsNeverEnds] = useState(false);

  useEffect(() => {
    if (addClass.start_date !== "") {
      nextStepSchedule();
    }
  }, [addClass.start_date]);

  const handleSubmit = (value) => {
    setIsReady(false);

    const arrSchedule = [];
    const ids = [];

    // Function to check if time slot is already included
    const isTimeSlotIncluded = (day, startTime, endTime) => {
      const timeSlotId = `${day}${startTime}-${endTime}`;
      return ids.includes(timeSlotId);
    };

    // Function to process the schedule for a specific day
    const processDaySchedule = (day, daySchedule) => {
      daySchedule.forEach((times) => {
        const { startTime, endTime } = times;
        const dayLowerCase = day.toLocaleLowerCase();

        // Check if startTime and endTime are not empty and are valid dates
        if (
          startTime &&
          endTime &&
          dayjs(startTime, "HH:mm", true).isValid() &&
          dayjs(endTime, "HH:mm", true).isValid()
        ) {
          if (isTimeSlotIncluded(dayLowerCase, startTime, endTime)) {
            setIsReady(false);
            toast.error("Please Select Different Time!");
          } else {
            setIsReady(true);
            const timeSlotId = `${dayLowerCase}${startTime}-${endTime}`;
            ids.push(timeSlotId);
            arrSchedule.push({
              day: dayLowerCase,
              start_time: dayjs(startTime).format("HH:mm"),
              end_time: dayjs(endTime).format("HH:mm"),
            });
          }
        }
      });
    };

    // Process each day's schedule
    days.forEach((day) => {
      if (value[day][0]) {
        processDaySchedule(day, value[day]);
      }
    });

    // Function to format the date
    const formatDate = (date) => {
      const { year, month, day } = date || {};
      return `${year}-${month}-${day}`;
    };

    const finalStartDate = formatDate(value.start_date);
    const finalEndDate = isNeverEnds ? "" : formatDate(value.end_date);

    const params = {
      start_date: finalStartDate,
      end_date: finalEndDate,
      repeat_every: repeatAmount,
      repeat_duration: repeatDuration,
    };

    // If the schedule is ready, dispatch actions and proceed to the next step
    if (isReady) {
      dispatch(addSchedule(arrSchedule));
      dispatch(addDuration(params));
      nextStepSchedule();
    }
  };

  const initialValues = days.reduce((acc, day) => {
    const daySchedules = addClass.schedule;
    acc[day] =
      daySchedules.length > 0
        ? daySchedules.map((schedule) => ({
            startTime: schedule.start_time,
            endTime: schedule.end_time,
          }))
        : [{ startTime: "", endTime: "" }];
    acc[`${day}Checked`] = daySchedules.length > 0;
    return acc;
  }, {});

  return (
    <div className="flex justify-center items-center p-4 flex-col gap-3">
      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <ConfigProvider
          theme={{
            components: {
              Form: {},
            },
          }}
        >
          <Form
            size="large"
            form={form}
            initialValues={initialValues}
            onFinish={handleSubmit}
          >
            <div className="text-slate-900 text-lg font-bold my-[25px]">
              Period
            </div>
            <Form.Item label="Start date" name="start_date">
              <DatePickerCustom />
            </Form.Item>
            <Form.Item label="End date" name="end_date">
              <DatePickerCustom
                endDate
                isNeverEnds={isNeverEnds}
                setIsNeverEnds={setIsNeverEnds}
              />
            </Form.Item>
            <div className="text-slate-900 text-lg font-bold my-[25px]">
              Schedules
            </div>
            {days.map((day) => (
              <ScheduleFormlist
                key={day}
                name={day}
                colorTheme={colorTheme}
                form={form}
              />
            ))}

            <div className="flex flex-col md:w-[568px] w-full gap-2">
              <Button
                isLoading={isLoading}
                buttonType="submit"
                type="primary"
                title="CONTINUE"
                style={{ width: "100%", backgroundColor: colorTheme }}
              />

              <Button
                type="secondary"
                title="Skip for now"
                onClick={() => {
                  setSelectedStep(4);
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  color: colorTheme,
                }}
              />
            </div>
          </Form>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default ClassDurationSetup;
