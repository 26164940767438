import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Col, Modal, Stack } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { DateRange } from "react-date-range";
import { toast } from "react-toastify";
import Button from "../../../../components/Button/button.component";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import AtomNumberFormat from "../../../../components/number-format";
import { getIncomeReport } from "../../../../services/dashboard";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import hexToRGBA from "../../../../helper/hexToRbga";
import { ConfigProvider, Space, Table } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import calender from "../../../../assets/images/calender.svg";

const IncomeReport = () => {
  const [cookies, setCookies] = useCookies(["user"]);
  const [listStatistic, setListStatistic] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState({});
  const [report, setReport] = useState("income");

  let date = new Date();
  let last = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);

  const [state, setState] = useState([
    {
      startDate: last,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const reportTypes = [
    { name: "Income Report", value: "income" },
    { name: "Student Enrollment", value: "students" },
  ];

  const mobileSize = useMemo(() => window.screen.width < 576, []);

  const handleGetListStatistic = async (isDownload) => {
    setIsLoading(true);
    try {
      const res = await getIncomeReport(
        user_token,
        studio_id,
        report,
        moment(state[0].startDate).format("YYYY-MM-DD"),
        moment(state[0].endDate).format("YYYY-MM-DD"),
        isDownload ?? 0
      );
      if (isDownload) {
        const outputFilename = `Income Report ${moment(
          state[0].startDate
        ).format("YYYY-MM-DD")}-${moment(state[0].endDate).format(
          "YYYY-MM-DD"
        )}.xlsx`;

        const url = URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
      } else {
        setListStatistic(res.data.data);
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetListStatistic();
  }, [report, state]);

  const columns =
    report === "income"
      ? [
          {
            title: "Student ID",
            dataIndex: "student_id",
            key: "student_id",
          },
          {
            title: "Student Name",
            dataIndex: "student_name",
            key: "student_name",
          },
          {
            title: "Order ID",
            dataIndex: "order_id",
            key: "order_id",
            className: "text-capitalize",
          },
          {
            title: "Transaction Date",
            dataIndex: "transaction_date",
            key: "transaction_date",
            render: (text) => moment(text).format("DD-MMM-YYYY"),
          },
          {
            title: "Plan Name",
            dataIndex: "plan_name",
            key: "plan_name",
            render: (text) => text ?? "-",
          },
          {
            title: "Amount Received",
            dataIndex: "amount_received",
            key: "amount_received",
            render: (text) => <AtomNumberFormat prefix="IDR " value={text} />,
          },
          {
            title: "Fees",
            dataIndex: "fees",
            key: "fees",
          },
          {
            title: "Amount to be Dispersed",
            dataIndex: "amount_dispersed",
            key: "amount_dispersed",
            render: (text) => <AtomNumberFormat prefix="IDR " value={text} />,
          },
        ]
      : [
          {
            title: "Transaction Date",
            dataIndex: "created_at",
            key: "created_at",
            render: (text) => moment(text).format("DD-MMM-YYYY"),
          },
          {
            title: "Student Name",
            dataIndex: "student_name",
            key: "student_name",
          },
          {
            title: "Pass Type",
            dataIndex: "pass_type",
            key: "pass_type",
          },
          {
            title: "Usage",
            dataIndex: "usage_limit",
            key: "usage_limit",
            render: (text) => text ?? "-",
          },
          {
            title: "Pass Left",
            dataIndex: "pass_left",
            key: "pass_left",
            render: (text) => text ?? "-",
          },
          {
            title: "Exp Date",
            dataIndex: "expired_date",
            key: "expired_date",
            render: (text) => moment(text).format("DD-MMM-YYYY"),
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            className: "text-capitalize",
          },
        ];

  return (
    <>
      <OrganismLayout title={`${report} Report`} className="p-0">
        <Col>
          <>
            <h2 className="font-bold">Report</h2>

            <div className="d-grid d-md-flex justify-content-between gap-3 my-8">
              <Stack direction={mobileSize ? "vertical" : "horizontal"} gap={3}>
                <div className="shadow-sm d-flex overflow-hidden">
                  {reportTypes.map((el, idx) => (
                    <div
                      onClick={() => {
                        setReport(el.value);
                      }}
                      key={idx}
                      style={{
                        width: "max-content",
                        backgroundColor:
                          report === el?.value ? colorTheme : "#ffffff",
                        border: `1px solid ${hexToRGBA(colorTheme, 0.3)}`,
                      }}
                      className={`${
                        report === el?.value
                          ? `text-white`
                          : "pointer text-[#BDBDBD]"
                      } py-2 px-3 heading3`}
                    >
                      <b className="m-0 text-sm">{el?.name}</b>
                    </div>
                  ))}
                </div>

                <Space onClick={() => setScheduleOpen({ visible: true })}>
                  <img alt="" src={calender} width={18} />
                  <span className=" pointer custom-hover ms-2">
                    {`${moment(state[0].startDate).format(
                      "DD MMM, YYYY"
                    )} - ${moment(state[0].endDate).format("DD MMM, YYYY")}`}
                  </span>
                  <CaretDownOutlined />
                </Space>
              </Stack>

              <Button
                type="primary"
                title="Export"
                onClick={() => handleGetListStatistic(1)}
                style={{
                  borderRadius: 0,
                  backgroundColor: colorTheme,
                }}
              />
            </div>

            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBorderRadius: 0,
                    headerBg:
                      hexToRGBA(studio_detail?.styles?.header_color, 0.1) ||
                      "#fafafa",
                  },
                },
              }}
            >
              <Table
                columns={columns}
                size="middle"
                dataSource={listStatistic}
              />
            </ConfigProvider>
          </>
        </Col>
      </OrganismLayout>

      <Modal
        centered
        contentClassName="rounded-lg bg-light overflow-auto"
        show={scheduleOpen?.visible}
        onHide={() => setScheduleOpen({ visible: false })}
      >
        <div className="py-2 text-center">
          <DateRange
            className="shadow-sm mb-3"
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <div className="d-flex justify-content-center ">
            <Button
              type="primary"
              title="Close"
              onClick={() => setScheduleOpen({ visible: false })}
              style={{
                borderRadius: 0,
                backgroundColor: colorTheme,
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IncomeReport;
