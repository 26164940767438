import React, { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import {
  Row,
  Col,
  Accordion,
  useAccordionButton,
  Modal,
} from "react-bootstrap";
import Pagination from "../../../../components/pagination";
import DropdownCard from "../../../../components/Button/dropdown.component";
import AtomLoading from "../../../../components/loding-indicator";

import {
  HiArrowCircleDown,
  HiArrowCircleRight,
  HiCalendar,
  HiClock,
  HiUser,
  HiUserGroup,
} from "react-icons/hi";
import {
  getProfile,
  getUserRole,
} from "../../../../redux/student/student.action";
import {
  setLoginFromStudio,
  setNewUser,
} from "../../../../redux/user/user.action";
import EmptyData from "../../../../components/no-data";
import * as dashboardService from "../../../../services/dashboard";
import { useOutsideOnclick } from "../../../../services/clickOutside";
import { changeRole } from "../../../../services/auth";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import { DateRange } from "react-date-range";
import Button from "../../../../components/Button/button.component";
import { getStudioDetail } from "../../../../redux/user/user.action";
import Sidebar from "../../../../components/SidebarOwner";
import calender from "../../../../assets/images/calender.svg";
import hexToRGBA from "../../../../helper/hexToRbga";
import { ConfigProvider, Menu, Popover } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

const TeacherDashBoard = () => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const [active1, setActive1] = useState(1);
  const [active2, setActive2] = useState(1);
  const [active3, setActive3] = useState(1);
  const [active4, setActive4] = useState(1);
  const [index, setIndex] = useState("");
  const [open, setOpen] = useState(false);
  const [cookies, removeCookies] = useCookies(["user"]);
  const [selectedDate, setSelectedDate] = useState({ visible: false });
  const [cancellationRequest, setCancellationRequest] = useState([]);
  const [classInprogress, setClassInprogress] = useState([]);
  const [upcomingClass, setUpcomingClass] = useState([]);
  const [classHeld, setClassHeld] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let date = new Date();
  let last = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);

  const [state, setState] = useState([
    {
      startDate: last,
      endDate: date,
      key: "selection",
    },
  ]);

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const { user_role, profile } = useSelector((state) => state.student);
  const themeColor = studio_detail?.styles?.header_color;
  const role =
    loginFromStudio || isRegisterStudent ? user?.role : cookies.user?.role;

  const userRoles = user_role.roles
    ? user_role.roles?.filter((item) => item !== role)
    : [];
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;

  const data = [
    ...userRoles.map((el) => ({
      name: `View as ${
        el === "owner"
          ? "admin".toLowerCase().replace(/\w/, (x) => x.toUpperCase())
          : el.toLowerCase().replace(/\w/, (x) => x.toUpperCase())
      }`,
      onClick: () => setRoles(el),
    })),
    {
      name: "Edit Profile",
      href: "/admin/profile",
    },
  ];

  const setRoles = async (e) => {
    try {
      const params = {
        role: e.key,
      };

      const res = await changeRole(user_token, studio_id, params);
      dispatch(setNewUser(res.data.data));

      // if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      // }
      dispatch(setLoginFromStudio());
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      index === eventKey ? setIndex("") : setIndex(eventKey)
    );

    return (
      <div style={{ cursor: "pointer" }} onClick={decoratedOnClick}>
        {children}
      </div>
    );
  };

  const getClassInProgress = async () => {
    try {
      const res = await dashboardService.getClassInProgress(
        user_token,
        studio_id,
        moment(state[0].startDate).format("YYYY-MM-DD"),
        moment(state[0].endDate).format("YYYY-MM-DD"),
        active4
      );
      console.log(res.data);
      setClassInprogress(res.data);
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const getUpcomingClass = async () => {
    setIsLoading(true);
    try {
      const res = await dashboardService.getUpcomingClass(
        user_token,
        studio_id,
        moment(state[0].startDate).format("YYYY-MM-DD"),
        moment(state[0].endDate).format("YYYY-MM-DD"),
        active1
      );
      setUpcomingClass(res.data);
      setIsLoading(false);
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const getCancellationRequest = async () => {
    setIsLoading(true);
    try {
      const res = await dashboardService.getCancellationRequest(
        user_token,
        studio_id,
        moment(state[0].startDate).format("YYYY-MM-DD"),
        moment(state[0].endDate).format("YYYY-MM-DD"),
        active2
      );
      setCancellationRequest(res.data);
      setIsLoading(false);
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const getClassHeld = async () => {
    setIsLoading(true);
    try {
      const res = await dashboardService.getClassHeld(
        user_token,
        studio_id,
        moment(state[0].startDate).format("YYYY-MM-DD"),
        moment(state[0].endDate).format("YYYY-MM-DD"),
        active3
      );
      setClassHeld(res.data);
      setIsLoading(false);
    } catch (error) {
      // toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  useEffect(() => {
    dispatch(getUserRole(user_token, studio_id));
    dispatch(getProfile(user_token, studio_id));
  }, [dispatch, studio_id, user_token]);

  useEffect(() => {
    getClassInProgress();
  }, [state]);

  // useEffect(() => {
  //   getPaymentVerification();
  // }, [state, active]);

  useEffect(() => {
    getUpcomingClass();
  }, [state, active1]);

  useEffect(() => {
    getCancellationRequest();
  }, [state, active2]);

  useEffect(() => {
    getClassHeld();
  }, [state, active3]);

  useEffect(() => {
    dispatch(getStudioDetail(user_token, studio_id));
  }, [studio_id, user_token, dispatch]);

  const items = [
    {
      key: `1`,
      label: "Select Role",
      children: userRoles.map((el) => ({
        key: el,
        label: el.toLowerCase().replace(/\w/, (x) => x.toUpperCase()),
      })),
    },
  ];

  const content = (
    <div className="w-[220px]">
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemSelectedColor: "rgba(0, 0, 0, 0.88)",
            },
          },
        }}
      >
        <Menu
          mode="inline"
          items={items}
          className="font-bold capitalize"
          onClick={setRoles}
        />
      </ConfigProvider>
    </div>
  );

  return (
    <Sidebar>
      <div className="d-flex row">
        {/* <HeaderTeacher title='Dashboard' /> */}
        {isLoading ? (
          <AtomLoading />
        ) : (
          <div>
            <div className="flex justify-between items-center">
              <div>
                <div className="flex item items-center gap-2">
                  <span className="heading1 fw-bold">
                    {profile?.first_name} {profile?.last_name}
                  </span>

                  <Popover
                    open={open}
                    onOpenChange={() => setOpen((prev) => !prev)}
                    trigger="click"
                    placement="bottom"
                    content={content}
                  >
                    <CaretDownOutlined onClick={() => setOpen(true)} />
                  </Popover>
                </div>

                <span className="heading2 fw-normal text-capitalize">
                  {role === "owner" ? "admin" : role}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <img alt="" src={calender} width={18} />
                <span
                  onClick={() => setSelectedDate({ visible: true })}
                  className=" pointer custom-hover ms-2"
                >
                  {`${moment(state[0].startDate).format(
                    "DD MMM, YYYY"
                  )} - ${moment(state[0].endDate).format("DD MMM, YYYY")}`}
                </span>
              </div>
            </div>

            <div className="md:flex gap-2">
              <Accordion className="md:w-3/5">
                <div
                  style={{
                    minHeight: 200,
                    border: `1px solid ${hexToRGBA(themeColor, 0.4)}`,
                  }}
                  className=" p-2 mt-2"
                >
                  {classInprogress.data?.length > 0 ? (
                    classInprogress.data.map((el, idx) => (
                      <div key={idx}>
                        <div className="d-flex justify-content-between fs-12 mb-2">
                          <span className="font-semibold">
                            Class In Progress
                          </span>
                          <Link
                            to={{
                              pathname: `/admin/class/${el?.class_id}/detail`,
                              state: { id: el?.class_id },
                            }}
                            className="text-decoration-none greyblue"
                          >
                            Detail
                          </Link>
                        </div>

                        <div className="d-flex justify-content-between mb-2">
                          <div className="">
                            <span className="fw-bold me-2">
                              {el.class_name}
                            </span>
                            <span className="fs-12">
                              {el.is_online === 1 ? "Online" : "Offline"}
                            </span>
                          </div>
                          <span className="">
                            <CustomToggle eventKey={idx}>
                              {index === idx ? (
                                <HiArrowCircleDown className="greyblue custom-hover fs-6" />
                              ) : (
                                <HiArrowCircleRight className="text-black-50 custom-hover fs-6" />
                              )}
                            </CustomToggle>
                          </span>
                        </div>

                        <div className="fs-12 d-flex gap-3">
                          <span>
                            <HiCalendar className="text-pink" />{" "}
                            {moment(el?.class_date).format("dddd, DD MMM YYYY")}
                          </span>
                          <span>
                            <HiClock className="text-pink" />{" "}
                            {el?.class_start_time} - {el?.class_end_time}
                          </span>
                          <span>
                            <HiUserGroup className="text-pink" />{" "}
                            {el?.participant} Registrants
                          </span>
                          <span>
                            <HiUserGroup className="text-pink" /> {el?.attended}{" "}
                            Attendants
                          </span>
                          <span>
                            <HiUser className="text-pink" /> {el?.teachers}
                          </span>
                        </div>

                        <hr />

                        <Accordion.Collapse eventKey={idx}>
                          <>
                            <Row className="fw-bold">
                              <Col>Location</Col>
                              <Col>Room</Col>
                            </Row>
                            <Row className="">
                              <Col>{el?.location}</Col>
                              <Col>{el?.room}</Col>
                            </Row>
                            <Row className="fw-bold">
                              <Col>Web Conference Link</Col>
                              <Col>Additional Information</Col>
                            </Row>
                            <Row className="">
                              <Col>
                                {el?.class_link === "" ? "-" : el?.class_link}
                              </Col>
                              <Col>
                                {el?.additional_information === ""
                                  ? "-"
                                  : el?.additional_information}
                              </Col>
                            </Row>
                            <Row className="fw-bold">
                              <Col>Notes</Col>
                            </Row>
                            <Row className="">
                              <Col>{el?.notes === "" ? "-" : el?.notes}</Col>
                            </Row>
                          </>
                        </Accordion.Collapse>
                      </div>
                    ))
                  ) : (
                    <div className="">
                      <span className="font-semibold">Class In Progress</span>
                      <EmptyData />
                    </div>
                  )}
                  {classInprogress.data?.length > 0 && (
                    <Pagination
                      active={active4}
                      setActive={setActive4}
                      current_page={
                        classInprogress.meta.pagination?.current_page
                      }
                      total_pages={classInprogress.meta.pagination?.total_pages}
                    />
                  )}
                </div>
              </Accordion>
              <div className="flex flex-col md:w-2/5">
                <div
                  style={{
                    minHeight: 200,
                    border: `1px solid ${hexToRGBA(themeColor, 0.4)}`,
                  }}
                  className=" p-2 mt-2  bg-white"
                >
                  <p className=" font-semibold">Cancellation Request</p>
                  {cancellationRequest.data?.length > 0 ? (
                    cancellationRequest.data?.map((el, idx) => (
                      <div key={idx}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Link
                            to={{
                              pathname: `/admin/student/${el?.student_id}/detail`,
                              state: { id: el?.student_id, setOrder: true },
                            }}
                            className="text-black"
                          >
                            <b>{el?.student_name}</b>
                          </Link>
                          <span
                            className={`${
                              el?.category === "class"
                                ? "text-danger"
                                : "greyblue"
                            } heading3`}
                          >
                            {el?.category?.replace(/\b\w/g, (l) =>
                              l.toUpperCase()
                            )}
                          </span>
                        </div>
                        <Row className="heading3">
                          <Col>
                            {moment(el?.cancel_date).format(
                              "dddd, DD MMM YYYY"
                            )}
                          </Col>{" "}
                          <br />
                          <span>{el?.cancellation_reason ?? "-"}</span>
                        </Row>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                  {cancellationRequest.data?.length > 0 && (
                    <Pagination
                      active={active2}
                      setActive={setActive2}
                      current_page={
                        cancellationRequest.meta.pagination?.current_page
                      }
                      total_pages={
                        cancellationRequest.meta.pagination?.total_pages
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  minHeight: 300,
                  border: `1px solid ${hexToRGBA(themeColor, 0.4)}`,
                }}
                className="  bg-white my-2"
              >
                <p className="font-semibold p-3 m-0">Upcoming Class</p>
                <div
                  style={{
                    backgroundColor: hexToRGBA(themeColor, 0.1),
                  }}
                  className="p-3"
                >
                  <Row>
                    <Col>Schedule</Col>
                    <Col>Class Name</Col>
                    <Col>Registrants</Col>
                    <Col>Est. Turnover</Col>
                  </Row>
                </div>
                {upcomingClass.data?.length > 0 ? (
                  upcomingClass.data?.map((el, idx) => (
                    <div key={idx} className="">
                      <Row className="px-3 pt-2">
                        <Col>{el?.date_time_class}</Col>
                        <Col>{el?.class_name}</Col>
                        <Col>{el?.participant}</Col>
                        <Col>
                          IDR{" "}
                          {el?.turnover
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  ))
                ) : (
                  <EmptyData />
                )}
                {upcomingClass.data?.length > 0 && (
                  <Pagination
                    active={active1}
                    setActive={setActive1}
                    current_page={upcomingClass.meta.pagination?.current_page}
                    total_pages={upcomingClass.meta.pagination?.total_pages}
                  />
                )}
              </div>

              <div
                style={{
                  minHeight: 300,
                  border: `1px solid ${hexToRGBA(themeColor, 0.4)}`,
                }}
                className=" bg-white"
              >
                <div>
                  <p className="font-semibold p-3 m-0">Class Held</p>
                  <div
                    style={{
                      backgroundColor: hexToRGBA(themeColor, 0.1),
                    }}
                    className="p-3"
                  >
                    <Row>
                      <Col>Schedule</Col>
                      <Col>Class Name</Col>
                      <Col>Registrants</Col>
                      <Col>Attendants</Col>
                      <Col>Est. Turnover</Col>
                    </Row>
                  </div>
                  {classHeld.data?.length > 0 ? (
                    classHeld.data?.map((el, idx) => (
                      <div key={idx} className="">
                        <Row className="px-3 pt-2">
                          <Col>{el?.class_date}</Col>
                          <Col>{el?.class_name}</Col>
                          <Col>{el?.participants}</Col>
                          <Col>{el?.attended}</Col>
                          <Col>
                            IDR{" "}
                            {el?.turnover
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <EmptyData />
                  )}
                  {classHeld.data?.length > 0 && (
                    <Pagination
                      active={active3}
                      setActive={setActive3}
                      current_page={classHeld.meta.pagination?.current_page}
                      total_pages={classHeld.meta.pagination?.total_pages}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        centered
        contentClassName="rounded-lg bg-light overflow-auto"
        show={selectedDate?.visible}
        onHide={() => setSelectedDate({ visible: false })}
      >
        <div className="py-2 text-center">
          <DateRange
            className="shadow-sm mb-3"
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <div className="d-flex justify-content-center ">
            <Button
              type="primary"
              title="Close"
              onClick={() => setSelectedDate({ visible: false })}
            />
          </div>
        </div>
      </Modal>
    </Sidebar>
  );
};

export default TeacherDashBoard;
