import React, { useState } from "react";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import OnboardingStatus from "../../../../components/onboarding-status";
import AddStudentPLan from "./add-student-plan";
import { AddStudent } from "./add-student.component";

const ModifyStudent = (props) => {
  const id = props.location.state?.id;
  const isOnboarding = props.location.state?.isOnboarding;
  const isCreating = window.location.pathname.includes("add") ? true : false;

  const [step, setStep] = useState(1);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <AddStudent id={id} isOnboarding={isOnboarding} setStep={setStep} />
        );
      case 2:
        return (
          <AddStudentPLan
            id={id}
            isOnboarding={isOnboarding}
            setStep={setStep}
          />
        );
      default:
        break;
    }
  };

  return (
    <OrganismLayout
      renderStep={id ? false : true}
      totalStep={[1, 2]}
      step={step}
      maxWidth="32rem"
      title={isCreating ? "Add Student" : "Edit Student"}
      onBoardingStatus={
        isOnboarding && <OnboardingStatus selectedStep={1} activeStep={1} />
      }
    >
      {renderStep()}
    </OrganismLayout>
  );
};

export default ModifyStudent;
