import React, { useEffect, useState } from "react";
import moment from "moment";
import { Space, Select, Checkbox } from "antd";

const DatePickerCustom = ({
  title,
  currentDate = new Date(),
  onChange,
  isNeverEnds,
  setIsNeverEnds,
  endDate = false,
}) => {
  const [onChangeDate, setOnChangeDate] = useState({
    day: "",
    year: "",
    month: "",
  });
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const triggerChange = (changedValue) => {
    onChange?.(changedValue);
  };

  const getYear = (startYear = 2024) => {
    const currentYear = 9999;
    const years = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    const dataYears = years.map((year) => {
      return {
        value: year,
        label: year.toString(),
      };
    });
    return dataYears;
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const months = monthNames.map((name, index) => ({
    value: String(index + 1).padStart(2, "0"),
    label: name,
  }));

  const getDate = () => {
    const result = [];
    for (let i = 1; i <= 31; i++) {
      const paddedValue = String(i).padStart(2, "0");
      result.push({
        value: paddedValue,
        label: paddedValue,
      });
    }

    return result;
  };

  useEffect(() => {
    const year = moment(currentDate).format("YYYY");
    const month = moment(currentDate).format("MM");
    const day = moment(currentDate).format("DD");
    setYear(year);
    setMonth(month);
    setDay(day);
    setOnChangeDate({
      ...onChangeDate,
      year: year,
      month: month,
      day: day,
    });
  }, []);

  useEffect(() => {
    triggerChange(onChangeDate);
  }, [onChangeDate]);

  return (
    <div className="w-full flex justify-between items-center relative">
      <label htmlFor="date" className="font-normal">
        {title}
      </label>
      <Space>
        <Select
          disabled={isNeverEnds}
          value={day}
          options={getDate()}
          placeholder="DD"
          className="w-[70px] h-[47px] text-slate-900 font-normal"
          onChange={(e) => {
            setDay(e);
            setOnChangeDate({
              ...onChangeDate,
              day: e,
            });
          }}
        />
        <Select
          disabled={isNeverEnds}
          value={month}
          options={months}
          placeholder="MM"
          className="w-[80px] h-[47px]"
          onChange={(e) => {
            setMonth(e);
            setOnChangeDate({
              ...onChangeDate,
              month: e,
            });
          }}
        />
        <Select
          disabled={isNeverEnds}
          value={year}
          options={getYear()}
          placeholder="YYYY"
          className="w-[80px] h-[47px]"
          picker="year"
          onChange={(e) => {
            setYear(e);
            setOnChangeDate({
              ...onChangeDate,
              year: e,
            });
          }}
        />
      </Space>

      {endDate && (
        <div className="absolute -right-28">
          <Checkbox onChange={(e) => setIsNeverEnds(e.target.checked)}>
            Never Ends
          </Checkbox>
        </div>
      )}
    </div>
  );
};

export default DatePickerCustom;
