import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Col, Form, Image, Modal } from "react-bootstrap";
import "./../students.styles.scss";
import sampleImg from "./../../../../assets/images/placeholder.png";

import { FaAngleRight } from "react-icons/fa";
import { useCookies } from "react-cookie";
import { setStatusDefault } from "../../../../redux/student/student.action";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { getPaymentCode, payWithOy } from "../../../../services/payment";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import TextInput from "../../../../components/TextInput/text-input.component";
import Button from "../../../../components/Button/button.component";

const ChoosePayment = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies] = useCookies(["user"]);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [next, setNext] = useState(false);
  const [show, setShow] = useState({});
  const [paymentCode, setPaymentCode] = useState([]);

  const { client_key, isRegisterStudent } = useSelector(
    (state) => state.studio,
  );
  const { payment_detail } = useSelector((state) => state.owner);
  const { order_status, order_resp } = useSelector((state) => state.student);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const [phoneNumber, setPhoneNumber] = useState("");
  const subdomain = window.location.host.split(".")[0];
  const plan_id = props.location.state?.plan_id;
  const buy_class = props.location.state?.buy_class ?? false;
  const class_schedule_id = props.location.state?.class_schedule_id;
  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const handleSubmit = async (code) => {
    // console.log(e, "e");
    // if (e !== null) {
    //   e.preventDefault();
    // }

    let params = buy_class
      ? {
          ...code,
          category: "class",
          class_schedule_id: class_schedule_id,
          payment_method: "online",
          payment_type: selectedPayment === "option1" ? "VA" : "e-wallet",
          mobile_number: phoneNumber,
        }
      : {
          ...code,
          category: "plan",
          plan_id: plan_id,
          payment_method: "online",
          payment_type: selectedPayment === "option1" ? "VA" : "e-wallet",
          mobile_number: phoneNumber,
        };
    try {
      const res = await payWithOy(user_token, studio_id, params);
      history.push({
        pathname: "/waiting-payment",
        state: { data: res.data.data, buy_class: buy_class },
      });
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  // useEffect(() => {
  //   if (next && selectedPayment === "option2") {
  //     handleSubmit();
  //   }
  // }, [next && selectedPayment === "option2"]);

  const handleGetPaymentCode = async () => {
    try {
      const res = await getPaymentCode(user_token, studio_id);
      setPaymentCode(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // dispatch(getClientKey(user_token, studio_id));
    handleGetPaymentCode();
    // dispatch(getPaymentDetail(user_token, studio_id));
  }, []);

  // useEffect(() => {
  //   //change this to the script source you want to load, for example this is snap.js sandbox env
  //   const midtransScriptUrl = process.env.REACT_APP_MIDTRANS_SNAP_URL;
  //   //change this according to your client-key
  //   const myMidtransClientKey = client_key?.client_key;

  //   let scriptTag = document.createElement("script");
  //   scriptTag.src = midtransScriptUrl;
  //   // optional if you want to set script attribute
  //   // for example snap.js have data-client-key attribute
  //   scriptTag.setAttribute("data-client-key", myMidtransClientKey);

  //   document.body.appendChild(scriptTag);
  //   console.log("Done appending snap.js");
  //   return () => {
  //     document.body.removeChild(scriptTag);
  //   };
  // }, [client_key]);

  useEffect(() => {
    if (order_status === "success") {
      window.snap.pay(order_resp?.midtrans_token_id, {
        onSuccess: function (result) {
          /* You may add your own implementation here */
          window.location.href =
            process.env.REACT_APP_PROTOCOL +
            subdomain +
            `.${process.env.REACT_APP_HOST}/online-payment-success`;
          console.log(result, "result succes");
        },
        onPending: function (result) {
          /* You may add your own implementation here */
          window.location.href =
            process.env.REACT_APP_PROTOCOL +
            subdomain +
            `.${process.env.REACT_APP_HOST}/dashboard`;
          console.log(result, "result pending");
        },
        onError: function (result) {
          /* You may add your own implementation here */
          window.location.href =
            process.env.REACT_APP_PROTOCOL +
            subdomain +
            `.${process.env.REACT_APP_HOST}/dashboard`;
          console.log(result, "result error");
        },
        onClose: function (result) {
          /* You may add your own implementation here */
          window.location.href =
            process.env.REACT_APP_PROTOCOL +
            subdomain +
            `.${process.env.REACT_APP_HOST}/dashboard`;
          console.log(result, "result close");
        },
      });
      dispatch(setStatusDefault());
    }
  }, [order_status]);

  return (
    <OrganismLayout maxWidth='48rem'>
      <Col className='mx-auto p-md-3' style={{ maxWidth: "28rem" }}>
        <h1 className=' font-bold text-3xl mb-5 text-center'>Choose Payment</h1>
        <div className='form-check' style={{ marginBottom: 50 }}>
          <input
            className='form-check-input'
            type='radio'
            name='exampleRadios'
            id='exampleRadios1'
            value='option1'
            onChange={(e) => {
              setSelectedPayment(e.target.value);
              setNext(false);
            }}
            checked={selectedPayment === "option1"}
          />
          <label className='fw-bold' htmlFor='exampleRadios1'>
            Virtual Account
          </label>
          {selectedPayment === "option1" &&
            paymentCode.length > 0 &&
            paymentCode
              .filter((el) => el.type === "banks")[0]
              .value.map((el, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    let params = { bank_code: el.code };
                    handleSubmit(params);
                  }}
                  className='b-gray rounded-3 d-flex justify-content-between align-items-center p-2 mb-3 pointer'
                >
                  <div className=''>
                    <Image src={el?.image ?? sampleImg} width={50} />
                    <span className='ms-3'>{el?.name}</span>
                  </div>
                  <FaAngleRight className='fs-4 text-black-50' />
                </div>
              ))}
        </div>

        <div className='form-check'>
          <input
            className='form-check-input'
            type='radio'
            name='exampleRadios'
            id='exampleRadios2'
            value='option2'
            onChange={(e) => {
              setSelectedPayment(e.target.value);
              setNext(false);
            }}
            checked={selectedPayment === "option2"}
          />
          <label className='fw-bold' htmlFor='exampleRadios2'>
            E-Wallet
          </label>
          {selectedPayment === "option2" &&
            paymentCode.length > 0 &&
            paymentCode
              .filter((el) => el.type === "e-wallet")[0]
              .value.map((el, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    let params = { ewallet_code: el.code };
                    if (el.name === "OVO") {
                      setShow({ visible: true, params: params });
                    } else {
                      handleSubmit(params);
                    }
                  }}
                  className='b-gray rounded-3 d-flex justify-content-between align-items-center p-3 mb-3 pointer'
                >
                  <div className=''>
                    <Image src={el?.image ?? sampleImg} width={50} />
                    <span className='ms-3'>{el?.name}</span>
                  </div>
                  <FaAngleRight className='fs-4 text-black-50' />
                </div>
              ))}
        </div>

        {/* <button
          onClick={() => {
            setNext(true);
          }}
          className="custom-button bg-lightaquamarine fw-bold text-white w-100 d-flex justify-content-between py-3 px-5"
        >
          NEXT <FaAngleRight className="fs-4" />
        </button> */}
      </Col>

      <Modal
        size='sm'
        centered
        show={show.visible}
        onHide={() => setShow({ visible: false })}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(show.params);
          }}
          className='p-3'
        >
          <p>Please Input Your Phone Number that Linked to OVO Account.</p>
          <TextInput
            required
            max={13}
            type='number'
            label='Phone Number'
            placeholder='0888 xxxx xxxx'
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
          <div className='d-flex gap-3 mt-5'>
            <Button
              type='outline-secondary'
              title='Cancel'
              onClick={() => setShow({ visible: false })}
            />
            <Button type='primary' buttonType='submit' title='Submit' />
          </div>
        </Form>
      </Modal>
    </OrganismLayout>
  );
};

export default ChoosePayment;
