import axiosInstance from "../axiosInstance";

export const getClassInProgress = async (
  user_token,
  studio_id,
  start_date,
  end_date,
  page
) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-teachers?start_date=${start_date}&actiontype=class-in-progress&page=${page}&end_date=${end_date}`,
    config
  );
  return res;
};

export const getPaymentVerification = async (
  user_token,
  studio_id,
  start_date,
  end_date,
  page
) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-teachers?start_date=${start_date}&actiontype=payment-verification&page=${page}&end_date=${end_date}`,
    config
  );
  return res;
};

export const getUpcomingClass = async (
  user_token,
  studio_id,
  start_date,
  end_date,
  page
) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-teachers?start_date=${start_date}&actiontype=upcoming-classes&page=${page}&end_date=${end_date}`,
    config
  );
  return res;
};

export const getCancellationRequest = async (
  user_token,
  studio_id,
  start_date,
  end_date,
  page
) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-teachers?start_date=${start_date}&actiontype=cancellation-request&page=${page}&end_date=${end_date}`,
    config
  );
  return res;
};

export const getClassHeld = async (
  user_token,
  studio_id,
  start_date,
  end_date,
  page
) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-teachers?start_date=${start_date}&actiontype=class-held&page=${page}&end_date=${end_date}`,
    config
  );
  return res;
};

export const getListStatistic = async (
  user_token,
  studio_id,
  start_date,
  end_date,
  actionType,
  page
) => {
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  const res = await axiosInstance.get(
    `/dashboard-owners?start_date=${start_date}&end_date=${end_date}&page=${page}&actiontype=${actionType}`,
    config
  );
  return res;
};

export const getIncomeReport = async (
  user_token,
  studio_id,
  report,
  start_date,
  end_date,
  download
) => {
  let config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  if (download === 1) config.responseType = "blob";

  const res = await axiosInstance.get(
    `/orders/reports/${report}?download=${download}&start_date=${start_date}&end_date=${end_date}`,
    config
  );
  return res;
};
