import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Form } from "react-bootstrap";
import TextInput from "../../../../components/TextInput/text-input.component";
import Button from "../../../../components/Button/button.component";
import { useCookies } from "react-cookie";

import { setStatusDefault } from "../../../../redux/owner/owner.action";
import AtomLoading from "../../../../components/loding-indicator";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import { sendGetRequest, sendPostRequest } from "../../../../services/students";
import hexToRGBA from "../../../../helper/hexToRbga";

const AddStudentPLan = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isCreating = window.location.pathname.includes("add") ? true : false;

  const [cookies, setCookies] = useCookies(["user"]);
  const [plans, setPlans] = useState([]);
  const [planId, setPlanId] = useState("");

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const { isLoadingStudent, onboarding_status, add_student_res } = useSelector(
    (state) => state.owner
  );

  const student_detail = add_student_res[0];
  const list_plans = plans?.length > 0 ? plans : [];
  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  useEffect(() => {
    (async () => {
      try {
        const { data } = await sendGetRequest(
          user_token,
          studio_id,
          "/plans?plan_type=membership&status=active&limit=0"
        );
        setPlans(data.data);
      } catch (error) {
        toast.error(<GenerateErrorMessage error={error} />);
      }
    })();
  }, [user_token, studio_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let params = {
      category: "plan",
      plan_id: isCreating ? planId.split(",")[0] : planId,
      payment_method: "offline",
      payment_type: "cash",
      student_id: student_detail._id,
    };
    try {
      isCreating
        ? await sendPostRequest(user_token, studio_id, "orders", params)
        : await sendPostRequest(user_token, studio_id, "orders", params);

      if (props.isOnboarding) {
        onboarding_status?.first_teacher === false
          ? history.push({
              pathname: "/admin/add-teacher",
              state: { isOnboarding: true },
            })
          : onboarding_status?.schedule_class === false
          ? history.push({
              pathname: "/admin/add-class",
              state: { isOnboarding: true },
            })
          : onboarding_status?.plan === false
          ? history.push({
              pathname: "/admin/add-plan",
              state: { isOnboarding: true },
            })
          : onboarding_status?.term_and_condition === false
          ? history.push({
              pathname: "/admin/setting-terms-and-conditions",
              state: { isOnboarding: true, step: 2 },
            })
          : onboarding_status?.cancellation_setting === false
          ? history.push({
              pathname: "/admin/setting-cancellation",
              state: { isOnboarding: true, step: 2 },
            })
          : onboarding_status?.payment_information === false
          ? history.push({
              pathname: "/admin/setting-payment-gateway",
              state: { isOnboarding: true, step: 2 },
            })
          : history.goBack();
      } else {
        history.goBack();
      }
      toast.success("Successfully Add Plan for New Student!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    } finally {
      dispatch(setStatusDefault());
    }
  };

  // useEffect(() => {
  //   if (!isCreating) {
  //     setPlanId(plan_id);
  //   }
  // }, [!isCreating]);

  const studentPlans = [
    { label: "Choose Plan", value: "", selected: true },
    ...list_plans,
  ];

  return (
    <>
      {false ? (
        <AtomLoading />
      ) : (
        <Form onSubmit={handleSubmit} className="w-full md:w-[568px]">
          <Form.Group style={{ marginBottom: 10 }}>
            <div className={`form-group`}>
              <label className="custom-form-label">
                Add Plan for New Student (Optional)
              </label>
              <select
                onChange={(e) => setPlanId(e.target.value)}
                className="select-input p-2"
                required
              >
                {studentPlans
                  ? studentPlans.map((element, idx) => (
                      <option
                        key={idx}
                        value={
                          element.id
                            ? element?.id.concat(",", element?.bank_name)
                            : element.value
                        }
                      >
                        {element.label ?? element.plan_name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </Form.Group>

          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              label="Student Name"
              disabled
              value={`${student_detail?.first_name} ${
                student_detail?.last_name ?? ""
              }`}
            />
          </Form.Group>

          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              label="Student E-mail"
              disabled
              value={student_detail?.email}
            />
          </Form.Group>

          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput
              label="Student Phone Number"
              disabled
              value={student_detail?.phone_number ?? "-"}
            />
          </Form.Group>

          <Form.Group style={{ marginBottom: 10 }}>
            <TextInput label="Payment Method" disabled value="Cash" />
          </Form.Group>

          <div className="mt-3 d-flex justify-content-center gap-3">
            <Button
              buttonType="button"
              type="outline-secondary"
              title="Exit"
              onClick={() => {
                history.goBack();
                dispatch(setStatusDefault());
              }}
              style={{
                width: "25%",
                backgroundColor: "#fff",
                color: colorTheme,
                borderRadius: 0,
                border: `1px solid ${hexToRGBA(colorTheme, 0.2)}`,
              }}
            />

            <Button
              buttonType="submit"
              type="primary"
              title={isCreating ? "Add" : "Update"}
              style={{
                width: "25%",
                backgroundColor: colorTheme,
                borderRadius: 0,
              }}
              isLoading={isLoadingStudent}
            />
          </div>
        </Form>
      )}
    </>
  );
};

export default AddStudentPLan;
